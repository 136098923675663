<!-- Loading -->
<div class="loading in" *ngIf="loadingUserPeople || loadingUserLocations || loadingUserKeywords">
	&nbsp;
</div>

<!-- No Payment Method Message -->
<div class="scroll" *ngIf="!paymentMethodExists && !loadingUserPeople && !loadingUserLocations && !loadingUserKeywords">
	<div class="welcome-title">
		<div class="title">Upload Photos</div>
	</div>
	<div class="welcome-message">
		To upload photos, you must have a valid payment method added to your account...
	</div>
	<div class="welcome-content">
		<div class="column">
			<div class="icon-cell">
				<i class="far fa-credit-card"></i>
			</div>
			<div class="border-cell">
				<div class="content-cell">
					<div class="content-title">Payment Methods</div>
					<div class="content-text">
						<p>Photo storage costs are $0.50 per GB per month, which can be pre-paid or billed monthly.</p>
					</div>
					<div class="button-cell">
						<div class="button" (click)="navigateToPaymentMethods()">Add a Payment Method</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Upload Form -->
<div class="row" *ngIf="paymentMethodExists && !loadingUserPeople && !loadingUserLocations && !loadingUserKeywords">
	<!-- Left Column -->
	<div class="col-md-4">
		<div class="metadata-container">
			<form #s="ngForm" [formGroup]="form" class="form">
				<!-- Folder -->
				<div class="form-group tag-outer-group">
					<!-- Label -->
					<label for="folder" class="field-label">
						Select an optional folder location:
						<i class="fas fa-question-circle" [tooltip]="folderLocationTemplate" placement="bottom"></i>
						<ng-template #folderLocationTemplate>
							To update or delete folders, visit the My Folders page
						</ng-template>
					</label>
					<!-- No Selected Folder -->
					<div class="tag-select-container folder" [ngClass]="{'disabled': !foldersShowSelector && !selectedRootFolder}">
						<i class="fas fa-folder left"></i>
						<input [ngClass]="{'hidden': selectedRootFolder}" #inputFolder placeholder="Select or create a folder" id="folder" formControlName="folder" (focus)="folderFocus()" (keyup)="folderKeyup()" autocomplete="off" />
						<div [ngClass]="{'hidden': !selectedRootFolder}" class="title">{{ selectedRootFolder?.name }}<div class="tag-subfolder" *ngIf="selectedSubFolder">&#187; {{ selectedSubFolder?.name }}</div>
						</div>
						<!-- Buttons-->
						<div *ngIf="tempFolder.length == 0" class="tag-icon-container">
							<i *ngIf="!foldersShowSelector && userRootFolders.length > 0" class="fas fa-caret-down" (click)="showAllUserFolders()"></i>
							<i *ngIf="foldersShowSelector && userRootFolders.length > 0" class="fas fa-caret-up" (click)="showAllUserFolders()"></i>
							<i class="fas fa-times" (click)="selectNoFolder(folder)"></i>
						</div>
						<div *ngIf="tempFolder.length > 0" class="tag-icon-container">
							<i class="fas fa-times" (click)="cancelCreateRootFolder()"></i>
							<i class="fas fa-check" (click)="createRootFolder()"></i>
						</div>
						<!-- Selectors -->
						<div class="tags-dropdown-container folder" [ngClass]="{'hidden': !foldersShowSelector || filteredUserRootFolders?.length > 0 || userRootFolders.length == 0 }">
							<!-- List folders -->
							<ng-container *ngFor="let folder of userRootFolders">
								<!-- Root folder -->
								<div class="item">
									<div>
										<i class="fas fa-angle-right left" *ngIf="expandedRootId !== folder.id" [ngClass]="{'disabled': !folder.folders || folder.folders?.length == 0}" (mousedown)="expandFolder(folder)"></i>
										<i class="fas fa-angle-down left" *ngIf="expandedRootId == folder.id" (mousedown)="collapseFolder()"></i>
										<i class="fas fa-folder left"></i>
									</div>
									<div class="grow" (mousedown)="selectFolder(folder, null)"><span [ngClass]="{'strong': selectedRootFolder?.id == folder.id}">{{ folder.name }}</span></div>
									<div><i class="fas fa-folder-plus" *ngIf="newSubFolderParentId == -1" (mousedown)="showCreateSubFolder(folder.id)" title="Create sub-folder"></i></div>
								</div>
								<!-- Create sub-folder -->
								<div [ngClass]="{'hidden': newSubFolderParentId !== folder.id}" class="new-sub-folder">
									<i class="fas fa-folder"></i>
									<input formControlName="newSubFolderName" placeholder="Enter folder name" />
									<i class="fas fa-times" (click)="cancelShowCreateSubFolder(folder.id)"></i>
									<i class="fas fa-check" *ngIf="form.value.newSubFolderName?.length > 0" (click)="createSubFolder(folder.id)"></i>
									<div class="icon-spacer" *ngIf="!form.value.newSubFolderName || form.value.newSubFolderName?.length == 0"></div>
								</div>
								<!-- List Sub-folders -->
								<ng-container *ngIf="expandedRootId == folder.id">
									<ng-container *ngFor="let subfolder of folder.folders">
										<div class="subfolder" (click)="selectFolder(folder, subfolder)">
											<i class="fas fa-folder"></i>
											<span [ngClass]="{'strong': selectedRootFolder?.id == subfolder.id}">{{ subfolder.name }}</span>
										</div>
									</ng-container>
								</ng-container>
							</ng-container>
						</div>
						<div class="folder-select-container" [ngClass]="{'hidden': !foldersShowSelector || filteredUserRootFolders.length == 0 || userRootFolders.length == 0}">
							<!-- List folders -->
							<ng-container *ngFor="let folder of filteredUserRootFolders">
								<!-- Root folder -->
								<div class="item">
									<div>
										<i class="fas fa-angle-right" *ngIf="expandedRootId !== folder.id" [ngClass]="{'disabled': !folder.folders || folder.folders?.length == 0}" (mousedown)="expandFolder($event, folder)"></i>
										<i class="fas fa-angle-down" *ngIf="expandedRootId == folder.id" (mousedown)="collapseFolder($event)"></i>
										<i class="fas fa-folder"></i>
									</div>
									<div class="grow" (mousedown)="selectFolder(folder, null)"><span [ngClass]="{'strong': selectedRootFolder?.id == folder.id}">{{ folder.name }}</span></div>
									<div><i class="fas fa-folder-plus" *ngIf="newSubFolderParentId == -1" (mousedown)="showCreateSubFolder(folder.id)" title="Create sub-folder"></i></div>
								</div>
								<!-- Create sub-folder -->
								<div [ngClass]="{'hidden': newSubFolderParentId !== folder.id}" class="new-sub-folder">
									<i class="fas fa-folder"></i>
									<input formControlName="newSubFolderName" placeholder="Enter folder name" />
									<i class="fas fa-times" (click)="cancelShowCreateSubFolder(folder.id)"></i>
									<i class="fas fa-check" *ngIf="form.value.newSubFolderName?.length > 0" (click)="createSubFolder(folder.id)"></i>
									<div class="icon-spacer" *ngIf="!form.value.newSubFolderName || form.value.newSubFolderName?.length == 0"></div>
								</div>
								<!-- List Sub-folders -->
								<ng-container *ngIf="expandedRootId == folder.id">
									<ng-container *ngFor="let subfolder of folder.folders">
										<div class="subfolder" (click)="selectFolder(folder, subfolder)">
											<i class="fas fa-folder"></i>
											<span [ngClass]="{'strong': selectedRootFolder?.id == subfolder.id}">{{ subfolder.name }}</span>
										</div>
									</ng-container>
								</ng-container>
							</ng-container>
						</div>
					</div>
				</div>

				<!-- People -->
				<div class="form-group tag-outer-group">
					<!-- Label -->
					<label for="people" class="field-label">Who is in the photo?</label>
					<!-- No Selected Tags -->
					<div *ngIf="selectedPeople.length == 0" class="tag-select-container disabled">
						<i class="fas fa-user left"></i>No data entered
					</div>
					<!-- Selected Tags -->
					<ng-container *ngFor="let person of selectedPeople">
						<!-- Selected -->
						<div *ngIf="person.name !== ''" class="tag-select-container">
							<i class="fas fa-user left"></i>
							<span *ngIf="person.type == 'new'" class="new-icon">NEW</span>
							<div class="title">{{ person.name }}</div>
							<div class="tag-icon-container">
								<i class="fas fa-times" (click)="removePerson(person, false)"></i>
							</div>
						</div>
						<!-- Not Selected -->
						<div class="tag-select-container" *ngIf="person.name == ''">
							<i class="fas fa-user left"></i>
							<input #inputPerson placeholder="add a person" (focus)="peopleFocus()" (keyup)="peopleKeyup()" autocomplete="off" />
							<!-- Buttons-->
							<div *ngIf="tempPerson.length == 0" class="tag-icon-container">
								<i *ngIf="!peopleShowSelector && userPeople.length > 0" class="fas fa-caret-down" (click)="showAllUserPeople()"></i>
								<i *ngIf="peopleShowSelector && userPeople.length > 0" class="fas fa-caret-up" (click)="showAllUserPeople()"></i>
								<i class="fas fa-times" (click)="removePerson(person, true)"></i>
							</div>
							<div *ngIf="tempPerson.length > 0" class="tag-icon-container">
								<i class="fas fa-times" (click)="cancelCreatePerson()"></i>
								<i class="fas fa-check" (click)="peopleKeyupEnter()"></i>
							</div>
							<!-- Selectors -->
							<div class="tags-dropdown-container" [ngClass]="{'hidden': !peopleShowSelector || filteredUserPeople.length > 0 || userPeople.length == 0}">
								<div *ngFor="let person of userPeople" class="item" (click)="selectPerson(person)">{{ person.name }}</div>
							</div>
							<div class="tags-dropdown-container" [ngClass]="{'hidden': !peopleShowSelector || filteredUserPeople.length == 0}">
								<div *ngFor="let person of filteredUserPeople" class="item" (click)="selectPerson(person)">{{ person.name }}</div>
							</div>
						</div>
					</ng-container>
					<!-- Add Tag Icon -->
					<i *ngIf="!peopleHideAddIcon" class="fas fa-plus add-tag-icon" (click)="addPersonTag()"></i>
				</div>

				<!-- Date
				<div class="form-group even">
					<label for="capturedDate">When was this photo taken?</label> <i class="fas fa-question right" (click)="showHelp=!showHelp"></i>
					<div *ngIf="showHelp" class="help-text">NOTE: This date will only be used if the photo contains no EXIF data. Photos taken with digital cameras typically contain EXIF data while scanned photos do not.</div>
					<input bsDatepicker type="text" [disabled]="loadingPhotos" id="capturedDate" formControlName="capturedDate" class="form-control date" placement="top middle" [maxDate]="maxDate" autocomplete="off">
				</div>-->

				<!-- Locations -->
				<div class="form-group tag-outer-group">
					<label for="locations" class="field-label">Where was this photo taken?</label>
					<!-- No Selected Tags -->
					<div *ngIf="selectedLocations.length == 0" class="tag-select-container disabled">
						<i class="fas fa-map-marker-alt left"></i>No data entered
					</div>
					<!-- Selected Tags -->
					<ng-container *ngFor="let location of selectedLocations">
						<!-- Selected -->
						<div *ngIf="location.name !== ''" class="tag-select-container">
							<i class="fas fa-map-marker-alt left"></i>
							<span *ngIf="location.type == 'new'" class="new-icon">NEW</span>
							<div class="title">{{ location.name }}</div>
							<div class="tag-icon-container">
								<i class="fas fa-times" (click)="removeLocation(location, false)"></i>
							</div>
						</div>
						<!-- Not Selected -->
						<div class="tag-select-container" *ngIf="location.name == ''">
							<i class="fas fa-map-marker-alt left"></i>
							<input #inputLocation placeholder="add a location" (focus)="locationsFocus()" (keyup)="locationsKeyup()" autocomplete="off" />
							<!-- Buttons-->
							<div *ngIf="tempLocation.length == 0" class="tag-icon-container">
								<i *ngIf="!locationsShowSelector && userLocations.length > 0" class="fas fa-caret-down" (click)="showAllUserLocations()"></i>
								<i *ngIf="locationsShowSelector && userLocations.length > 0" class="fas fa-caret-up" (click)="showAllUserLocations()"></i>
								<i class="fas fa-times" (click)="removeLocation(location, true)"></i>
							</div>
							<div *ngIf="tempLocation.length > 0" class="tag-icon-container">
								<i class="fas fa-times" (click)="cancelCreateLocation()"></i>
								<i class="fas fa-check" (click)="locationsKeyupEnter()"></i>
							</div>
							<!-- Selectors -->
							<div class="tags-dropdown-container" [ngClass]="{'hidden': !locationsShowSelector || filteredUserLocations.length > 0 || userLocations.length == 0}">
								<div *ngFor="let location of userLocations" class="item" (click)="selectLocation(location)" [title]="location.name">{{ location.name }}</div>
								<div class="item" (click)="loadMoreLocationResults()" *ngIf="tempLocation.length > 1">Load more results...</div>
							</div>
							<div class="tags-dropdown-container" [ngClass]="{'hidden': !locationsShowSelector || filteredUserLocations.length == 0  || locationsShowSuggestions }">
								<div *ngFor="let location of filteredUserLocations" class="item" (click)="selectLocation(location)" [title]="location.name">{{ location.name }}</div>
								<div class="item" (click)="loadMoreLocationResults()" *ngIf="tempLocation.length > 1">Load more results...</div>
							</div>
							<div class="tags-dropdown-container" [ngClass]="{'hidden': !locationsShowSelector || filteredPublicLocations.length == 0 }">
								<div *ngFor="let suggestion of filteredPublicLocations" class="item" (click)="selectLocationSuggestion(suggestion.title)" [title]="suggestion.title">{{ suggestion.title }}</div>
							</div>
							<div class="tags-dropdown-container" *ngIf="userLocations.length == 0 && filteredPublicLocations.length == 0 && tempLocation.length > 1">
								<div class="item" (click)="loadMoreLocationResults()">Load more results...</div>
							</div>
						</div>
					</ng-container>
					<!-- Add Tag Icon -->
					<i *ngIf="!locationsHideAddIcon" class="fas fa-plus add-tag-icon" (click)="addLocationTag()"></i>
				</div>

				<!-- Keywords -->
				<div class="form-group tag-outer-group">
					<!-- Label -->
					<label for="keywords" class="field-label">Add photo keywords:</label>
					<!-- No Selected Tags -->
					<div *ngIf="selectedKeywords.length == 0" class="tag-select-container disabled">
						<i class="fas fa-tag left"></i>No data entered
					</div>
					<!-- Selected Tags -->
					<ng-container *ngFor="let keyword of selectedKeywords">
						<!-- Selected -->
						<div *ngIf="keyword.name !== ''" class="tag-select-container">
							<i class="fas fa-tag left"></i>
							<span *ngIf="keyword.type == 'new'" class="new-icon">NEW</span>
							<div class="title">{{ keyword.name }}</div>
							<div class="tag-icon-container">
								<i class="fas fa-times" (click)="removeKeyword(keyword, false)"></i>
							</div>
						</div>
						<!-- Not Selected -->
						<div class="tag-select-container" *ngIf="keyword.name == ''">
							<i class="fas fa-tag left"></i>
							<input #inputKeyword placeholder="add a keyword" (focus)="keywordsFocus()" (keyup)="keywordsKeyup()" autocomplete="off" />
							<!-- Buttons-->
							<div *ngIf="tempKeyword.length == 0" class="tag-icon-container">
								<i *ngIf="!keywordsShowSelector && userKeywords.length > 0" class="fas fa-caret-down" (click)="showAllUserKeywords()"></i>
								<i *ngIf="keywordsShowSelector && userKeywords.length > 0" class="fas fa-caret-up" (click)="showAllUserKeywords()"></i>
								<i class="fas fa-times" (click)="removeKeyword(keyword, true)"></i>
							</div>
							<div *ngIf="tempKeyword.length > 0" class="tag-icon-container">
								<i class="fas fa-times" (click)="cancelCreateKeyword()"></i>
								<i class="fas fa-check" (click)="keywordsKeyupEnter()"></i>
							</div>
							<!-- Selectors -->
							<div class="tags-dropdown-container" [ngClass]="{'hidden': !keywordsShowSelector || filteredUserKeywords.length > 0 || userKeywords.length == 0}">
								<div *ngFor="let keyword of userKeywords" class="item" (click)="selectKeyword(keyword)" [title]="keyword.name">{{ keyword.name }}</div>
							</div>
							<div class="tags-dropdown-container" [ngClass]="{'hidden': !keywordsShowSelector || filteredUserKeywords.length == 0}">
								<div *ngFor="let keyword of filteredUserKeywords" class="item" (click)="selectKeyword(keyword)" [title]="keyword.name">{{ keyword.name }}</div>
							</div>
						</div>
					</ng-container>
					<!-- Add Tag Icon -->
					<i *ngIf="!keywordsHideAddIcon" class="fas fa-plus add-tag-icon" (click)="addKeywordTag()"></i>
				</div>

				<!-- Public Tags -->
				<div class="form-group tag-outer-group">
					<label for="tags" class="field-label">Add public tags:
						<i class="fas fa-question-circle" [tooltip]="tagsTemplate" placement="bottom"></i>
						<ng-template #tagsTemplate>
							Public tags give a unique way of sharing photos with connections and non-connections. For example, the tags "Rossville High School" and "2002 Graduation" will allow anyone to view these photos by searching for these terms.
						</ng-template>
					</label>
					<!-- No Selected Tags -->
					<div *ngIf="selectedTags.length == 0" class="tag-select-container disabled">
						<i class="fas fa-hashtag left"></i>No data entered
					</div>
					<!-- Selected Tags -->
					<ng-container *ngFor="let tag of selectedTags">
						<!-- Selected -->
						<div *ngIf="tag.name !== ''" class="tag-select-container">
							<i class="fas fa-hashtag left"></i>
							<span *ngIf="tag.type == 'new'" class="new-icon">NEW</span>
							<div class="title">{{ tag.name }}</div>
							<div class="tag-icon-container">
								<i class="fas fa-times" (click)="removeTag(tag, false)"></i>
							</div>
						</div>
						<!-- Not Selected -->
						<div class="tag-select-container" *ngIf="tag.name == ''">
							<i class="fas fa-hashtag left"></i>
							<input #inputTag placeholder="add a tag" (focus)="tagsFocus()" (keyup)="tagsKeyup()" autocomplete="off" />
							<!-- Buttons-->
							<div *ngIf="tempTag.length == 0" class="tag-icon-container">
								<i *ngIf="!tagsShowSelector && userTags.length > 0" class="fas fa-caret-down" (click)="showAllUserTags()"></i>
								<i *ngIf="tagsShowSelector && userTags.length > 0" class="fas fa-caret-up" (click)="showAllUserTags()"></i>
								<i class="fas fa-times" (click)="removeTag(tag, true)"></i>
							</div>
							<div *ngIf="tempTag.length > 0" class="tag-icon-container">
								<i class="fas fa-times" (click)="cancelCreateTag()"></i>
								<i class="fas fa-check" (click)="tagsKeyupEnter()"></i>
							</div>
							<!-- Selectors -->
							<div class="tags-dropdown-container" [ngClass]="{'hidden': !tagsShowSelector || userTags.length == 0 || filteredUserTags.length > 0 || filteredPublicTags.length > 0 || (tagsShowSelector && filteredUserTags.length == 0 && filteredPublicTags.length == 0 && tempTag.length > 0)}">
								<div class="heading">My Tags</div>
								<div *ngFor="let tag of userTags" class="item" (click)="selectTag(tag)">#{{ tag.name }}</div>
							</div>
							<div class="tags-dropdown-container" [ngClass]="{'hidden': !tagsShowSelector || (filteredUserTags.length == 0 && filteredPublicTags.length == 0)}">
								<div *ngIf="filteredUserTags.length > 0" class="heading">My Tags</div>
								<div *ngFor="let tag of filteredUserTags" class="item" (click)="selectTag(tag)">#{{ tag.name }}</div>
								<div *ngIf="filteredPublicTags.length > 0" class="heading">Public Tags</div>
								<div *ngFor="let tag of filteredPublicTags" class="item" (click)="selectTag(tag)">#{{ tag.name }}</div>
							</div>
						</div>
					</ng-container>
					<!-- Add Tag Icon -->
					<i *ngIf="!tagsHideAddIcon" class="fas fa-plus add-tag-icon" (click)="addUserTag()"></i>
				</div>

				<!-- Permissions -->
				<label for="keywords">
					Allow your connections to:
					<i class="fas fa-question-circle" [tooltip]="defaultSettingsTemplate" placement="bottom"></i>
					<ng-template #defaultSettingsTemplate>
						Defaults for these settings can be changed in User Settings
					</ng-template>
				</label>
				<div class="flex">
					<div class="form-group left">
						<div class="option">
							<div class="slider-container">
								<label class="switch">
									<input id="connectionsCanViewSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanView" (change)="connectionsCanViewSwitchChanged($event)" />
									<span class="slider round"></span>
								</label>
							</div>
							<label for="keywords">View photos</label>
						</div>
					</div>
					<div class="form-group right">
						<div class="option">
							<div class="slider-container" [ngClass]="{'hidden': form.value.connectionsCanView }">
								<label class="switch">
									<span class="slider round"></span>
								</label>
							</div>
							<div class="slider-container" [ngClass]="{'hidden': !form.value.connectionsCanView }">
								<label class="switch">
									<input id="connectionsCanReactSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanReact" />
									<span class="slider round"></span>
								</label>
							</div>
							<label for="keywords">React to photos</label>
						</div>
					</div>
				</div>
				<div class="flex">
					<div class="form-group left">
						<div class="option">
							<div class="slider-container" [ngClass]="{'hidden': form.value.connectionsCanView }">
								<label class="switch">
									<span class="slider round"></span>
								</label>
							</div>
							<div class="slider-container" [ngClass]="{'hidden': !form.value.connectionsCanView }">
								<label class="switch">
									<input id="connectionsCanDiscussSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanDiscuss" />
									<span class="slider round"></span>
								</label>
							</div>
							<label for="keywords">Discuss photos</label>
						</div>
					</div>
					<div class="form-group right">
						<div class="option">
							<div class="slider-container" [ngClass]="{'hidden': form.value.connectionsCanView }">
								<label class="switch">
									<span class="slider round"></span>
								</label>
							</div>
							<div class="slider-container" [ngClass]="{'hidden': !form.value.connectionsCanView }">
								<label class="switch">
									<input id="connectionsCanSuggestSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanSuggest" />
									<span class="slider round"></span>
								</label>
							</div>
							<label for="keywords">Suggest metadata</label>
						</div>
					</div>
				</div>
				<div class="flex">
					<div class="form-group left">
						<div class="option">
							<div class="slider-container" [ngClass]="{'hidden': form.value.connectionsCanView }">
								<label class="switch">
									<span class="slider round"></span>
								</label>
							</div>
							<div class="slider-container" [ngClass]="{'hidden': !form.value.connectionsCanView }">
								<label class="switch">
									<input id="connectionsCanSeeExifSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanSeeExif" />
									<span class="slider round"></span>
								</label>
							</div>
							<label for="keywords">See EXIF data</label>
						</div>
					</div>
				</div>

				<!-- Buttons -->
				<div class="upload-button-container">
					<button type="button" class="btn btn-primary btn-md button" (click)="uploadPhotosSubmit()" [disabled]="!s.valid || files.length == 0 || loadingPhotos">Upload {{ files?.length }} Photos</button>
					<button type="button" class="btn btn-default btn-md button" (click)="clearForm()" [disabled]="loadingPhotos">Reset</button>
				</div>

			</form>
		</div>
	</div>
	<!-- Right Column -->
	<div class="col-md-8">
		<div class="right-outer-container">
			<!-- File Dropzone -->
			<div ngx-dropzone class="custom-dropzone" (change)="onPhotoAdd($event)" [expandable]="false" accept="image/jpeg,image/jpg,image/png,image/gif" [maxFileSize]="10485760">
				<ngx-dropzone-label class="full">
					<div class="text">Drop images here or click to browse</div>
				</ngx-dropzone-label>
				<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files; let i = index" class="upload-row" [id]="i" [file]="f" [removable]="!loadingPhotos" (removed)="onPhotoRemove(f)">
					<ngx-dropzone-label class="photo-details">
						<span *ngIf="!f.error">
							{{ f.name }}
							<br />{{ f.size/1024/1024 | number:'.2' }} MB
						</span>
					</ngx-dropzone-label>
				</ngx-dropzone-image-preview>
			</div>
		</div>
	</div>
</div>
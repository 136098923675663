import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-widget-tag-cloud',
	templateUrl: './widget-tag-cloud.component.html',
	styleUrls: ['./widget-tag-cloud.component.css']
})
export class WidgetTagCloudComponent implements OnInit {
	loadingUserPublicTags = true;

	userTags: any[] = [];

	constructor(
		private userService: UserService,
		private router: Router
	) { }

	ngOnInit() {
		this.retrieveTags();
	}

	retrieveTags() {
		this.loadingUserPublicTags = true;
		this.userService.getUserPublicTags().subscribe(
			response => {
				this.userTags = response;
				this.userTags.sort((a, b) => a.localeCompare(b));
				this.userTags = this.userTags.slice();

				this.loadingUserPublicTags = false;
			}
		);
	}

	searchTag(tag) {
		this.router.navigate(['/explore'], { queryParams: { tags: tag } });
	}
}

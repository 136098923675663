import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserConnectionService } from 'src/app/services/user-connection.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-card-connection-view',
	templateUrl: './card-connection-view.component.html',
	styleUrls: ['../cards.component.css']
})
export class CardConnectionViewComponent implements OnInit, OnDestroy {
	@Input() user: User;

	@Output() emitConnectionApproved = new EventEmitter<User>();
	@Output() emitConnectionRejected = new EventEmitter<User>();
	@Output() emitConnectionCancelled = new EventEmitter<User>();
	@Output() emitConnectionBlocked = new EventEmitter<User>();
	@Output() emitConnectionUnblocked = new EventEmitter<User>();
	@Output() emitConnectionRemoved = new EventEmitter<User>();

	@ViewChild('card') cardRef: ElementRef;
	loading = false;
	loadingWave = false;

	userFoundSubscription: Subscription;

	removeConfirm = false;
	blockConfirm = false;

	showActiveSubmenu = false;

	waveToUserSuccess = false;

	constructor(
		private router: Router,
		private connectionService: UserConnectionService,
		private userService: UserService
	) { }

	ngOnInit() {
		this.subscribeToUserChanged();

		this.userService.getUserByUserId(this.user.id, null);
	}

	ngOnDestroy() { }

	subscribeToUserChanged() {
		this.userFoundSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.user.id) {
					this.user.imageSafeUrl = user.imageSafeUrl;
				}
			}
		);
	}

	waveToUser() {
		this.loadingWave = true;
		this.showActiveSubmenu = false;

		this.userService.waveToUser(this.user.id).subscribe(
			response => {
				this.loadingWave = false;
				this.waveToUserSuccess = true;
			}
		);
	}

	/**
	 * APPROVE PENDING CONNECTION
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	approveConnectionPending(user: User) {
		this.loading = true;
		this.connectionService.approveUserConnection(user.uuid).subscribe(
			response => {
				if (response.status === 200) {
					this.emitConnectionApproved.emit(user);
					this.loading = false;
				} else {
					this.loading = false;
				}
			},
			err => {
				this.loading = false;
			}
		);
	}

	/**
	 * REJECT CONNECTION
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	rejectConnectionPending(user: User) {
		this.loading = true;
		this.connectionService.rejectUserConnection(user.uuid).subscribe(
			response => {
				if (response.status === 200) {
					this.emitConnectionRejected.emit(user);
					this.connectionService.connectionsCount = this.connectionService.connectionsCount - 1;
					this.loading = false;
				} else {
					this.loading = false;
				}
			},
			err => {
				this.loading = false;
			}
		);
	}

	/**
	 * CANCEL CONNECTION WAITING
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	cancelConnectionWaiting(user: User) {
		this.loading = true;
		this.connectionService.cancelUserConnection(user.uuid).subscribe(
			response => {
				if (response.status === 200) {
					this.emitConnectionCancelled.emit(user);
					this.connectionService.connectionsCount = this.connectionService.connectionsCount - 1;
					this.loading = false;
				} else {
					this.loading = false;
				}
			},
			err => {
				this.loading = false;
			}
		);
	}

	/**
	 * BLOCK CONNECTION ACTIVE CONFIRM
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	blockConnectionActiveConfirm(user: User) {
		this.showActiveSubmenu = false;

		this.blockConfirm = true;
		this.flip();
	}

	cancelBlockConnection() {
		this.blockConfirm = false;
		this.flip();
	}

	/**
	 * BLOCK CONNECTION ACTIVE
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	blockConnectionActive(user: User) {
		this.loading = true;
		this.connectionService.blockUserConnection(user.uuid).subscribe(
			response => {
				if (response.status === 200) {
					this.emitConnectionBlocked.emit(user);
					this.connectionService.connectionsCount = this.connectionService.connectionsCount - 1;
					this.loading = false;
				} else {
					this.loading = false;
				}
			},
			err => {
				this.loading = false;
			}
		);
	}

	/**
	 * BLOCK CONNECTION PENDING
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	blockConnectionPending(user: User) {
		this.loading = true;
		this.connectionService.blockUserConnection(user.uuid).subscribe(
			response => {
				if (response.status === 200) {
					this.emitConnectionBlocked.emit(user);
					this.connectionService.connectionsCount = this.connectionService.connectionsCount - 1;
					this.loading = false;
				} else {
					this.loading = false;
				}
			},
			err => {
				this.loading = false;
			}
		);
	}

	/**
	 * BLOCK CONNECTION
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	unblockConnection(user: User) {
		this.loading = true;
		this.connectionService.unblockUserConnection(user.uuid).subscribe(
			response => {
				if (response.status === 200) {
					this.emitConnectionUnblocked.emit(user);
					this.connectionService.connectionsCount = this.connectionService.connectionsCount + 1;
					this.loading = false;
				} else {
					this.loading = false;
				}
			},
			err => {
				this.loading = false;
			}
		);
	}

	removeConnectionActiveConfirm() {
		this.showActiveSubmenu = false;

		this.removeConfirm = true;
		this.flip();
	}

	cancelRemoveConnection() {
		this.removeConfirm = false;
		this.flip();
	}

	/**
	 * REMOVE CONNECTION ACTIVE
	 * Triggered By:  ...
	 * Outcome:       ...
	 */
	removeConnectionActive(user: User) {
		this.loading = true;
		this.showActiveSubmenu = false;
		this.connectionService.removeUserConnection(user.uuid).subscribe(
			response => {
				if (response.status === 200) {
					this.emitConnectionRemoved.emit(user);
					this.connectionService.connectionsCount = this.connectionService.connectionsCount - 1;
					this.loading = false;
				} else {
					this.loading = false;
				}
			},
			err => {
				this.loading = false;
			}
		);
	}

	/**
	 * Helper Methods
	 **/
	navigate(route: string) {
		this.router.navigate([route]);
	}

	navigateToPhotos() {
		this.router.navigate(['/explore/'], { queryParams: { user: this.user.id } });
	}

	navigateToTimeline() {
		this.router.navigate(['/timeline/' + this.user.uuid]);
	}

	flip() {
		if (this.cardRef.nativeElement.style.transform == "rotateY(180deg)") {
			this.cardRef.nativeElement.style.transform = "rotateY(0deg)";
		}
		else {
			this.cardRef.nativeElement.style.transform = "rotateY(180deg)";
		}
	}
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserMessage } from 'src/app/models/user-message';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-user-messages',
	templateUrl: './user-messages.component.html',
	styleUrls: ['./user-messages.component.css']
})
export class UserMessagesComponent implements OnInit, OnDestroy {
	loading = true;
	userMessages: UserMessage[] = [];

	constructor(
		private router: Router,
		private userService: UserService
	) { }

	ngOnInit() {
		this.retrieveCurrentUserMessages();
	}

	ngOnDestroy() { }

	retrieveCurrentUserMessages() {
		this.userService.retrieveUserMessages().subscribe(
			response => {
				this.userMessages = response.body;
				this.loading = false;
			},
			err => {
				this.loading = false;
			}
		);
	}

	removeMessage(message) {
		this.userMessages.splice(this.userMessages.indexOf(message), 1);
	}

	navigate(route: string) {
		this.router.navigate([route]);
	}
}

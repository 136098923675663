import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-explore-connection-icon',
	templateUrl: './explore-connection-icon.component.html',
	styleUrls: ['./explore-connection-icon.component.css']
})
export class ExploreConnectionIconComponent implements OnInit {
	@Input() user: User;
	@Input() defaultPhotoOwnerUserIdsArray;

	@Output() emitSelectOwner = new EventEmitter<User>();
	@Output() emitUnselectOwner = new EventEmitter<User>();

	loading = true;

	userFoundSubscription: Subscription;

	constructor(
		private userService: UserService
	) { }

	ngOnInit() {
		this.subscribeToUserChanged();

		this.userService.getUserByUserId(this.user.id, null);
	}

	ngOnDestroy() { }


	subscribeToUserChanged() {
		this.userFoundSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.user.id) {
					const item = this.defaultPhotoOwnerUserIdsArray.find(d => d === user.id);
					if (item) {
						user.isSearchDefaultOwner = true;
					} else {
						user.isSearchDefaultOwner = false;
					}

					this.user = user;

					this.loading = false;
				}
			}
		);
	}

	selectOwner() {
		console.log('selectOwner');
		//this.user.isSearchDefaultOwner = true;
		this.emitSelectOwner.emit(this.user);
	}

	unselectOwner() {
		console.log('unselectOwner');
		//this.user.isSearchDefaultOwner = false;
		this.emitUnselectOwner.emit(this.user);
	}
}

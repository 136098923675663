import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-terms-of-service',
	templateUrl: './terms-of-service.component.html',
	styleUrls: ['./terms-of-service.component.css']
})
export class TermsOfServiceComponent implements OnInit, AfterViewInit {
	@Input() isModal: boolean = false;
	loading = true;

	constructor(
		public router: Router
	) { }

	ngOnInit() {
		this.loading = false;
	}

	ngAfterViewInit() {
		this.scrollToTop();
		let _this = this;
		setTimeout(function () {
			_this.scrollToTop();
		}, 100);
	}

	scrollToTop() {
		let top = document.getElementById('top');
		if (top !== null) {
			top.scrollIntoView();
			top = null;
		}
	}

	navigate(path,) {
		this.router.navigate([path]);
	}
}

import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Folder } from 'src/app/models/folder.model';
import { PhotoUpload } from 'src/app/models/photo-upload.model';
import { User } from 'src/app/models/user.model';
import { FolderService } from 'src/app/services/folder.service';
import { LayoutService } from 'src/app/services/layout.service';
import { UserConnectionService } from 'src/app/services/user-connection.service';
import { UserService } from 'src/app/services/user.service';

const EXIF = require('exif-js');

@Component({
	selector: 'app-photos-add-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.css']
})
export class PhotosAddUploadComponent implements OnInit {
	@ViewChild('presetMetadataDialog', { static: true }) presetMetadataDialog: TemplateRef<any>;
	@ViewChild('keywordsSelect') keywordsSelect;
	@ViewChild('locationsSelect') locationsSelect;
	@ViewChild('peopleSelect') peopleSelect;

	@ViewChild('inputPerson') inputPerson: ElementRef;
	@ViewChild('inputKeyword') inputKeyword: ElementRef;
	@ViewChild('inputLocation') inputLocation: ElementRef;
	@ViewChild('inputTag') inputTag: ElementRef;

	modalRef: BsModalRef;
	loadingUserKeywords = true;
	loadingUserPeople = true;
	loadingUserLocations = true;
	loadingUserPublicTags = false;
	loadingPhotos = false;
	loadingFolders = true;
	loadingCreateFolder = -1;

	// UI Vars
	showHelp = false;
	paymentMethodExists = false;
	private folderSearchSubject = new Subject<string>();
	private peopleSearchSubject = new Subject<string>();
	private locationsSearchSubject = new Subject<string>();
	private keywordsSearchSubject = new Subject<string>();
	private tagsSearchSubject = new Subject<string>();

	private readonly debounceTime350Ms = 350;
	private readonly debounceTime100Ms = 100;

	// General Form Vars
	form: UntypedFormGroup;
	files: any[] = [];

	//photo: Photo;

	// User Vars
	user: User;
	userChangedSubscription: Subscription;

	// Folder Dropdown Vars
	foldersShowSelector = false;				// If true, the folders dropdown list is displayed
	selectedRootFolder: Folder = null;			// The root folder selected by the user
	selectedSubFolder: Folder = null;			// The sub folder selected by the user
	userRootFolders: any[] = [];				// List of user folders from the database
	filteredUserRootFolders: any[] = [];		//
	expandedRootId = -1;						// 
	newSubFolderParentId = -1;					// 
	tempFolder = '';							// 

	// People Dropdown Vars
	peopleHideAddIcon = false;					// If true, a new tag is displayed
	peopleShowSelector = false;					// If true, the people dropdown list is displayed
	selectedPeople: any[] = [];					// People that were selected by the user
	userPeople: any[] = [];						// List of connections + user people from the database. This list is displayed when the user presses the down icon
	filteredUserPeople: any[] = [];				// A subset of userPeople, used to populate the people selector dropdown when a user types into the input
	tempPerson = '';							//


	// Keywords Dropdown Vars
	keywordsHideAddIcon = false;				// If true, a new tag is displayed
	keywordsShowSelector: boolean = false;		// If true, the keywords dropdown list is displayed
	selectedKeywords: any[] = [];				// Keywords that were selected by the user
	userKeywords: any[] = [];					// List of user keywords from the database. This list is displayed when the user presses the down icon
	filteredUserKeywords: any[] = [];			// A subset of userKeywords, used to populate the keyword selector dropdown when a user types into the input
	tempKeyword = '';							// 

	// Locations Dropdown Vars
	locationsHideAddIcon = false;				// If true, a new tag is displayed
	locationsShowSelector: boolean = false;		// If true, the locations dropdown list is displayed
	locationsShowSuggestions: boolean = false;	// If true, public address suggestions are displayed
	selectedLocations: any[] = [];				// Location that was selected by the user
	userLocations: any[] = [];					//
	filteredUserLocations: any[] = [];			//
	filteredPublicLocations: any[] = [];		//
	tempLocation = '';							// 

	// Tags Dropdown Vars
	tagsHideAddIcon = false;					// If true, a new tag is displayed
	tagsShowSelector: boolean = false;			// If true, the tags dropdown list is displayed
	selectedTags: any[] = [];					// Tags that were selected by the user
	userTags: any[] = [];						// List of user tags from the database. This list is displayed when the user presses the down icon
	filteredUserTags: any[] = [];				// A subset of userTags, used to populate the tag selector dropdown when a user types into the input
	filteredPublicTags: any[] = [];				// Looked-up public tags, used to populate the tag selector dropdown when a user types into the input
	tempTag = '';								// When a user types a new value in to the tags input, it is saved in tempTag.  This value is then added to the array after the user presses enter or clicks the check icon

	constructor(
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private connectionService: UserConnectionService,
		private folderService: FolderService,
		private layoutService: LayoutService,
		private userService: UserService
	) { }

	/**
	 * INIT
	 */
	ngOnInit() {
		this.subscribeToUserChanged();
		this.subscribeToFolderInputChanged();
		this.subscribeToPeopleInputChanged();
		this.subscribeToLocationInputChanged();
		this.subscribeToKeywordsInputChanged();
		this.subscribeToTagsInputChanged();

		this.loadUserFolders();
		this.loadPeople();
		this.loadUserLocations();
		this.loadUserKeywords();
		this.loadUserPublicTags();

		if (this.userService.users[0]) {
			this.user = this.userService.users[0];

			this.checkUserPaymentMethod();
			this.loadFormData();
		} else {
			this.initForm();
		}
	}

	initForm() {
		this.form = this.formBuilder.group({
			'folder': new UntypedFormControl(),
			'newSubFolderName': new UntypedFormControl(),
			'people': new UntypedFormControl(),
			'locations': new UntypedFormControl(),
			'keywords': new UntypedFormControl(),
			'tags': new UntypedFormControl([]),
			'connectionsCanView': new UntypedFormControl(false, []),
			'connectionsCanReact': new UntypedFormControl(false, []),
			'connectionsCanDiscuss': new UntypedFormControl(false, []),
			'connectionsCanSuggest': new UntypedFormControl(false, []),
			'connectionsCanSeeExif': new UntypedFormControl(false, []),
		});

		this.form.controls['tags'].disable();
	}

	/**
	 * SUBSCRIPTIONS
	 */
	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.userService.users[0].id) {
					this.user = user;

					this.checkUserPaymentMethod();
					this.loadFormData();
				}
			}
		);
	}

	subscribeToFolderInputChanged() {
		this.folderSearchSubject.pipe(debounceTime(this.debounceTime100Ms)).subscribe(() => {
			this.folderKeyupSearch();
		});
	}

	subscribeToPeopleInputChanged() {
		this.peopleSearchSubject.pipe(debounceTime(this.debounceTime100Ms)).subscribe(() => {
			this.peopleKeyupSearch();
		});
	}

	subscribeToLocationInputChanged() {
		this.locationsSearchSubject.pipe(debounceTime(this.debounceTime350Ms)).subscribe(() => {
			this.locationsKeyupSearch();
		});
	}

	subscribeToKeywordsInputChanged() {
		this.keywordsSearchSubject.pipe(debounceTime(this.debounceTime100Ms)).subscribe(() => {
			this.keywordsKeyupSearch();
		});
	}

	subscribeToTagsInputChanged() {
		this.tagsSearchSubject.pipe(debounceTime(this.debounceTime100Ms)).subscribe(() => {
			this.tagsKeyupSearch();
		});
	}

	/** 
	 * POST INIT FUNCTIONS 
	 */

	/** 
	 * LOAD FOLDERS
	 */
	loadUserFolders() {
		this.loadingFolders = true;
		this.folderService.getFolders(0).subscribe(
			response => {
				this.userRootFolders = response.body;

				this.userRootFolders.sort((a, b) => a.name.toString().localeCompare(b.name));
				this.userRootFolders = this.userRootFolders.slice();

				this.loadingFolders = false;
			}
		);
	}

	/**
	 * LOAD USER KEYWORDS
	 * 
	 * Load the list of user keywords for the Keywords dropdown. 
	 * 
	 * 1. Load the user keywords. 
	 * 2. Sort the keywords alphabetically.
	 * 
	 * TODO: Implement cacheing.
	 */
	loadUserKeywords() {
		this.userService.getUserKeywords().subscribe(
			response => {
				for (const keyword of response) {
					this.userKeywords.push({ name: keyword, type: 'db' });
				}
				this.userKeywords.sort((a, b) => a.name.localeCompare(b.name));
				this.userKeywords = this.userKeywords.slice();

				this.loadingUserKeywords = false;
			}
		);
	}

	/**
	 * LOAD USER PUBLIC TAGS
	 * 
	 * Load the list of user tags from the database. 
	 * 
	 * 1. Load the user tags. 
	 * 2. Sort the tags alphabetically.
	 * 
	 * TODO: Implement cacheing.
	 */
	loadUserPublicTags() {
		this.userService.getUserPublicTags().subscribe(
			response => {
				for (const tag of response) {
					this.userTags.push({ name: tag, type: 'db' });
				}
				this.userTags.sort((a, b) => a.name.localeCompare(b.name));
				this.userTags = this.userTags.slice();

				this.loadingUserPublicTags = false;
			}
		);
	}

	/**
	 * LOAD PEOPLE
	 * 
	 * Load the list of user connections for People dropdown.
	 *
	 * 1. Add the current user.
	 * 2. Retrieve and add active user connections.
	 * 3. Sort the people alphabetically.
	 * 
	 * TODO: Implement cacheing.
	 */
	loadPeople() {
		let user = this.userService.getLocalUser(0);
		if (user?.firstName) {
			user.name = user.firstName + " " + user.lastName;
			this.userPeople.push(user);
		} else {
			// If the page is reloaded the user is not yet ready.
			this.userService.retrieveCurrentUser().subscribe(
				response => {
					user = response.body;
					user.name = response.body.firstName + " " + response.body.lastName;
					this.userPeople.push(user);
				}
			);
		}

		this.connectionService.getUserConnectionsActive().subscribe(
			response => {
				for (const user of response.body) {
					user.name = user.firstName + " " + user.lastName;
					this.userPeople.push(user);
				}
				this.userPeople.sort((a, b) => a.name.localeCompare(b.name));
				this.userPeople = this.userPeople.slice();

				this.loadUserPeople();
			}
		);
	}

	/**
	 * LOAD USER PEOPLE
	 * 
	 * Load the list of non-users for the People dropdown.
	 * 
	 * 1. Retrieve and add user people.
	 * 2. Sort the people alphabetically.
	 * 
	 * TODO: Implement cacheing.
	 * TODO: Do not add duplicate names.
	 */
	loadUserPeople() {
		this.userService.getUserPeople().subscribe(
			response => {
				for (const person of response) {
					this.userPeople.push({ name: person, type: 'db' });
				}
				this.userPeople.sort((a, b) => a.name.localeCompare(b.name));
				this.userPeople = this.userPeople.slice();

				this.loadingUserPeople = false;
			}
		);
	}

	/**
	 * LOAD USER LOCATIONS
	 * 
	 * Load list of user locations for the Locations dropdown.
	 * 
	 * 1. 
	 * 
	 * TODO: Implement cacheing.
	 */
	loadUserLocations() {
		this.userService.getUserLocations().subscribe(
			response => {
				for (const location of response) {
					this.userLocations.push({ name: location, type: 'db' });
				}
				this.userLocations.sort((a, b) => a.name.localeCompare(b.name));
				this.userLocations = this.userLocations.slice();

				this.loadingUserLocations = false;
			}
		);
	}


	/** 
	 * POST USER LOAD FUNCTIONS 
	 */

	checkUserPaymentMethod() {
		if (this.user.paymentMethodId) {
			let now = new Date(); // 5/13/22
			let expireDate = new Date(this.user.paymentMethodCcExpirationYear, this.user.paymentMethodCcExpirationMonth, 0);

			if (expireDate > now) {
				this.paymentMethodExists = true;
			}
		}
	}

	loadFormData() {
		this.form = this.formBuilder.group({
			'folder': new UntypedFormControl(),
			'newSubFolderName': new UntypedFormControl(),
			'people': new UntypedFormControl(),
			'locations': new UntypedFormControl(),
			'keywords': new UntypedFormControl(),
			'tags': new UntypedFormControl(),
			'connectionsCanView': new UntypedFormControl(this.userService.users[0].connectionsCanViewByDefault, []),
			'connectionsCanReact': new UntypedFormControl(this.userService.users[0].connectionsCanReactByDefault, []),
			'connectionsCanDiscuss': new UntypedFormControl(this.userService.users[0].connectionsCanDiscussByDefault, []),
			'connectionsCanSuggest': new UntypedFormControl(this.userService.users[0].connectionsCanSuggestByDefault, []),
			'connectionsCanSeeExif': new UntypedFormControl(this.userService.users[0].connectionsCanSeeExifByDefault, []),
		});
	}

	/**
	 * FOLDER FUNCTIONS
	 */

	// Happens when the user presses the down arrow on the folder input
	showAllUserFolders() {
		if (this.foldersShowSelector) {
			this.resetFolderSelector();
		} else {
			this.foldersShowSelector = true;
		}
	}

	// Happens when the user clicks inside the folder input
	folderFocus() {
		this.foldersShowSelector = true;
	}

	// Happens when the user releases any key when in the folder input
	// This provides a delay so the search doesn't trigger as often
	folderKeyup() {
		this.folderSearchSubject.next(null);
	}

	// Happens when the user releases any key when in the folder input
	// Triggered after the delay in the above function
	folderKeyupSearch() {
		let value = this.form.controls['folder'].value;
		if (value.length > 0) {
			this.tempFolder = value;
			this.foldersShowSelector = true;
			this.filteredUserRootFolders = this.userRootFolders.filter(d => String(d.name).toUpperCase().includes(this.tempFolder.toUpperCase()));
		} else {
			this.tempFolder = '';
			this.filteredUserRootFolders = [];
		}
	}

	// Happens when a user selects the No Folder option in the folder selector
	selectNoFolder() {
		this.selectedRootFolder = null;
		this.selectedSubFolder = null;
		this.expandedRootId = -1;
		this.newSubFolderParentId = -1;
		this.foldersShowSelector = false;
	}

	// Happens when a user selects a folder in the folder selector
	selectFolder(folder, subfolder) {
		this.selectedRootFolder = folder;
		this.selectedSubFolder = subfolder;

		this.resetFolderSelector();
	}

	// Happens when a user presses the expand icon next to a root folder
	expandFolder(folder) {
		if (folder.folders && folder.folders.length > 0) {
			this.newSubFolderParentId = -1;
			this.form.controls['newSubFolderName'].setValue('');

			this.expandedRootId = folder.id;
		}
	}

	// Happens when a user presses the collapse icon next to a root folder
	collapseFolder() {
		this.expandedRootId = -1;
	}

	// Happens when the user presses the X icon after typing to create a new root folder
	cancelCreateRootFolder() {
		this.tempFolder = '';
		this.form.controls['folder'].setValue('');
	}

	// Happens when a user presses the check icon after typing to create a new root folder
	createRootFolder() {
		let item = this.userRootFolders.find(d => d.name.toUpperCase() === this.tempFolder.toUpperCase());
		if (!item) {
			this.loadingCreateFolder = 0;

			let folder: Folder = new Folder;
			folder.name = this.form.controls['folder'].value.toUpperCase();
			folder.parentId = 0;

			this.folderService.createFolder(folder).subscribe(
				response => {
					this.userRootFolders.push(response.body);

					this.userRootFolders.sort((a, b) => a.name.toString().localeCompare(b.name));
					this.userRootFolders = this.userRootFolders.slice();

					this.form.controls['folder'].setValue('');
					this.tempFolder = '';

					this.loadingCreateFolder = -1;
				}
			);
		}
	}

	// Happens when a user presses the + Create Subfolder option next to a root folder
	showCreateSubFolder(parentFolderId) {
		this.expandedRootId = parentFolderId;

		this.newSubFolderParentId = parentFolderId;
	}

	// Happens when a user presses the X icon within the create subfolder dialog
	cancelShowCreateSubFolder() {
		this.newSubFolderParentId = -1;
	}

	// Happens when a user presses the check icon within the create subfolder dialog
	createSubFolder(parentFolderId) {
		this.loadingCreateFolder = parentFolderId;

		let folder: Folder = new Folder;
		folder.name = this.form.value.newSubFolderName.toUpperCase();
		folder.parentId = parentFolderId;

		this.folderService.createFolder(folder).subscribe(
			response => {
				let parentFolder: Folder = this.userRootFolders.find(d => d.id === parentFolderId);
				if (parentFolder) {
					if (parentFolder.folders) {
						parentFolder.folders.push(response.body);
					} else {
						parentFolder.folders = [];
						parentFolder.folders.push(response.body);
					}

					parentFolder.folders.sort((a, b) => a.name.toString().localeCompare(b.name));
					parentFolder.folders = parentFolder.folders.slice();

					this.newSubFolderParentId = -1;

					this.loadingCreateFolder = -1;
				} else {
					// Error handling, just pull the folders over again completely
					this.loadingCreateFolder = -1;
				}

			}
		);
	}

	// Resets the form, which happens after a few different actions
	resetFolderSelector() {
		this.form.controls['folder'].setValue('');
		this.form.controls['newSubFolderName'].setValue('');

		this.filteredUserRootFolders = [];
		this.expandedRootId = -1;

		this.newSubFolderParentId = -1;
		this.tempFolder = '';

		this.foldersShowSelector = false;
	}

















	/** 
	 * PEOPLE DROPDOWN FUNCTIONS 
	 */

	addPersonTag() {
		let user = { name: "", type: "new" };
		this.selectedPeople.push(user);

		this.peopleHideAddIcon = true;
	}

	// Happens when the user presses the down arrow on the people input
	showAllUserPeople() {
		this.peopleShowSelector = !this.peopleShowSelector;
	}

	// Happens when the user clicks inside the people input
	peopleFocus() {
		this.peopleShowSelector = true;
	}

	// Happens when the user releases any key when in the locations input
	// This provides a delay so the search doesn't trigger as often
	peopleKeyup() {
		this.peopleSearchSubject.next(null);
	}

	// Happens when the user releases any key when in the people input
	// Triggered after the delay in the above function
	peopleKeyupSearch() {
		let value = this.inputPerson.nativeElement.value;
		if (value.length > 0) {
			this.tempPerson = value;
			this.peopleShowSelector = true;
			this.filteredUserPeople = this.userPeople.filter(d => String(d.name).toUpperCase().includes(this.tempPerson.toUpperCase()));
		} else {
			this.tempPerson = '';
			this.filteredUserPeople = [];
		}
	}

	// Happens when the user releases the enter key when in the people input, or after a user presses the check icon after typing in the people input
	// This should add the typed value as a new person
	peopleKeyupEnter() {
		let existingItem = this.selectedPeople.find(d => d.name.toUpperCase() === this.tempPerson.toUpperCase());
		if (!existingItem && this.tempPerson.length > 1) {
			let emptyItem = this.selectedPeople.find(d => d.name.toUpperCase() === '');
			emptyItem.name = this.tempPerson.toUpperCase();
			emptyItem.type = 'new';

			this.resetPeopleSelector();

			this.peopleHideAddIcon = false;
		}
	}

	// Happens when the user clicks on a person
	selectPerson(person) {
		let existingItem = this.selectedPeople.find(d => d.name.toUpperCase() === person.name.toUpperCase());
		if (!existingItem) {
			let emptyItem = this.selectedPeople.find(d => d.name.toUpperCase() === '');
			emptyItem.name = person.name;
			emptyItem.type = person.type || 'db';

			this.resetPeopleSelector();

			this.peopleHideAddIcon = false;
		}

		let removeItem = this.userPeople.find(d => d.name.toUpperCase() === person.name.toUpperCase());
		if (removeItem) {
			this.userPeople.splice(this.userPeople.indexOf(removeItem), 1);
		}
	}

	// Happens when the user presses the X icon on the selected person
	removePerson(person, hideAddIcon) {
		if (person.type !== 'new') {
			let itemAdd = this.userPeople.find(d => d.name.toUpperCase() === person.name.toUpperCase());
			if (!itemAdd) {
				this.userPeople.push({ name: person.name.toUpperCase(), type: person.type });
				this.userPeople.sort((a, b) => a.name.localeCompare(b.name));
				this.userPeople = this.userPeople.slice();
			}
		}

		let itemRemove = this.selectedPeople.find(d => d.name.toUpperCase() === person.name.toUpperCase());
		if (itemRemove) {
			this.selectedPeople.splice(this.selectedPeople.indexOf(itemRemove), 1);
		}

		if (hideAddIcon) {
			this.peopleHideAddIcon = false;

			this.peopleShowSelector = false;
		}
	}

	// Happens when a user presses the X icon after typing in the people input
	cancelCreatePerson() {
		this.inputPerson.nativeElement.value = '';
		this.tempPerson = '';
	}

	// Resets the form, which happens after a few different actions
	resetPeopleSelector() {
		this.filteredUserPeople = [];
		this.peopleShowSelector = false;
		this.tempPerson = '';
	}







	/** 
	 * LOCATIONS DROPDOWN FUNCTIONS 
	 */

	addLocationTag() {
		let location = { name: "", type: "new" };
		this.selectedLocations.push(location);

		this.locationsHideAddIcon = true;
	}

	// Happens when the user presses the down arrow on the locations input
	showAllUserLocations() {
		if (this.locationsShowSelector) {
			this.resetLocationsSelector();
		} else {
			this.locationsShowSelector = true;
		}
	}

	// Happens when the user clicks inside the locations input
	locationsFocus() {
		this.locationsShowSelector = true;
	}

	// Happens when the user releases any key when in the locations input
	// This provides a delay so the search doesn't trigger as often
	locationsKeyup() {
		this.locationsSearchSubject.next(null);
	}

	// Happens when the user releases any key when in the locations input
	// Triggered after the delay in the above function
	locationsKeyupSearch() {
		let value = this.inputLocation.nativeElement.value;
		if (value.length > 0) {
			this.tempLocation = value;
			this.locationsShowSelector = true;

			if (this.locationsShowSuggestions) {
				this.filteredUserLocations = [];

				this.getLocationSuggestions();
			} else {
				this.filteredPublicLocations = [];
				this.filteredUserLocations = this.userLocations.filter(d => String(d.name).toUpperCase().includes(this.tempLocation.toUpperCase()));
			}

		} else {
			this.tempLocation = '';
			this.filteredUserLocations = [];
		}
	}

	// Happens when the user releases the enter key when in the locations input, or after a user presses the check icon after typing in the locations input
	// This should add the typed value as a new location
	locationsKeyupEnter() {
		let existingItem = this.selectedLocations.find(d => d.name.toUpperCase() === this.tempLocation.toUpperCase());
		if (!existingItem && this.tempLocation.length > 1) {
			let emptyItem = this.selectedLocations.find(d => d.name.toUpperCase() === '');
			emptyItem.name = this.tempLocation.toUpperCase();
			emptyItem.type = 'new';

			this.resetLocationsSelector();

			this.locationsHideAddIcon = false;
		}
	}

	// Happens when the user presses the Load More Results button in the locations selector
	loadMoreLocationResults() {
		this.locationsShowSuggestions = true;

		this.locationsKeyupSearch();
	}

	// Retrieves suggestions
	getLocationSuggestions() {
		this.filteredPublicLocations = [];

		let value = this.inputLocation.nativeElement.value;
		this.userService.getLocationSuggestions(value).subscribe(
			response => {
				this.filteredPublicLocations = response.body.items;
			}
		);
	}

	// Happens when the user clicks on a location
	selectLocation(location) {
		let existingItem = this.selectedLocations.find(d => d.name.toUpperCase() === location.name.toUpperCase());
		if (!existingItem) {
			let emptyItem = this.selectedLocations.find(d => d.name.toUpperCase() === '');
			emptyItem.name = location.name;
			emptyItem.type = location.type || 'db';

			this.resetLocationsSelector();

			this.locationsHideAddIcon = false;
		}

		let removeItem = this.userLocations.find(d => d.name.toUpperCase() === location.name.toUpperCase());
		if (removeItem) {
			this.userLocations.splice(this.userLocations.indexOf(removeItem), 1);
		}
	}

	// Happens when the user clicks on a location suggestion
	selectLocationSuggestion(name) {
		let existingItem = this.selectedLocations.find(d => d.name.toUpperCase() === name.toUpperCase());
		if (!existingItem) {
			let emptyItem = this.selectedLocations.find(d => d.name.toUpperCase() === '');
			emptyItem.name = name;
			emptyItem.type = 'new';
		}

		this.resetLocationsSelector();

		this.locationsHideAddIcon = false;
	}

	// Happens when the user presses the X icon on the selected location
	removeLocation(location, hideAddIcon) {
		if (location.type !== 'new') {
			let itemAdd = this.userLocations.find(d => d.name.toUpperCase() === location.name.toUpperCase());
			if (!itemAdd) {
				this.userLocations.push({ name: location.name.toUpperCase(), type: location.type });
				this.userLocations.sort((a, b) => a.name.localeCompare(b.name));
				this.userLocations = this.userLocations.slice();
			}
		}

		let itemRemove = this.selectedLocations.find(d => d.name.toUpperCase() === location.name.toUpperCase());
		if (itemRemove) {
			this.selectedLocations.splice(this.selectedLocations.indexOf(itemRemove), 1);
		}

		if (hideAddIcon) {
			this.locationsHideAddIcon = false;
		}
	}

	// Happens when a user presses the X icon after typing in the locations input
	cancelCreateLocation() {
		this.tempLocation = '';
		this.inputLocation.nativeElement.value = '';
	}

	// Resets the form, which happens after a few different actions
	resetLocationsSelector() {
		this.filteredUserLocations = [];
		this.filteredPublicLocations = [];
		this.locationsShowSelector = false;
		this.locationsShowSuggestions = false;
		if (this.inputLocation) {
			this.inputLocation.nativeElement.value = '';
		}
		this.tempLocation = '';
	}


	/** 
	 * KEYWORDS FUNCTIONS 
	 */

	addKeywordTag() {
		let keyword = { name: "", type: "new" };
		this.selectedKeywords.push(keyword);

		this.keywordsHideAddIcon = true;
	}

	// Happens when the user presses the down arrow on the keywords input
	showAllUserKeywords() {
		this.keywordsShowSelector = !this.keywordsShowSelector;
	}

	// Happens when the user clicks inside the keywords input
	keywordsFocus() {
		this.keywordsShowSelector = true;
	}

	// Happens when the user releases any key when in the keywords input
	// This provides a delay so the search doesn't trigger as often
	keywordsKeyup() {
		this.keywordsSearchSubject.next(null);
	}

	// Happens when the user releases any key when in the keywords input
	// Triggered after the delay in the above function
	keywordsKeyupSearch() {
		let value = this.inputKeyword.nativeElement.value;
		if (value.length > 0) {
			this.tempKeyword = value;
			this.keywordsShowSelector = true;
			this.filteredUserKeywords = this.userKeywords.filter(d => String(d.name).toUpperCase().includes(this.tempPerson.toUpperCase()));
		} else {
			this.tempKeyword = '';
			this.filteredUserKeywords = [];
		}
	}

	// Happens when the user releases the enter key when in the keywords input, or after a user presses the check icon after typing in the keywords input
	// This should add the typed value as a new keyword
	keywordsKeyupEnter() {
		let existingItem = this.selectedKeywords.find(d => d.name.toUpperCase() === this.tempKeyword.toUpperCase());
		if (!existingItem && this.tempKeyword.length > 1) {
			let emptyItem = this.selectedKeywords.find(d => d.name.toUpperCase() === '');
			emptyItem.name = this.tempKeyword.toUpperCase();
			emptyItem.type = 'new';

			this.resetKeywordsSelector();

			this.keywordsHideAddIcon = false;
		}
	}

	// Happens when the user clicks on a keyword
	selectKeyword(keyword) {
		let existingItem = this.selectedKeywords.find(d => d.name.toUpperCase() === keyword.name.toUpperCase());
		if (!existingItem) {
			let emptyItem = this.selectedKeywords.find(d => d.name.toUpperCase() === '');
			emptyItem.name = keyword.name;
			emptyItem.type = keyword.type || 'db';

			this.resetKeywordsSelector();

			this.keywordsHideAddIcon = false;
		}

		let removeItem = this.userKeywords.find(d => d.name.toUpperCase() === keyword.name.toUpperCase());
		if (removeItem) {
			this.userKeywords.splice(this.userKeywords.indexOf(removeItem), 1);
		}
	}

	// Happens when the user presses the X icon on the selected keyword
	removeKeyword(keyword, hideAddIcon) {
		if (keyword.type !== 'new') {
			let itemAdd = this.userKeywords.find(d => d.name.toUpperCase() === keyword.name.toUpperCase());
			if (!itemAdd) {
				this.userKeywords.push({ name: keyword.name.toUpperCase(), type: keyword.type });
				this.userKeywords.sort((a, b) => a.name.localeCompare(b.name));
				this.userKeywords = this.userKeywords.slice();
			}
		}

		let itemRemove = this.selectedKeywords.find(d => d.name.toUpperCase() === keyword.name.toUpperCase());
		if (itemRemove) {
			this.selectedKeywords.splice(this.selectedKeywords.indexOf(itemRemove), 1);
		}

		if (hideAddIcon) {
			this.keywordsHideAddIcon = false;
		}
	}

	// Happens when a user presses the X icon after typing in the keywords input
	cancelCreateKeyword() {
		this.inputKeyword.nativeElement.value = '';
		this.tempKeyword = '';
	}

	// 
	resetKeywordsSelector() {
		this.filteredUserKeywords = [];
		this.keywordsShowSelector = false;
		this.tempKeyword = '';
	}

	/** 
	 * PUBLIC TAGS DROPDOWN FUNCTIONS 
	 */

	addUserTag() {
		let tag = { name: "", type: "new" };
		this.selectedTags.push(tag);

		this.tagsHideAddIcon = true;
	}

	// Happens when the user presses the arrow down icon in the tag input
	showAllUserTags() {
		this.tagsShowSelector = !this.tagsShowSelector;
	}

	// Happens when the user clicks inside the tags input
	tagsFocus() {
		this.tagsShowSelector = true;
	}

	// Happens when the user releases any key when in the tags input
	tagsKeyup() {
		this.tagsSearchSubject.next(null);
	}

	tagsKeyupSearch() {
		let value = this.inputTag.nativeElement.value;
		if (value.length > 1) {
			this.tempTag = value;
			this.tagsShowSelector = true;
			this.filteredUserTags = this.userTags.filter(d => String(d.name).toUpperCase().includes(this.tempTag.toUpperCase()));
			this.findPublicTags(this.tempTag.toUpperCase());
		} else {
			this.tagsShowSelector = false;
			this.tempTag = '';

			this.filteredUserTags = [];
			this.filteredPublicTags = [];
		}
	}

	// Happens when the user releases the enter key when in the tags input
	// This should add the typed value as a new tag
	tagsKeyupEnter() {
		let existingItem = this.selectedTags.find(d => d.name.toUpperCase() === this.tempTag.toUpperCase());
		if (!existingItem && this.tempTag.length > 1) {

			let emptyItem = this.selectedTags.find(d => d.name.toUpperCase() === '');
			emptyItem.name = this.tempTag.toUpperCase();
			emptyItem.type = 'new';
		}

		this.resetTagsSelector();

		this.tagsHideAddIcon = false;
	}

	// 
	findPublicTags(keyword) {
		this.loadingUserPublicTags = true;
		this.filteredPublicTags = [];
		this.userService.findPublicTagsByKeyword(keyword).subscribe(
			response => {
				for (const tag of response) {
					let item = this.filteredUserTags.find(d => d.name === tag);
					if (!item) {
						this.filteredPublicTags.push({ name: tag, type: 'new' });
					}
				}
				this.filteredPublicTags.sort((a, b) => a.name.localeCompare(b.name));
				this.filteredPublicTags = this.filteredPublicTags.slice();

				this.loadingUserPublicTags = false;
			}
		);
	}

	// Happens when the user clicks on a tag
	selectTag(tag) {
		let existingItem = this.selectedTags.find(d => d.name.toUpperCase() === tag.name.toUpperCase());
		if (!existingItem) {
			let emptyItem = this.selectedTags.find(d => d.name.toUpperCase() === '');
			emptyItem.name = tag.name;
			emptyItem.type = tag.type || 'db';

			this.resetTagsSelector();

			this.tagsHideAddIcon = false;
		}

		let removeItem = this.userTags.find(d => d.name.toUpperCase() === tag.name.toUpperCase());
		if (removeItem) {
			this.userTags.splice(this.userTags.indexOf(removeItem), 1);
		}
	}

	// Happens when the user presses the X icon on the tag
	removeTag(tag, hideAddIcon) {
		if (tag.type !== 'new') {
			let itemAdd = this.userTags.find(d => d.name.toUpperCase() === tag.name.toUpperCase());
			if (!itemAdd) {
				this.userTags.push({ name: tag.name.toUpperCase(), type: tag.type });
				this.userTags.sort((a, b) => a.name.localeCompare(b.name));
				this.userTags = this.userTags.slice();
			}
		}

		let itemRemove = this.selectedTags.find(d => d.name.toUpperCase() === tag.name.toUpperCase());
		if (itemRemove) {
			this.selectedTags.splice(this.selectedTags.indexOf(itemRemove), 1);
		}

		if (hideAddIcon) {
			this.tagsHideAddIcon = false;
		}
	}

	cancelCreateTag() {
		this.filteredPublicTags = [];
		this.filteredUserTags = [];
		this.tagsShowSelector = false;
		this.inputTag.nativeElement.value = '';
		this.tempTag = '';
	}

	clearTags() {
		this.selectedTags = [];
		this.filteredUserTags = [];
		this.filteredPublicTags = [];
		this.tagsShowSelector = false;
		this.tempTag = '';
	}

	resetTagsSelector() {
		this.filteredPublicTags = [];
		this.filteredUserTags = [];
		this.tagsShowSelector = false;
		if (this.inputTag) {
			this.inputTag.nativeElement.value = '';
		}
		this.tempTag = '';
	}

	/** 
	 * UPLOAD PHOTOS FUNCTIONS 
	 */

	uploadPhotosSubmit() {
		let index = this.files.length;
		while (index--) {
			let photoUpload: PhotoUpload = new PhotoUpload();
			photoUpload.file = this.files[index];

			if (this.selectedSubFolder) {
				photoUpload.selectedFolder = this.selectedSubFolder;
			} else {
				photoUpload.selectedFolder = this.selectedRootFolder;
			}

			if (this.selectedPeople && this.selectedPeople.length > 0) {
				let selectedPeopleArray = [];
				for (let person of this.selectedPeople) {
					selectedPeopleArray.push(person.name.toUpperCase());
				}
				photoUpload.selectedPeople = selectedPeopleArray;
			}

			if (this.selectedLocations && this.selectedLocations.length > 0) {
				let selectedLocationsArray = [];
				for (let location of this.selectedLocations) {
					selectedLocationsArray.push(location.name.toUpperCase());
				}
				photoUpload.selectedLocations = selectedLocationsArray;
			}

			if (this.selectedKeywords && this.selectedKeywords.length > 0) {
				let selectedKeywordsArray = [];
				for (let keyword of this.selectedKeywords) {
					selectedKeywordsArray.push(keyword.name.toUpperCase());
				}
				photoUpload.selectedKeywords = selectedKeywordsArray;
			}

			if (this.selectedTags && this.selectedTags.length > 0) {
				let selectedTagsArray = [];
				for (let tag of this.selectedTags) {
					selectedTagsArray.push(tag.name.toUpperCase());
				}
				photoUpload.selectedTags = selectedTagsArray;
			}

			photoUpload.connectionsCanView = this.form.controls['connectionsCanView'].value;
			photoUpload.connectionsCanReact = this.form.controls['connectionsCanReact'].value;
			photoUpload.connectionsCanDiscuss = this.form.controls['connectionsCanDiscuss'].value;
			photoUpload.connectionsCanSuggest = this.form.controls['connectionsCanSuggest'].value;
			photoUpload.connectionsCanSeeExif = this.form.controls['connectionsCanSeeExif'].value;

			this.layoutService.announceUploadPhoto(photoUpload);

			this.files.splice(index, 1);

			if (index == 0) {
				this.createCustomMetadata();
				this.clearForm();
			}
		}
	}

	// If you select a tag, it moves it from userKeyword to selectedKeyword.  If you press the reset form, the selectedKeywords get lost.
	// This function moves them back as needed.
	resetSelectedMetadata() {
		for (const item of this.selectedPeople) {
			if (item.type == 'db') {
				this.userPeople.push(item);
			}
		}
		this.userPeople.sort((a, b) => a.name.localeCompare(b.name));
		this.userPeople = this.userPeople.slice();


		for (const item of this.selectedLocations) {
			if (item.type == 'db') {
				this.userLocations.push(item);
			}
		}
		this.userLocations.sort((a, b) => a.name.localeCompare(b.name));
		this.userLocations = this.userLocations.slice();

		for (const item of this.selectedKeywords) {
			if (item.type == 'db') {
				this.userKeywords.push(item);
			}
		}
		this.userKeywords.sort((a, b) => a.name.localeCompare(b.name));
		this.userKeywords = this.userKeywords.slice();

		for (const item of this.selectedTags) {
			if (item.type == 'db') {
				this.userTags.push(item);
			}
		}
		this.userTags.sort((a, b) => a.name.localeCompare(b.name));
		this.userTags = this.userTags.slice();

		this.selectedPeople = [];
		this.selectedLocations = [];
		this.selectedKeywords = [];
		this.selectedTags = [];
	}

	/**
	 * createCustomMetadata - On the last loop, add any custom metadata.
	 */

	createCustomMetadata() {
		// Add new people to the user people table
		if (this.selectedPeople) {
			for (const item of this.selectedPeople) {
				if (item.type == 'new') {
					this.userService.addUserPerson(item.name.toUpperCase()).subscribe(
						response => {
							item.type = 'db';
							this.userPeople.push(item);
						});
				} else {
					this.userPeople.push(item);
				}
			}
		}

		// Add new keywords to the user keyword table
		if (this.selectedKeywords) {
			for (const item of this.selectedKeywords) {
				if (item.type == 'new') {
					this.userService.addUserKeyword(item.name.toUpperCase()).subscribe(
						response => {
							item.type = 'db';
							this.userKeywords.push(item);
						});
				} else {
					this.userKeywords.push(item);
				}
			}
		}

		// Add new locations to the user location table
		if (this.selectedLocations) {
			for (const item of this.selectedLocations) {
				if (item.type == 'new') {
					this.userService.addUserLocation(item.name.toUpperCase()).subscribe(
						response => {
							item.type = 'db';
							this.userLocations.push(item);
						});
				} else {
					this.userLocations.push(item);
				}
			}
		}

		// Add new global tags to the user tags table
		if (this.selectedTags) {
			for (const item of this.selectedTags) {
				if (item.type == 'new') {
					this.userService.addUserPublicTag(item.name.toUpperCase()).subscribe(
						response => {
							item.type = 'db';
							this.userTags.push(item);
						});
				} else {
					this.userTags.push(item);
				}
			}
		}
	}

	onPhotoAdd(event) {
		this.files.push(...event.addedFiles);
	}

	onPhotoRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	clearFiles() {
		this.files = [];
	}

	clearForm() {
		this.resetFolderSelector();
		this.resetPeopleSelector();
		this.resetLocationsSelector();
		this.resetKeywordsSelector();
		this.resetTagsSelector();

		this.resetSelectedMetadata();

		this.selectedRootFolder = null;
		this.selectedSubFolder = null;

		this.peopleHideAddIcon = false;
		this.locationsHideAddIcon = false;
		this.keywordsHideAddIcon = false;
		this.tagsHideAddIcon = false;

		this.clearFiles();
		this.loadFormData();
	}


	/** PHOTO PERMISSION FUNCTIONS **/

	connectionsCanViewSwitchChanged(event) {
		if (event.srcElement.checked) {
			// Enable
			this.loadFormData();

			this.tagsHideAddIcon = false;
		} else {
			// Disable
			this.initForm();

			// Remove public tags
			this.clearTags();

			this.tagsHideAddIcon = true;
		}
	}


	/** HELPER FUNCTIONS **/

	parseExifDate(s) {
		var b = s.split(/\D/);
		return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
	}

	numDaysBetween(d1, d2) {
		var diff = Math.abs(d1.getTime() - d2.getTime());
		return diff / (1000 * 60 * 60 * 24);
	}

	navigate(path) {
		this.router.navigate([path]);
	}

	navigateToPaymentMethods() {
		this.router.navigate(['profile/'], { queryParams: { tab: 'payment' } });
	}
}

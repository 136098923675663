import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Event } from 'src/app/models/event.model';
import { User } from 'src/app/models/user.model';
import { EventService } from 'src/app/services/event.service';
import { PhotoService } from 'src/app/services/photo.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-user-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.css']
})
export class UserTimelineComponent implements OnInit {
    math = Math;

    error = false;

    loadingEvents = true;
    loadingEventsWait = true; // This is required for the events fade in animation, we delay turning this false after events are loaded
    loadingPhotos = false;

    currentUser: User;

    timelineUser: User;
    timelineUserBirthDate: Date;

    events: Event[] = [];
    eventsDisplayedOnTimeline: Event[] = [];

    eventActive: Event;
    eventHover: Event;
    eventPhotos = [];
    eventSelectedOnce = false;

    unselectEvent;

    locationEmbedUrl;

    fadeText1 = false;
    fadeText2 = false;

    coverPhotoId: number = -1;

    year0;
    year1;
    year2;
    year3;
    year4;
    year5;
    year6;
    year7;
    year8;
    year9;

    constructor(
        private domSanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router,
        private eventService: EventService,
        private photoService: PhotoService,
        private userService: UserService,
    ) { }

    // 1. Determine user
    // 2. Retrieve user
    // 3. Calculate timeline years
    // 4. Retrieve events
    // 5. Calculate timeline events
    // 6. 
    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params['userUuid']) {
                this.retrieveTimelineUser(params['userUuid']);
            } else {
                this.retrieveTimelineUser('');
            }

            let _this = this;
            setTimeout(function () {
                _this.fadeText1 = true;
            }, 1000);

            setTimeout(function () {
                _this.fadeText2 = true;
            }, 2000);
        });
    }

    retrieveCurrentUser(uuid) {
        if (uuid.length > 0) {
            // Not me

        } else {
            // Me
        }
    }

    retrieveTimelineUser(uuid) {
        if (uuid.length > 0) {
            // Not me
            let user = this.userService.users.find(d => d.uuid === uuid);
            if (user) {
                this.timelineUser = user;
                let _this = this;
                setTimeout(function () {
                    _this.performSecurityCheck(_this.timelineUser, uuid);
                }, 100);
            } else {
                this.userService.retrieveUserBasicByUuid(uuid).subscribe(
                    response => {
                        this.timelineUser = response.body;
                        this.performSecurityCheck(this.timelineUser, uuid);
                    },
                    err => {
                        this.error = true;
                        this.loadingEvents = false;
                    }
                );
            }
        } else {
            // Me
            if (this.userService.users[0]) {
                this.timelineUser = this.userService.users[0];
                let _this = this;
                setTimeout(function () {
                    _this.performSecurityCheck(_this.timelineUser, uuid);
                }, 100);
            } else {
                this.userService.retrieveCurrentUser().subscribe(
                    response => {
                        this.timelineUser = response.body;
                        this.performSecurityCheck(this.timelineUser, uuid);
                    },
                    err => {
                        this.error = true;
                        this.loadingEvents = false;
                    }
                );
            }
        }
    }

    performSecurityCheck(user, uuid) {
        if (uuid.length > 0) {
            // Not me, perform security check
            if (user.isTimelinePrivate == undefined || user.isTimelinePrivate) {
                // Show error
                this.error = true;
                this.loadingEvents = false;
            } else {
                // Continue 
                // let _this = this;
                // setTimeout(function () {
                //     _this.calculateTimelineYears(user);
                // }, 500);
                this.calculateTimelineYears(user);
            }
        } else {
            this.calculateTimelineYears(user);
            // let _this = this;
            // setTimeout(function () {
            //     _this.calculateTimelineYears(user);
            // }, 500);
        }
    }

    calculateTimelineYears(user) {
        // Birth year
        this.timelineUserBirthDate = user.birthDate;
        let birthYear = new Date(user.birthDate);
        this.year0 = birthYear.getFullYear();

        // Current year
        this.year9 = new Date().getFullYear();

        // Gap years
        let age = this.year9 - this.year0;
        let ageGap = age / 9;
        this.year1 = this.year0 + ageGap;
        this.year2 = this.year1 + ageGap;
        this.year3 = this.year2 + ageGap;
        this.year4 = this.year3 + ageGap;
        this.year5 = this.year4 + ageGap;
        this.year6 = this.year5 + ageGap;
        this.year7 = this.year6 + ageGap;
        this.year8 = this.year7 + ageGap;

        this.retrieveEvents();
    }

    // TODO: GET MEMBER EVENTS TOO
    retrieveEvents() {
        this.events = [];
        this.eventsDisplayedOnTimeline = [];

        this.eventService.getEventsForTimeline(this.timelineUser.id).subscribe(
            response => {
                if (response.status === 200) {
                    this.events = response.body;
                    this.eventsDisplayedOnTimeline = response.body;

                    this.loadingEvents = false;

                    let _this = this;
                    setTimeout(function () {
                        _this.loadingEventsWait = false;
                    }, 500);
                } else {
                    this.error = true;
                    this.loadingEvents = false;
                }
            },
            err => {
                this.error = true;
                this.loadingEvents = false;
            }
        );
    }

    hoverEvent(event) {
        if (!this.eventActive?.id) {
            this.eventHover = event;
        }
    }

    unhoverEvent(event) {
        this.eventHover = null;
    }

    selectEvent(event) {
        this.eventSelectedOnce = true;
        this.eventHover = null;
        if (this.eventActive?.id == event.id) {
            this.unselectEvent = -1; // Triggers the animation
            this.eventPhotos = [];
            this.eventActive = null;
        } else {
            this.eventActive = event;

            if (event.location && event.locationShowMap) {
                let url = 'https://www.google.com/maps/embed/v1/place?&q=' + event.location + '&key=AIzaSyBaIc0xglS3_DQ0S5h8B-nnnm1ohED0BsI'
                url = url.replace(/\s/g, "+");

                this.locationEmbedUrl = url;
            } else {
                this.locationEmbedUrl = null;
            }

            this.retrieveEventCoverPhoto();
        }
    }

    closeEvent() {
        this.unselectEvent = -1; // Triggers the animation
        this.eventPhotos = [];
        this.eventActive = null;
    }

    retrieveEventCoverPhoto() {
        this.eventPhotos = [];
        if (this.eventActive.photosOrder?.length > 0) {
            let coverPhotoIds = JSON.parse(this.eventActive.photosOrder);

            this.coverPhotoId = coverPhotoIds[0];

            this.eventService.getEventCoverPhotoByShareCode1AndShareCode2(this.eventActive.shareCode1, this.eventActive.shareCode2).subscribe(
                response => {
                    let photo = response.body;
                    photo.eventCoverPhoto = true;

                    let token = this.photoService.signedDownloadTokens.find(d => d.eventId === this.eventActive.id.toString());
                    if (token) {
                        if (!photo.imageSafeUrl) {
                            let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + token.token;

                            let image = { image: imageSafeUrl, thumbImage: imageSafeUrl, eventCoverPhoto: true };
                            this.eventPhotos.push(image);
                        } else {
                            let image = { image: photo.imageSafeUrl, thumbImage: photo.imageSafeUrl };
                            this.eventPhotos.push(image);
                        }

                        // This is dumb, but it does work to make the cover photo always first.
                        let _this = this;
                        setTimeout(function () {
                            _this.retrieveEventPhotos(0, _this.eventActive.shareCode1, _this.eventActive.shareCode2);
                        }, 500);
                    } else {
                        this.photoService.getSignedDownloadToken(this.eventActive.id.toString(), 'event').subscribe(
                            response => {
                                let responseToken = response.body;
                                responseToken.eventId = this.eventActive.id.toString();

                                this.photoService.signedDownloadTokens.push(responseToken);

                                if (!photo.imageSafeUrl) {
                                    let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + response.body.token;

                                    let image = { image: imageSafeUrl, thumbImage: imageSafeUrl, eventCoverPhoto: true };
                                    this.eventPhotos.push(image);
                                } else {
                                    let image = { image: photo.imageSafeUrl, thumbImage: photo.imageSafeUrl };
                                    this.eventPhotos.push(image);
                                }

                                // This is dumb, but it does work to make the cover photo always first.
                                let _this = this;
                                setTimeout(function () {
                                    _this.retrieveEventPhotos(0, _this.eventActive.shareCode1, _this.eventActive.shareCode2);
                                }, 500);
                            }
                        );
                    }
                }
            );
        } else {
            this.retrieveEventPhotos(0, this.eventActive.shareCode1, this.eventActive.shareCode2);
        }
    }

    retrieveEventPhotos(startPosition, shareCode1, shareCode2) {
        // if (startPosition == 0) {
        //     this.eventPhotos = [];
        // }
        this.loadingPhotos = true;

        //this.lastStartPosition = this.eventPhotosPointer;

        // Retrieve event photos
        this.eventService.retrieveEventPhotosByShareCode1AndShareCode2(startPosition, shareCode1, shareCode2).subscribe(
            response => {
                if (response.status === 200 && response.body.length > 0) {
                    let index = 0;
                    let photos = response.body;
                    let _this = this;

                    (function repeat() {
                        setTimeout(function () {
                            let photo = photos[index];

                            if (photos[index].id !== _this.coverPhotoId) {


                                let token = _this.photoService.signedDownloadTokens.find(d => d.eventId === _this.eventActive.id.toString());
                                if (token) {
                                    if (!photo.imageSafeUrl) {
                                        let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + token.token;

                                        let image = { image: imageSafeUrl, thumbImage: imageSafeUrl };
                                        _this.eventPhotos.push(image);
                                    } else {
                                        let image = { image: photo.imageSafeUrl, thumbImage: photo.imageSafeUrl };
                                        _this.eventPhotos.push(image);
                                    }

                                    _this.loadingPhotos = false;

                                    index++;
                                    if (index == photos.length) {
                                        // console.log(_this.eventPhotos);
                                    } else {
                                        repeat();
                                    }
                                } else {
                                    _this.photoService.getSignedDownloadToken(_this.eventActive.id.toString(), 'event').subscribe(
                                        response => {
                                            let responseToken = response.body;
                                            responseToken.eventId = _this.eventActive.id.toString();

                                            _this.photoService.signedDownloadTokens.push(responseToken);

                                            if (!photo.imageSafeUrl) {
                                                let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + response.body.token;

                                                let image = { image: imageSafeUrl, thumbImage: imageSafeUrl };
                                                _this.eventPhotos.push(image);
                                            } else {
                                                let image = { image: photo.imageSafeUrl, thumbImage: photo.imageSafeUrl };
                                                _this.eventPhotos.push(image);
                                            }

                                            _this.loadingPhotos = false;

                                            index++;
                                            if (index == photos.length) {
                                                // console.log(_this.eventPhotos);
                                            } else {
                                                repeat();
                                            }
                                        }
                                    );
                                }
                            } else {
                                _this.loadingPhotos = false;

                                index++;
                                if (index == photos.length) {
                                    // console.log(_this.eventPhotos);
                                } else {
                                    repeat();
                                }
                            }
                        }, 10);
                    })();
                } else {
                    this.loadingPhotos = false;
                }
            });
    }

    calculateLeftPosition(date: Date) {
        let years = this.year9 - this.year0;
        let leftPercent = (this.getAgeFromBirthdate(date) / years) * 100;
        return leftPercent;
    }

    getAgeFromBirthdate(date: Date) {
        const d1 = new Date(date);
        const d2 = new Date(this.timelineUserBirthDate);

        let age = d1.getFullYear() - d2.getFullYear();
        const m = d1.getMonth() - d2.getMonth();
        if (m < 0 || (m === 0 && d1.getDate() < d2.getDate())) {
            age--;
        }
        return age;
    }

    navigate(route: string) {
        this.router.navigate([route]);
    }
}

<div *ngIf="photoDeleted" class="photo-message-container">
	<div>The photo has been deleted</div>
</div>
<div class="modal-body" [ngClass]="{'loading in': loading}">
	<form #s="ngForm" [formGroup]="classifyForm" class="form">

		<div class="left-container">
			<div class="photo-container">
				<img class="main 2" *ngIf="photo?.imageSafeUrl" [src]="photo?.imageSafeUrl" />
			</div>
			<div class="description-container">
				<div *ngIf="tab == 'classifyTab' && editMode && photo?.userId == userService.getLocalUserId(0)">
					<textarea name="description" id="description" formControlName="description" class="multiline-text-field" placeholder="Enter a description" rows="4"></textarea>
				</div>
				<div *ngIf="tab !== 'classifyTab' || !editMode || photo?.userId !== userService.getLocalUserId(0)">
					{{ photo?.description }}
				</div>
			</div>
			<div *ngIf="deleteConfirm" class="delete-container">
				<div>Delete this photo?</div>
				<div><button class="delete yes" (click)="deletePhotoConfirm()">Yes</button> <button class="delete no" (click)="deleteConfirm=false">No</button></div>
			</div>
		</div>

		<div class="right-container">
			<div>
				<tabset>
					<tab [active]="tab == 'discussTab'" heading="Discuss" id="discussTab" (selectTab)="tabSelect('discussTab')">
						<div class="form-group comments-container" [ngClass]="{'empty': photoComments.length == 0}">
							<ng-container *ngIf="photo?.connectionsCanDiscuss || photo?.userId == userService.getLocalUserId(0)">

								<div *ngIf="photoComments.length == 0 && !photo.connectionsCanDiscuss" class="first-comment">
									<i class="fas fa-comments"></i>
									<div>Discussion is not enabled</div>
								</div>
								<div *ngIf="photoComments.length == 0 && photo.connectionsCanDiscuss" class="first-comment">
									<i class="fas fa-comments"></i>
									<div *ngIf="!photo.lockDiscussion">Be the first to comment</div>
									<div *ngIf="photo.lockDiscussion">&nbsp;</div>
								</div>
								<ng-container *ngIf="photoComments.length > 0">
									<ng-container *ngFor="let photoComment of photoComments">
										<app-photo-comment [photoComment]="photoComment" [showReplies]="false" [allowReplies]="photo.connectionsCanDiscuss && !photo.lockDiscussion"></app-photo-comment>
									</ng-container>
								</ng-container>
							</ng-container>
							<ng-container *ngIf="!photo.connectionsCanDiscuss && photo.userId !== userService.getLocalUserId(0)">
								<div class="first-comment">
									<i class="fas fa-comments"></i>
									<div>Discussion is not enabled</div>
								</div>
							</ng-container>
						</div>
					</tab>
					<tab [active]="tab == 'shareTab'" heading="Share" id="shareTab" *ngIf="photo?.userId == userService.getLocalUserId(0)" (selectTab)="tabSelect('shareTab')">
						<!-- Public Tags -->
						<div class="form-group delete-container" *ngIf="photo && !photo.connectionsCanView">
							To add public tags, you must enable the <br />View setting on the Options tab.
						</div>
						<div class="form-group tag-outer-group" *ngIf="photo && photo.connectionsCanView">
							<label for="tags" class="keywords-label">Public Tags:
								<i class="fas fa-question-circle" [tooltip]="tagsTemplate" placement="bottom"></i>
								<ng-template #tagsTemplate>
									Public tags give a unique way of sharing photos with connections and non-connections. For example, the tags "Rossville High School" and "2002 Graduation" will allow anyone to view these photos by searching for these terms.
								</ng-template>
							</label>
							<!-- No Selected Tags -->
							<form #t="ngForm" [formGroup]="tagsForm" class="form tag-form">
								<div *ngIf="selectedTags.length == 0" class="tag-select-container disabled">
									<i class="fas fa-hashtag left"></i>No data entered
								</div>
								<!-- Selected Tags -->
								<ng-container *ngFor="let tag of selectedTags">
									<!-- Selected -->
									<div *ngIf="tag.name !== ''" class="tag-select-container">
										<i class="fas fa-hashtag left"></i>
										<span *ngIf="tag.new == true" class="new-icon">NEW</span>
										<div class="title" [ngClass]="{'margin-32': editTagsMode}">{{ tag.name }}<div *ngIf="!editTagsMode" class="spacer"></div>
										</div>
										<div *ngIf="editTagsMode" class="tag-icon-container">
											<i class="fas fa-times" (click)="removeTag(tag, false)"></i>
										</div>
									</div>
									<!-- Not Selected -->
									<div *ngIf="editMode && tag.name == ''" class="tag-select-container">
										<i class="fas fa-hashtag left"></i>
										<input #inputTag placeholder="add a tag" (focus)="tagsFocus()" (keyup)="tagsKeyup()" autocomplete="off" />
										<!-- Buttons-->
										<div *ngIf="editTagsMode && tempTag.length == 0" class="tag-icon-container">
											<i *ngIf="!tagsShowSelector && userTags.length > 0" class="fas fa-caret-down" (click)="showAllUserTags()"></i>
											<i *ngIf="tagsShowSelector && userTags.length > 0" class="fas fa-caret-up" (click)="showAllUserTags()"></i>
											<i class="fas fa-times" (click)="removeTag(tag, true)"></i>
										</div>
										<div *ngIf="editTagsMode && tempTag.length > 0" class="tag-icon-container">
											<i class="fas fa-times" (click)="cancelCreateTag()"></i>
											<i class="fas fa-check" (click)="tagsKeyupEnter()"></i>
										</div>
										<!-- Selectors -->
										<div class="tags-dropdown-container" [ngClass]="{'hidden': !tagsShowSelector || userTags.length == 0 || filteredUserTags.length > 0 || filteredPublicTags.length > 0 || (tagsShowSelector && filteredUserTags.length == 0 && filteredPublicTags.length == 0 && tempTag.length > 0)}">
											<div class="heading">My Tags</div>
											<div *ngFor="let tag of userTags" class="item" (click)="selectTag(tag)">#{{ tag.name }}</div>
										</div>
										<div class="tags-dropdown-container" [ngClass]="{'hidden': !tagsShowSelector || (filteredUserTags.length == 0 && filteredPublicTags.length == 0)}">
											<div *ngIf="filteredUserTags.length > 0" class="heading">My Tags</div>
											<div *ngFor="let tag of filteredUserTags" class="item" (click)="selectTag(tag)">#{{ tag.name }}</div>
											<div *ngIf="filteredPublicTags.length > 0" class="heading">Public Tags</div>
											<div *ngFor="let tag of filteredPublicTags" class="item" (click)="selectTag(tag)">#{{ tag.name }}</div>
										</div>
									</div>
								</ng-container>
								<!-- Add Tag Icon -->
								<i *ngIf="editTagsMode && !tagsHideAddIcon" class="fas fa-plus add-tag-icon" (click)="addUserTag()"></i>
							</form>
						</div>
					</tab>

					<tab [active]="tab == 'classifyTab'" heading="Classify" id="classifyTab" (selectTab)="tabSelect('classifyTab')">
						<!-- Folder -->
						<div class="form-group tag-outer-group" *ngIf="photo && photo.userId == userService.getLocalUserId(0)">
							<!-- Label -->
							<label for="folder" class="keywords-label">
								Select an optional folder location:
							</label>
							<!-- Loading -->
							<i *ngIf="loadingFolders" class="fas fa-circle-notch fa-spin"></i>
							<!-- Not Edit Mode -->
							<ng-container *ngIf="!editMode && !loadingFolders">
								<div *ngIf="!selectedRootFolder" class="tag-select-container folder disabled">
									<i class="fas fa-folder left"></i>No folder selected<div class="spacer"></div>
								</div>
								<div *ngIf="selectedRootFolder" class="tag-select-container folder">
									<i class="fas fa-folder left"></i>{{ selectedRootFolder?.name }}<div class="tag-subfolder" *ngIf="selectedSubFolder">&#187; {{ selectedSubFolder?.name }}</div>
									<div class="spacer"></div>
								</div>
							</ng-container>
							<!-- Edit Mode -->
							<ng-container *ngIf="editMode && !loadingFolders">
								<!-- No Selected Folder -->
								<div class="tag-select-container folder" [ngClass]="{'disabled': !foldersShowSelector && !selectedRootFolder}">
									<i class="fas fa-folder left"></i>
									<input [ngClass]="{'hidden': selectedRootFolder}" #inputFolder placeholder="Select or create a folder" id="folder" formControlName="folder" (focus)="folderFocus()" (keyup)="folderKeyup()" autocomplete="off" />
									<div [ngClass]="{'hidden': !selectedRootFolder}" class="title">{{ selectedRootFolder?.name }}<div class="tag-subfolder" *ngIf="selectedSubFolder">&#187; {{ selectedSubFolder?.name }}</div>
									</div>
									<!-- Buttons-->
									<div *ngIf="tempFolder.length == 0" class="tag-icon-container">
										<i *ngIf="!foldersShowSelector && userRootFolders.length > 0" class="fas fa-caret-down" (click)="showAllUserFolders()"></i>
										<i *ngIf="foldersShowSelector && userRootFolders.length > 0" class="fas fa-caret-up" (click)="showAllUserFolders()"></i>
										<i class="fas fa-times" (click)="selectNoFolder(folder)"></i>
									</div>
									<div *ngIf="tempFolder.length > 0" class="tag-icon-container">
										<i class="fas fa-times" (click)="cancelCreateRootFolder()"></i>
										<i class="fas fa-check" (click)="createRootFolder()"></i>
									</div>
									<!-- Selectors -->
									<div class="tags-dropdown-container folder" [ngClass]="{'hidden': !foldersShowSelector || filteredUserRootFolders?.length > 0 || userRootFolders.length == 0 }">
										<!-- List folders -->
										<ng-container *ngFor="let folder of userRootFolders">
											<!-- Root folder -->
											<div class="item">
												<div>
													<i class="fas fa-angle-right left" *ngIf="expandedRootId !== folder.id" [ngClass]="{'disabled': !folder.folders || folder.folders?.length == 0}" (mousedown)="expandFolder(folder)"></i>
													<i class="fas fa-angle-down left" *ngIf="expandedRootId == folder.id" (mousedown)="collapseFolder()"></i>
													<i class="fas fa-folder left"></i>
												</div>
												<div class="grow" (mousedown)="selectFolder(folder, null)"><span [ngClass]="{'strong': selectedRootFolder?.id == folder.id}">{{ folder.name }}</span></div>
												<div><i class="fas fa-folder-plus" *ngIf="newSubFolderParentId == -1" (mousedown)="showCreateSubFolder(folder.id)" title="Create sub-folder"></i></div>
											</div>
											<!-- Create sub-folder -->
											<div [ngClass]="{'hidden': newSubFolderParentId !== folder.id}" class="new-sub-folder">
												<i class="fas fa-folder"></i>
												<input formControlName="newSubFolderName" placeholder="Enter folder name" />
												<i class="fas fa-times" (click)="cancelShowCreateSubFolder(folder.id)"></i>
												<i class="fas fa-check" *ngIf="classifyForm.value.newSubFolderName?.length > 0" (click)="createSubFolder(folder.id)"></i>
												<div class="icon-spacer" *ngIf="!classifyForm.value.newSubFolderName || classifyForm.value.newSubFolderName?.length == 0"></div>
											</div>
											<!-- List Sub-folders -->
											<ng-container *ngIf="expandedRootId == folder.id">
												<ng-container *ngFor="let subfolder of folder.folders">
													<div class="subfolder" (click)="selectFolder(folder, subfolder)">
														<i class="fas fa-folder"></i>
														<span [ngClass]="{'strong': selectedRootFolder?.id == subfolder.id}">{{ subfolder.name }}</span>
													</div>
												</ng-container>
											</ng-container>
										</ng-container>
									</div>
									<div class="folder-select-container" [ngClass]="{'hidden': !foldersShowSelector || filteredUserRootFolders.length == 0 || userRootFolders.length == 0}">
										<!-- List folders -->
										<ng-container *ngFor="let folder of filteredUserRootFolders">
											<!-- Root folder -->
											<div class="item">
												<div>
													<i class="fas fa-angle-right" *ngIf="expandedRootId !== folder.id" [ngClass]="{'disabled': !folder.folders || folder.folders?.length == 0}" (mousedown)="expandFolder($event, folder)"></i>
													<i class="fas fa-angle-down" *ngIf="expandedRootId == folder.id" (mousedown)="collapseFolder($event)"></i>
													<i class="fas fa-folder"></i>
												</div>
												<div class="grow" (mousedown)="selectFolder(folder, null)"><span [ngClass]="{'strong': selectedRootFolder?.id == folder.id}">{{ folder.name }}</span></div>
												<div><i class="fas fa-folder-plus" *ngIf="newSubFolderParentId == -1" (mousedown)="showCreateSubFolder(folder.id)" title="Create sub-folder"></i></div>
											</div>
											<!-- Create sub-folder -->
											<div [ngClass]="{'hidden': newSubFolderParentId !== folder.id}" class="new-sub-folder">
												<i class="fas fa-folder"></i>
												<input formControlName="newSubFolderName" placeholder="Enter folder name" />
												<i class="fas fa-times" (click)="cancelShowCreateSubFolder(folder.id)"></i>
												<i class="fas fa-check" *ngIf="classifyForm.value.newSubFolderName?.length > 0" (click)="createSubFolder(folder.id)"></i>
												<div class="icon-spacer" *ngIf="!classifyForm.value.newSubFolderName || classifyForm.value.newSubFolderName?.length == 0"></div>
											</div>
											<!-- List Sub-folders -->
											<ng-container *ngIf="expandedRootId == folder.id">
												<ng-container *ngFor="let subfolder of folder.folders">
													<div class="subfolder" (click)="selectFolder(folder, subfolder)">
														<i class="fas fa-folder"></i>
														<span [ngClass]="{'strong': selectedRootFolder?.id == subfolder.id}">{{ subfolder.name }}</span>
													</div>
												</ng-container>
											</ng-container>
										</ng-container>
									</div>
								</div>
							</ng-container>
						</div>

						<!-- People -->
						<div class="form-group tag-outer-group" *ngIf="photo">
							<!-- Label -->
							<label for="people" class="keywords-label">Who is in the photo?</label>
							<!-- Loading -->
							<i *ngIf="loadingPeople" class="fas fa-circle-notch fa-spin"></i>
							<!-- No Selected Tags -->
							<div *ngIf="selectedPeople.length == 0 && !loadingPeople" class="tag-select-container disabled">
								<i class="fas fa-user left"></i>No data entered
							</div>
							<!-- Selected Tags -->
							<ng-container *ngIf="!loadingPeople">
								<ng-container *ngFor="let person of selectedPeople">
									<!-- Selected -->
									<div *ngIf="person.name !== ''" class="tag-select-container" [ngClass]="{'suggestion': person.type == 'suggestion' || person.type == 'suggestion-new' }">
										<i class="fas fa-user left"></i>
										<span *ngIf="person.new == true" class="new-icon">NEW</span>
										<div class="title" [ngClass]="{'margin-60': (person.type == 'suggestion' && photo.userId == userService.getLocalUserId(0)), 'margin-32': (person.type == 'suggestion' && person.userId == userService.getLocalUserId(0)) || (editMode && person.userId == userService.getLocalUserId(0)) || (editMode && person.type !== 'suggestion' && photo.userId !== userService.getLocalUserId(0)) }">{{ person.name }}
											<span *ngIf="(person.type == 'suggestion' || person.type == 'suggestion-new') && person.action == 'add'">(Add)</span>
											<span *ngIf="(person.type == 'suggestion' || person.type == 'suggestion-new') && person.action == 'remove'">(Remove)</span>
										</div>

										<div *ngIf="(!editMode && person.type !== 'suggestion') || (!editMode && person.type == 'suggestion' && person.userId !== userService.getLocalUserId(0)) || (editMode && person.userId !== userService.getLocalUserId(0))" class="spacer"></div>

										<!-- EditMode.
										 Current user is the photo owner.
									 	 Current user added the tag.
										 Type = db || new.
									 		REMOVE TAG - READY TO TEST
									-->
										<div *ngIf="editMode && photo.userId == userService.getLocalUserId(0) && person.userId == userService.getLocalUserId(0) && (person.type == 'db' || person.type == 'new')" class="tag-icon-container">
											<i class="fas fa-times" (click)="removePerson(person, false)"></i>
										</div>

										<!-- Current user is the photo owner.
									 	 Current user did not add the tag.
										 Type = suggestion.
									 		APPROVE OR DECLINE - READY TO TEST
									-->
										<div *ngIf="photo.userId == userService.getLocalUserId(0) && person.userId !== userService.getLocalUserId(0) && person.type == 'suggestion'" class="tag-icon-container">
											<i class="fas fa-thumbs-up" title="Accept suggestion" (click)="acceptSuggestion(person, 'person')"></i>
											<i class="fas fa-thumbs-down" title="Decline suggestion" (click)="declineSuggestion(person, 'person')"></i>
										</div>

										<!-- Current user is not the photo owner. 
									 	 Current user added the tag.
										 Type = suggestion.
									 		CANCEL EXISTING SUGGESTION - READY TO TEST
									-->
										<div *ngIf="photo.userId !== userService.getLocalUserId(0) && person.userId == userService.getLocalUserId(0) && person.type == 'suggestion'" class="tag-icon-container">
											<i class="fas fa-undo" title="Cancel suggestion" (click)="cancelSuggestion(person, 'person')"></i>
										</div>

										<!-- EditMode.
									 	 Current user is not the photo owner. 
										 Current user added the tag.
										 Type = suggestion-new.
										 Action = add.
											CANCEL ADD SUGGESTION  - READY TO TEST
									-->
										<div *ngIf="editMode && photo.userId !== userService.getLocalUserId(0) && person.userId == userService.getLocalUserId(0) && person.type == 'suggestion-new' && person.action == 'add'" class="tag-icon-container">
											<i class="fas fa-times" (click)="removePerson(person, false)"></i>
										</div>

										<!-- EditMode.
										 Current user is not the photo owner. 
									 	 Current user did not add the tag. 
										 Type = db.
									 		SUGGEST REMOVING TAG - READY TO TEST
									-->
										<div *ngIf="editMode && photo.userId !== userService.getLocalUserId(0) && person.userId !== userService.getLocalUserId(0) && person.type == 'db'" class="tag-icon-container">
											<i class="fas fa-times" (click)="removePerson(person, false)"></i>
										</div>

										<!-- EditMode.
										 Current user is not the photo owner. 
										 Current user added the tag.
										 Type = suggestion-new.
										 Action = remove.
											CANCEL REMOVE SUGGESTION - READY TO TEST
									-->
										<div *ngIf="editMode && photo.userId !== userService.getLocalUserId(0) && person.userId == userService.getLocalUserId(0) && person.type == 'suggestion-new' && person.action == 'remove'" class="tag-icon-container">
											<i class="fas fa-undo" (click)="cancelNewSuggestion(person)"></i>
										</div>

									</div>
									<!-- Not Selected -->
									<div *ngIf="editMode && person.name == ''" class="tag-select-container" [ngClass]="{'suggestion': person.type == 'suggestion' || person.type == 'suggestion-new'}">
										<i class="fas fa-user left"></i>
										<input #inputPerson placeholder="add a person" (focus)="peopleFocus()" (keyup)="peopleKeyup()" autocomplete="off" />
										<!-- Buttons-->
										<div *ngIf="tempPerson.length == 0" class="tag-icon-container">
											<i *ngIf="!peopleShowSelector && userPeople.length > 0" class="fas fa-caret-down" (click)="showAllUserPeople()"></i>
											<i *ngIf="peopleShowSelector && userPeople.length > 0" class="fas fa-caret-up" (click)="showAllUserPeople()"></i>
											<i class="fas fa-times" (click)="removePerson(person, true)"></i>
										</div>
										<div *ngIf="tempPerson.length > 0" class="tag-icon-container">
											<i class="fas fa-times" (click)="cancelCreatePerson()"></i>
											<i class="fas fa-check" (click)="peopleKeyupEnter()"></i>
										</div>
										<!-- Selectors -->
										<div class="tags-dropdown-container" [ngClass]="{'hidden': !peopleShowSelector || filteredUserPeople.length > 0 || userPeople.length == 0}">
											<div *ngFor="let person of userPeople" class="item" (click)="selectPerson(person)">{{ person.name }}</div>
										</div>
										<div class="tags-dropdown-container" [ngClass]="{'hidden': !peopleShowSelector || filteredUserPeople.length == 0}">
											<div *ngFor="let person of filteredUserPeople" class="item" (click)="selectPerson(person)">{{ person.name }}</div>
										</div>
									</div>
								</ng-container>
							</ng-container>
							<!-- Add Tag Icon -->
							<i *ngIf="editMode && !peopleHideAddIcon && !loadingPeople" class="fas fa-plus add-tag-icon" (click)="addPersonTag()"></i>
						</div>

						<!-- Date -->
						<div class="form-group tag-outer-group">
							<label for="date" class="keywords-label">When was this photo taken?</label>
							<!-- Not Edit Mode -->
							<!-- SHOW IF: (NOT EDIT MODE OR USER NOT CURRENT USER) AND HAS DATE -->
							<div *ngIf="(!editMode || photo?.userId !== userService.getLocalUserId(0)) && photo?.capturedDate" class="tag-select-container">
								<i class="fas fa-calendar-alt left"></i>{{ photo?.capturedDate | date:'fullDate' }}
								<div class="spacer"></div>
							</div>
							<!-- SHOW IF: (NOT EDIT MODE OR USER NOT CURRENT USER) AND DOES NOT HAVE DATE -->
							<div *ngIf="(!editMode || photo?.userId !== userService.getLocalUserId(0)) && !photo?.capturedDate" class="tag-select-container disabled">
								<i class="fas fa-calendar-alt left"></i>No data entered
							</div>
							<!-- Edit Mode -->
							<div *ngIf="editMode && photo?.userId == userService.getLocalUserId(0) && !dateHideAddIcon && !photo.capturedDate && !photo.capturedDateAuto" class="tag-select-container disabled">
								<i class="fas fa-calendar-alt left"></i>No data entered
							</div>
							<i *ngIf="editMode && !dateHideAddIcon && !photo?.capturedDate && photo?.userId == userService.getLocalUserId(0)" class="fas fa-plus add-tag-icon" (click)="addDate()"></i>
							<div class="tag-select-container" [ngClass]="{'hidden': !editMode || (!dateHideAddIcon && !photo.capturedDate) || photo?.userId !== userService.getLocalUserId(0) }">
								<i class="fas fa-calendar-alt left"></i>
								<input #inputDate bsDatepicker type="text" placeholder="Select a date" id="capturedDate" formControlName="capturedDate" placement="top middle" [maxDate]="maxDate" autocomplete="off">
								<!-- Buttons-->
								<div class="tag-icon-container">
									<i class="fas fa-times" (click)="removeDate()"></i>
								</div>
							</div>
							<i *ngIf="photo?.capturedDateAuto" class="fas fa-clock" title="The date was automatically captured from the photo metadata"></i>
						</div>

						<!-- Locations -->
						<div class="form-group tag-outer-group" *ngIf="photo">
							<!-- Label -->
							<label for="locations" class="keywords-label">Where was this photo taken?</label>
							<!-- Loading -->
							<i *ngIf="loadingLocations" class="fas fa-circle-notch fa-spin"></i>
							<!-- No Selected Tags -->
							<div *ngIf="selectedLocations.length == 0 && !loadingLocations" class="tag-select-container disabled">
								<i class="fas fa-map-marker-alt left"></i>No data entered
							</div>
							<!-- Selected Tags -->
							<ng-container *ngIf="!loadingLocations">
								<ng-container *ngFor="let location of selectedLocations">
									<!-- Selected -->
									<div *ngIf="location.name !== ''" class="tag-select-container" [ngClass]="{'suggestion': location.type == 'suggestion' || location.type == 'suggestion-new' }">
										<i class="fas fa-user left"></i>
										<span *ngIf="location.new == true" class="new-icon">NEW</span>
										<div class="title" [ngClass]="{'margin-60': (location.type == 'suggestion' && photo.userId == userService.getLocalUserId(0)), 'margin-32': (location.type == 'suggestion' && location.userId == userService.getLocalUserId(0)) || (editMode && location.userId == userService.getLocalUserId(0)) || (editMode && location.type !== 'suggestion' && photo.userId !== userService.getLocalUserId(0)) }"><a href="https://www.google.com/maps/search/{{ location.name }}" target="_blank">{{ location.name }}</a>
											<span *ngIf="(location.type == 'suggestion' || location.type == 'suggestion-new') && location.action == 'add'">(Add)</span>
											<span *ngIf="(location.type == 'suggestion' || location.type == 'suggestion-new') && location.action == 'remove'">(Remove)</span>
										</div>

										<div *ngIf="(!editMode && location.type !== 'suggestion') || (!editMode && location.type == 'suggestion' && location.userId !== userService.getLocalUserId(0)) || (editMode && location.userId !== userService.getLocalUserId(0))" class="spacer"></div>

										<!-- EditMode.
										 Current user is the photo owner.
									 	 Current user added the tag.
										 Type = db || new.
									 		REMOVE TAG - READY TO TEST
									-->
										<div *ngIf="editMode && photo.userId == userService.getLocalUserId(0) && location.userId == userService.getLocalUserId(0) && (location.type == 'db' || location.type == 'new')" class="tag-icon-container">
											<i class="fas fa-times" (click)="removeLocation(location, false)"></i>
										</div>

										<!-- Current user is the photo owner.
									 	 Current user did not add the tag.
										 Type = suggestion.
									 		APPROVE OR DECLINE - READY TO TEST
									-->
										<div *ngIf="photo.userId == userService.getLocalUserId(0) && location.userId !== userService.getLocalUserId(0) && location.type == 'suggestion'" class="tag-icon-container">
											<i class="fas fa-thumbs-up" title="Accept suggestion" (click)="acceptSuggestion(location, 'location')"></i>
											<i class="fas fa-thumbs-down" title="Decline suggestion" (click)="declineSuggestion(location, 'location')"></i>
										</div>

										<!-- Current user is not the photo owner. 
									 	 Current user added the tag.
										 Type = suggestion.
									 		CANCEL EXISTING SUGGESTION - READY TO TEST
									-->
										<div *ngIf="photo.userId !== userService.getLocalUserId(0) && location.userId == userService.getLocalUserId(0) && location.type == 'suggestion'" class="tag-icon-container">
											<i class="fas fa-undo" title="Cancel suggestion" (click)="cancelSuggestion(location, 'location')"></i>
										</div>

										<!-- EditMode.
									 	 Current user is not the photo owner. 
										 Current user added the tag.
										 Type = suggestion-new.
										 Action = add.
											CANCEL ADD SUGGESTION  - READY TO TEST
									-->
										<div *ngIf="editMode && photo.userId !== userService.getLocalUserId(0) && location.userId == userService.getLocalUserId(0) && location.type == 'suggestion-new' && location.action == 'add'" class="tag-icon-container">
											<i class="fas fa-times" (click)="removeLocation(location, false)"></i>
										</div>

										<!-- EditMode.
										 Current user is not the photo owner. 
									 	 Current user did not add the tag. 
										 Type = db.
									 		SUGGEST REMOVING TAG - READY TO TEST
									-->
										<div *ngIf="editMode && photo.userId !== userService.getLocalUserId(0) && location.userId !== userService.getLocalUserId(0) && location.type == 'db'" class="tag-icon-container">
											<i class="fas fa-times" (click)="removeLocation(location, false)"></i>
										</div>

										<!-- EditMode.
										 Current user is not the photo owner. 
										 Current user added the tag.
										 Type = suggestion-new.
										 Action = remove.
											CANCEL REMOVE SUGGESTION - READY TO TEST
									-->
										<div *ngIf="editMode && photo.userId !== userService.getLocalUserId(0) && location.userId == userService.getLocalUserId(0) && location.type == 'suggestion-new' && location.action == 'remove'" class="tag-icon-container">
											<i class="fas fa-undo" (click)="cancelNewSuggestion(location)"></i>
										</div>

									</div>
									<!-- Not Selected -->
									<div *ngIf="editMode && location.name == ''" class="tag-select-container" [ngClass]="{'suggestion': location.type == 'suggestion' || location.type == 'suggestion-new'}">
										<i class="fas fa-user left"></i>
										<input #inputLocation placeholder="add a location" (focus)="locationsFocus()" (keyup)="locationsKeyup()" autocomplete="off" />
										<!-- Buttons-->
										<div *ngIf="tempLocation.length == 0" class="tag-icon-container">
											<i *ngIf="!locationsShowSelector && userLocations.length > 0" class="fas fa-caret-down" (click)="showAllUserLocations()"></i>
											<i *ngIf="locationsShowSelector && userLocations.length > 0" class="fas fa-caret-up" (click)="showAllUserLocations()"></i>
											<i class="fas fa-times" (click)="removeLocation(location, true)"></i>
										</div>
										<div *ngIf="tempLocation.length > 0" class="tag-icon-container">
											<i class="fas fa-times" (click)="cancelCreateLocation()"></i>
											<i class="fas fa-check" (click)="locationsKeyupEnter()"></i>
										</div>
										<!-- Selectors -->
										<div class="tags-dropdown-container" [ngClass]="{'hidden': !locationsShowSelector || filteredUserLocations.length > 0 || userLocations.length == 0}">
											<div *ngFor="let location of userLocations" class="item" (click)="selectLocation(location)">{{ location.name }}</div>
											<div class="item" (click)="loadMoreLocationResults()" *ngIf="tempLocation.length > 1">Load more results...</div>
										</div>
										<div class="tags-dropdown-container" [ngClass]="{'hidden': !locationsShowSelector || filteredUserLocations.length == 0  || locationsShowSuggestions }">
											<div *ngFor="let location of filteredUserLocations" class="item" (click)="selectLocation(location)" [title]="location.name">{{ location.name }}</div>
											<div class="item" (click)="loadMoreLocationResults()" *ngIf="tempLocation.length > 1">Load more results...</div>
										</div>
										<div class="tags-dropdown-container" [ngClass]="{'hidden': !locationsShowSelector || filteredPublicLocations.length == 0 }">
											<div *ngFor="let suggestion of filteredPublicLocations" class="item" (click)="selectLocationSuggestion(suggestion.title)" [title]="suggestion.title">{{ suggestion.title }}</div>
										</div>
										<div class="tags-dropdown-container" *ngIf="userLocations.length == 0 && filteredPublicLocations.length == 0 && tempLocation.length > 1">
											<div class="item" (click)="loadMoreLocationResults()">Load more results...</div>
										</div>
									</div>
								</ng-container>
							</ng-container>
							<!-- Add Tag Icon -->
							<i *ngIf="editMode && !locationsHideAddIcon && !loadingLocations" class="fas fa-plus add-tag-icon" (click)="addLocationTag()"></i>
						</div>

						<!-- Keywords -->
						<div class="form-group tag-outer-group" *ngIf="photo">
							<!-- Label -->
							<label for="keywords" class="keywords-label">Photo keywords:</label>
							<!-- Loading -->
							<i *ngIf="loadingKeywords" class="fas fa-circle-notch fa-spin"></i>
							<!-- No Selected Tags -->
							<div *ngIf="selectedKeywords.length == 0 && !loadingKeywords" class="tag-select-container disabled">
								<i class="fas fa-tag left"></i>No data entered
							</div>
							<!-- Selected Tags -->
							<ng-container *ngIf="!loadingKeywords">
								<ng-container *ngFor="let keyword of selectedKeywords">
									<!-- Selected -->
									<div *ngIf="keyword.name !== ''" class="tag-select-container" [ngClass]="{'suggestion': keyword.type == 'suggestion' || keyword.type == 'suggestion-new' }">
										<i class="fas fa-user left"></i>
										<span *ngIf="keyword.new == true" class="new-icon">NEW</span>
										<div class="title" [ngClass]="{'margin-60': (keyword.type == 'suggestion' && photo.userId == userService.getLocalUserId(0)), 'margin-32': (keyword.type == 'suggestion' && keyword.userId == userService.getLocalUserId(0)) || (editMode && keyword.userId == userService.getLocalUserId(0)) || (editMode && keyword.type !== 'suggestion' && photo.userId !== userService.getLocalUserId(0)) }">{{ keyword.name }}
											<span *ngIf="(keyword.type == 'suggestion' || keyword.type == 'suggestion-new') && keyword.action == 'add'">(Add)</span>
											<span *ngIf="(keyword.type == 'suggestion' || keyword.type == 'suggestion-new') && keyword.action == 'remove'">(Remove)</span>
										</div>

										<div *ngIf="(!editMode && keyword.type !== 'suggestion') || (!editMode && keyword.type == 'suggestion' && keyword.userId !== userService.getLocalUserId(0)) || (editMode && keyword.userId !== userService.getLocalUserId(0))" class="spacer"></div>

										<!-- EditMode.
																	 Current user is the photo owner.
																 	 Current user added the tag.
																	 Type = db || new.
																 		REMOVE TAG - READY TO TEST
																-->
										<div *ngIf="editMode && photo.userId == userService.getLocalUserId(0) && keyword.userId == userService.getLocalUserId(0) && (keyword.type == 'db' || keyword.type == 'new')" class="tag-icon-container">
											<i class="fas fa-times" (click)="removeKeyword(keyword, false)"></i>
										</div>

										<!-- Current user is the photo owner.
																 	 Current user did not add the tag.
																	 Type = suggestion.
																 		APPROVE OR DECLINE - READY TO TEST
																-->
										<div *ngIf="photo.userId == userService.getLocalUserId(0) && keyword.userId !== userService.getLocalUserId(0) && keyword.type == 'suggestion'" class="tag-icon-container">
											<i class="fas fa-thumbs-up" title="Accept suggestion" (click)="acceptSuggestion(keyword, 'keyword')"></i>
											<i class="fas fa-thumbs-down" title="Decline suggestion" (click)="declineSuggestion(keyword, 'keyword')"></i>
										</div>

										<!-- Current user is not the photo owner. 
																 	 Current user added the tag.
																	 Type = suggestion.
																 		CANCEL EXISTING SUGGESTION - READY TO TEST
																-->
										<div *ngIf="photo.userId !== userService.getLocalUserId(0) && keyword.userId == userService.getLocalUserId(0) && keyword.type == 'suggestion'" class="tag-icon-container">
											<i class="fas fa-undo" title="Cancel suggestion" (click)="cancelSuggestion(keyword, 'keyword')"></i>
										</div>

										<!-- EditMode.
																 	 Current user is not the photo owner. 
																	 Current user added the tag.
																	 Type = suggestion-new.
																	 Action = add.
																		CANCEL ADD SUGGESTION  - READY TO TEST
																-->
										<div *ngIf="editMode && photo.userId !== userService.getLocalUserId(0) && keyword.userId == userService.getLocalUserId(0) && keyword.type == 'suggestion-new' && keyword.action == 'add'" class="tag-icon-container">
											<i class="fas fa-times" (click)="removeKeyword(keyword, false)"></i>
										</div>

										<!-- EditMode.
																	 Current user is not the photo owner. 
																 	 Current user did not add the tag. 
																	 Type = db.
																 		SUGGEST REMOVING TAG - READY TO TEST
																-->
										<div *ngIf="editMode && photo.userId !== userService.getLocalUserId(0) && keyword.userId !== userService.getLocalUserId(0) && keyword.type == 'db'" class="tag-icon-container">
											<i class="fas fa-times" (click)="removeKeyword(keyword, false)"></i>
										</div>

										<!-- EditMode.
																	 Current user is not the photo owner. 
																	 Current user added the tag.
																	 Type = suggestion-new.
																	 Action = remove.
																		CANCEL REMOVE SUGGESTION - READY TO TEST
																-->
										<div *ngIf="editMode && photo.userId !== userService.getLocalUserId(0) && keyword.userId == userService.getLocalUserId(0) && keyword.type == 'suggestion-new' && keyword.action == 'remove'" class="tag-icon-container">
											<i class="fas fa-undo" (click)="cancelNewSuggestion(keyword)"></i>
										</div>
									</div>
									<!-- Not Selected -->
									<div *ngIf="editMode && keyword.name == ''" class="tag-select-container" [ngClass]="{'suggestion': keyword.type == 'suggestion' || keyword.type == 'suggestion-new'}">
										<i class="fas fa-user left"></i>
										<input #inputKeyword placeholder="add a keyword" (focus)="keywordsFocus()" (keyup)="keywordsKeyup()" autocomplete="off" />
										<!-- Buttons-->
										<div *ngIf="tempKeyword.length == 0" class="tag-icon-container">
											<i *ngIf="!keywordsShowSelector && userKeywords.length > 0" class="fas fa-caret-down" (click)="showAllUserKeywords()"></i>
											<i *ngIf="keywordsShowSelector && userKeywords.length > 0" class="fas fa-caret-up" (click)="showAllUserKeywords()"></i>
											<i class="fas fa-times" (click)="removeKeyword(keyword, true)"></i>
										</div>
										<div *ngIf="tempKeyword.length > 0" class="tag-icon-container">
											<i class="fas fa-times" (click)="cancelCreateKeyword()"></i>
											<i class="fas fa-check" (click)="keywordsKeyupEnter()"></i>
										</div>
										<!-- Selectors -->
										<div class="tags-dropdown-container" [ngClass]="{'hidden': !keywordsShowSelector || filteredUserKeywords.length > 0 || userKeywords.length == 0}">
											<div *ngFor="let keyword of userKeywords" class="item" (click)="selectKeyword(keyword)">{{ keyword.name }}</div>
										</div>
										<div class="tags-dropdown-container" [ngClass]="{'hidden': !keywordsShowSelector || filteredUserKeywords.length == 0}">
											<div *ngFor="let keyword of filteredUserKeywords" class="item" (click)="selectKeyword(keyword)">{{ keyword.name }}</div>
										</div>
									</div>
								</ng-container>
							</ng-container>
							<!-- Add Tag Icon -->
							<i *ngIf="editMode && !keywordsHideAddIcon && !loadingKeywords" class="fas fa-plus add-tag-icon" (click)="addKeywordTag()"></i>
						</div>

					</tab>

					<tab heading="EXIF Data" id="exifTab" (selectTab)="tabSelect($event)">
						<ng-container *ngIf="photo.connectionsCanSeeExif || photo.userId == userService.getLocalUserId(0)">
							<div class="form-group">
								<label>Photo Details</label>
								<div class="details-container-data">
									<div *ngIf="photo?.imageSize">Size: {{ photo?.imageSize / 1024 / 1024 | number : '1.2-2' }} MB</div>
									<div *ngIf="photo?.imageSizeOnDisk">Size on disk: {{ photo?.imageSizeOnDisk / 1024 / 1024 | number : '1.2-2' }} MB<br /></div>
									<div *ngIf="photo?.xresolution"> Resolution: {{ photo?.xresolution }} dpi</div>
									<div *ngIf="photo?.width && photo?.height">Dimensions: {{ photo?.width }}w x {{ photo?.height }}h</div>
								</div>
							</div>
							<div class="form-group">
								<label>Camera Details</label>
								<div class="details-container-data">
									<div *ngIf="!photo?.cameraMake && !photo?.cameraModel && !photo?.cameraLensMake && !photo?.cameraLensModel">Not Available</div>
									<div *ngIf="photo?.cameraMake">Camera Make: {{ photo?.cameraMake }}</div>
									<div *ngIf="photo?.cameraModel">Camera Model: <a *ngIf="photo?.cameraModel" target="_blank" href="https://www.google.com/search?q={{photo?.cameraModel}}">{{ photo?.cameraModel }}</a></div>
									<div *ngIf="photo?.cameraLensMake">Lens Make: {{ photo?.cameraLensMake }}</div>
									<div *ngIf="photo?.cameraLensModel">Lens Model: {{ photo?.cameraLensModel }}</div>
								</div>
							</div>
							<div class="form-group">
								<label>Camera Settings</label>
								<div class="details-container-data">
									<div *ngIf="!photo?.cameraIso && !photo?.cameraShutterSpeed && !photo?.cameraAperature && !photo?.cameraBrightness && !photo?.cameraFlash && !photo?.cameraZoomRatio">Not Available</div>
									<div *ngIf="photo?.cameraIso">ISO: {{ photo?.cameraIso }}</div>
									<div *ngIf="photo?.cameraShutterSpeed">Shutter Speed: {{ photo?.cameraShutterSpeed }}</div>
									<div *ngIf="photo?.cameraAperature">Aperature: {{ photo?.cameraAperature }}</div>
									<div *ngIf="photo?.cameraBrightness">Brightness: {{ photo?.cameraBrightness }}</div>
									<div *ngIf="photo?.cameraFlash">Flash: {{ photo?.cameraFlash }}</div>
									<div *ngIf="photo?.cameraZoomRatio">Zoom Ratio: {{ photo?.cameraZoomRatio }}</div>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="!photo.connectionsCanSeeExif && photo.userId !== userService.getLocalUserId(0)">
							<div class="first-comment">
								EXIF Data not enabled.
							</div>
						</ng-container>
					</tab>

					<tab heading="History" id="historyTab" *ngIf="photo?.userId == userService.getLocalUserId(0)" (selectTab)="tabSelect($event)">
						<div class="row header">
							<div class="col-md-4">
								<label>Action</label>
							</div>
							<div class="col-md-6">
								<label>Date</label>
							</div>
						</div>
						<div class="row history" *ngFor="let row of photoHistory; let i = index">
							<div class="col-md-4">
								{{ row.action }}
							</div>
							<div class="col-md-6">
								{{ row.date | date:'medium' }}
							</div>
						</div>
					</tab>

					<tab heading="Options" id="optionsTab" *ngIf="photo?.userId == userService.getLocalUserId(0)" (selectTab)="tabSelect($event)">
						<div *ngIf="loadingOptionUpdate" class="loading-box"></div>
						<div class="form-group">
							<div class="option-column">
								<div>
									<label>Allow connections to view photo</label>
									<div class="slider-container">
										<label class="switch">
											<input id="connectionsCanViewSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanView" (change)="connectionsCanViewSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
								<div>
									<label>Allow connections to react to photo</label>
									<div class="slider-container" [ngClass]="{'hidden': classifyForm.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !classifyForm.value.connectionsCanView }">
										<label class="switch">
											<input id="connectionsCanReactSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanReact" (change)="connectionsCanReactSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="option-column">
								<div>
									<label>Allow connections to discuss photo</label>
									<div class="slider-container" [ngClass]="{'hidden': classifyForm.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !classifyForm.value.connectionsCanView }">
										<label class="switch">
											<input id="connectionsCanDiscussSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanDiscuss" (change)="connectionsCanDiscussSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
								<div>
									<label>Lock photo discussion</label>
									<div class="slider-container" [ngClass]="{'hidden': classifyForm.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !classifyForm.value.connectionsCanView }">
										<label class="switch">
											<input id="lockDiscussionSwitch" type="checkbox" class="switch-input" formControlName="lockDiscussion" (change)="lockDiscussionSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="option-column">
								<div>
									<label>Allow connections to suggest metadata</label>
									<div class="slider-container" [ngClass]="{'hidden': classifyForm.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !classifyForm.value.connectionsCanView }">
										<label class="switch">
											<input id="connectionsCanSuggestSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanSuggest" (change)="connectionsCanSuggestSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
								<div>
									<label>Allow connections to see EXIF data</label>
									<div class="slider-container" [ngClass]="{'hidden': classifyForm.value.connectionsCanView }">
										<label class="switch">
											<span class="slider round"></span>
										</label>
									</div>
									<div class="slider-container" [ngClass]="{'hidden': !classifyForm.value.connectionsCanView }">
										<label class="switch">
											<input id="connectionsCanSeeExifSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanSeeExif" (change)="connectionsCanSeeExifSwitchChanged($event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</tab>
				</tabset>
			</div>

		</div>

	</form>
</div>
<div class="modal-footer no-bg" *ngIf="photo?.userId == userService.getLocalUserId(0) || photo?.connectionsCanSuggest">
	<div class="left">
		<a *ngIf="photo && photo?.type != 'upload' && photo?.userId == userService.getLocalUserId(0)" title="Store in Photonomy" (mouseenter)="updateActionsRolloverText('Store in Photonomy')" (mouseleave)="updateActionsRolloverText('')"><i class="far fa-gem"></i></a>
		<a *ngIf="photo && photo?.type == 'facebook' && photo?.externalUrl && photo?.userId == userService.getLocalUserId(0)" title="View photo in Facebook" [href]="photo?.externalUrl" target="_blank" (mouseenter)="updateActionsRolloverText('View photo in Facebook')" (mouseleave)="updateActionsRolloverText('')" class="facebook"><i class="fab fa-facebook-f"></i>
			<!--<img src="/assets/images/logos/facebook.png" />-->
		</a>
		<!-- TODO: Fix the download action to download the main file. Need to generate a new "download" url
		<a *ngIf="photo && photo?.imageUrl" title="Download image 1" [href]="photo?.imageUrl" target="_blank" download (mouseenter)="updateActionsRolloverText('Download image')" (mouseleave)="updateActionsRolloverText('')"><i class="fas fa-download"></i></a>
		<a *ngIf="photo && photo?.type == 'upload' && photo?.imageUrl" title="Download image 2" [href]="photo?.imageUrl" target="_blank" download (mouseenter)="updateActionsRolloverText('Download image')" (mouseleave)="updateActionsRolloverText('')"><i class="fas fa-download"></i></a>
		<a *ngIf="photo && photo?.type == 'upload' && photo?.imageSafeUrl" title="Download image 3" [href]="photo?.imageSafeUrl" target="_blank" download (mouseenter)="updateActionsRolloverText('Download image')" (mouseleave)="updateActionsRolloverText('')"><i class="fas fa-download"></i></a>
		-->
		<a *ngIf="photo && photo?.type == 'upload' && photo?.userId == userService.getLocalUserId(0)" title="Delete photo" (click)="deleteConfirm=!deleteConfirm" (mouseenter)="updateActionsRolloverText('Delete photo')" (mouseleave)="updateActionsRolloverText('')"><i class="fas fa-trash"></i></a>

		<!--
        <i class="far fa-smile" (mouseenter)="updateActionsRolloverText('Unset photo as private')" (mouseleave)="updateActionsRolloverText('')"></i>
        <i class="far fa-smile-wink" (mouseenter)="updateActionsRolloverText('Set photo as private')" (mouseleave)="updateActionsRolloverText('')"></i>
        -->
		<span>{{ actionsRolloverText }}</span>
	</div>
	<div class="right">
		<!-- Options Loading Icon -->
		<span *ngIf="loadingOptionUpdate" class="saving-changes">Saving changes</span> <i *ngIf="loadingOptionUpdate" class="fas fa-circle-notch fa-spin"></i>

		<!-- Discuss Reply -->
		<div class="comment-container" [ngClass]="{'hidden': tab !== 'discussTab' || photo.connectionsCanDiscuss == false || photo.lockDiscussion == true}">
			<form #c="ngForm" [formGroup]="commentForm" class="form">
				<i class="fas fa-comment" (click)="addComment()"></i>
				<input id="addComment" type="text" class="form-control comment-input" formControlName="addComment" maxlength="500" placeholder="Submit a comment" />
			</form>
		</div>

		<!-- Share Buttons -->
		<span>{{ shareRolloverText }}</span>
		<a *ngIf="tab == 'shareTab' && editTagsMode" title="Cancel changes" (mouseenter)="updateShareRolloverText('Cancel changes')" (mouseleave)="updateShareRolloverText('')" (click)="cancelUpdatePhotoTags()" class="cancel"><i class="fas fa-times"></i></a>
		<a *ngIf="tab == 'shareTab' && editTagsMode && photo?.userId == userService.getLocalUserId(0)" title="Save changes" (mouseenter)="updateShareRolloverText('Save changes')" (mouseleave)="updateShareRolloverText('')" (click)="updatePhotoTags()" class="save"><i class="fas fa-save"></i></a>
		<a *ngIf="tab == 'shareTab' && !editTagsMode && photo?.userId == userService.getLocalUserId(0)" title="Edit public tags" (mouseenter)="updateShareRolloverText('Edit public tags')" (mouseleave)="updateShareRolloverText('')" (click)="enterEditTagsMode()" class="edit"><i class="fas fa-edit"></i></a>

		<!-- Classify Buttons -->
		<span>{{ saveRolloverText }}</span>
		<a *ngIf="tab == 'classifyTab' && editMode" title="Cancel changes" (mouseenter)="updateSaveRolloverText('Cancel changes')" (mouseleave)="updateSaveRolloverText('')" (click)="cancelUpdatePhoto()" class="cancel"><i class="fas fa-times"></i></a>
		<a *ngIf="tab == 'classifyTab' && editMode && photo?.userId == userService.getLocalUserId(0)" title="Save changes" (mouseenter)="updateSaveRolloverText('Save changes')" (mouseleave)="updateSaveRolloverText('')" (click)="updatePhoto()" class="save"><i class="fas fa-save"></i></a>
		<a *ngIf="tab == 'classifyTab' && editMode && photo?.userId !== userService.getLocalUserId(0)" title="Save changes" (mouseenter)="updateSaveRolloverText('Save changes')" (mouseleave)="updateSaveRolloverText('')" (click)="updatePhotoForReview()" class="save"><i class="fas fa-save"></i></a>
		<a *ngIf="tab == 'classifyTab' && !editMode && photo?.userId == userService.getLocalUserId(0)" title="Edit photo details" (mouseenter)="updateSaveRolloverText('Edit photo details')" (mouseleave)="updateSaveRolloverText('')" (click)="enterEditMode()" class="edit"><i class="fas fa-edit"></i></a>
		<a *ngIf="tab == 'classifyTab' && !editMode && photo?.userId !== userService.getLocalUserId(0)" title="Edit photo details" (mouseenter)="updateSaveRolloverText('Edit photo details for review')" (mouseleave)="updateSaveRolloverText('')" (click)="enterEditMode()" class="edit"><i class="fas fa-edit"></i></a>
	</div>
</div>
<!-- Photo Details Modal -->
<ng-template #photoDetailsDialog class="modal-dialog-centered">
	<app-photo-view [modalRef]="modalRef" [modalTab]="modalTab"></app-photo-view>
</ng-template>


<!-- Photo Lightbox Modal -->
<div id="myModal" class="modal" *ngIf="showImageLightbox">
	<span class="helper"></span>
	<div class="previous-container">
		<i class="fas fa-caret-left"></i>
	</div>
	<div class="photo-expanded-container" [style.background-image]="'url(' + activePhoto.imageSafeUrl + ')'">
		<div id="caption">{{ activePhoto?.description }}</div>
	</div>
	<div class="close-container" (click)="closeImageLightbox()">
		<i class="fas fa-times"></i>
	</div>
	<div class="switch-container" *ngIf="showImageLightbox" (click)="openPhotoDetailsModalClassify(activePhoto)">
		<i class="fas fa-file-alt"></i>
	</div>
	<div class="next-container">
		<i class="fas fa-caret-right"></i>
	</div>
</div>

<!-- Acknowledge Only Message -->
<div class="message-container" *ngIf="message.action == 'confirm'" [ngClass]="{'fade-out': fadeOut, 'hidden': fadeOutHidden }">
	<div class="message">{{ message.message }}</div>
	<div class="date">{{ message.date | date:'medium'}}</div>
	<div class="action-icon" (click)="acknowledge(message)">
		<i class="fas fa-check"></i> Acknowledge
	</div>
</div>

<!-- Photo Suggestion Message -->
<div class="message-container" *ngIf="message.action == 'p-suggest'" [ngClass]="{'fade-out': fadeOut, 'hidden': fadeOutHidden }">
	<div class="message">{{ message.message }}</div>
	<div class="date">{{ message.date | date:'mediumDate'}}</div>
	<div class="action-icon" (click)="acknowledge(message)">
		<i class="fas fa-check"></i> Acknowledge
	</div>
	<div class="action-icon" (click)="viewPhotoClassify()">
		<i class="fas fa-image"></i> View photo
	</div>
</div>

<!-- Event Photos Message -->
<div class="message-container" *ngIf="message.action == 'event'">
	<ng-container *ngIf="user?.imageSafeUrl">
		<img [src]="user.imageSafeUrl" class="image" />
	</ng-container>
	<div class="message">{{ user?.firstName }} {{ user?.lastName }} has created an event and you have photos that occur on the same day. Would you like to offer your photos for this event?</div>
	<div class="date">{{ message.date | date:'medium'}}</div>
	<div class="action-icon">
		<i class="fas fa-check"></i> Review photos
	</div>
	<div class="action-icon">
		<i class="fas fa-times"></i> No thanks
	</div>
</div>

<!-- User Wave Message -->
<div class="message-container" *ngIf="message.action == 'u-wave'" [ngClass]="{'fade-out': fadeOut, 'hidden': fadeOutHidden }">
	<div class="message">{{ message.message }}</div>
	<div class="date">{{ message.date | date:'medium'}}</div>
	<div class="action-icon" (click)="acknowledge(message)">
		<i class="fas fa-check"></i> Acknowledge
	</div>
	<div class="action-icon" (click)="waveToUser()" *ngIf="!loadingWave && !waveToUserSuccess">
		<i class="fas fa-hand-sparkles"></i> Wave back
	</div>
	<div class="wave-success" *ngIf="loadingWave || waveToUserSuccess">
		<ng-container *ngIf="loadingWave">
			<i class="fas fa-circle-notch fa-spin"></i>
		</ng-container>
		<ng-container *ngIf="!loadingWave">
			<i class="fas fa-hand-sparkles"></i> You waved back
		</ng-container>
	</div>
</div>
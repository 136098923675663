<span #widgetModeContainer *ngIf="pageAction === 'edit'" class="widget-mode-container">
	<span class="widget-icontext">{{ iconsRolloverText }}</span>
	<span class="icon-container">
		<a (mouseenter)="updateIconText('Delete widget')" (mouseleave)="updateIconText('')" (click)="deleteWidget()" href="javascript:"><em class="fas fa-minus-square"></em></a>
	</span>
</span>

<div *ngIf="!loading && widget.type === 'connections-quick-links'" class="widget-outer">
	<app-widget-connections-quick-links></app-widget-connections-quick-links>
</div>
<div *ngIf="!loading && widget.type === 'photos-quick-links'" class="widget-outer">
	<app-widget-photos-quick-links></app-widget-photos-quick-links>
</div>
<div *ngIf="!loading && widget.type === 'photos-uncategorized-gallery'" class="widget-outer">
	<app-widget-gallery-uncategorized-photos></app-widget-gallery-uncategorized-photos>
</div>
<div *ngIf="!loading && widget.type === 'photos-connections-graph'" class="widget-outer">
	<app-widget-photos-connections-graph></app-widget-photos-connections-graph>
</div>
<div *ngIf="!loading && widget.type === 'photos-uncategorized-graph'" class="widget-outer">
	<app-widget-photos-my-uncategorized-graph></app-widget-photos-my-uncategorized-graph>
</div>
<div *ngIf="!loading && widget.type === 'welcome-message'" class="widget-outer">
	<app-widget-welcome-message></app-widget-welcome-message>
</div>
<div *ngIf="!loading && widget.type === 'user-messages'" class="widget-outer">
	<app-user-messages></app-user-messages>
</div>
<div *ngIf="!loading && widget.type === 'photos-my-uploaded-graph'" class="widget-outer">
	<app-widget-photos-my-uploaded-graph></app-widget-photos-my-uploaded-graph>
</div>
<div *ngIf="!loading && widget.type === 'photo-storage-graph'" class="widget-outer">
	<app-widget-photo-storage-graph></app-widget-photo-storage-graph>
</div>
<div *ngIf="!loading && widget.type === 'tag-cloud'" class="widget-outer">
	<app-widget-tag-cloud></app-widget-tag-cloud>
</div>
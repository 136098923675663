<div class="background" [ngClass]="{'loading in': loadingEvents || loadingPhotos }">
    <div class="no-events-message-container" *ngIf="error">
        Timeline is not enabled for this user.
    </div>
    <div class="no-events-message-container" *ngIf="!error && !loadingEvents && eventsDisplayedOnTimeline.length == 0">
        <div>Welcome to your timeline. </div>
        <div>Add at least one <a (click)="navigate('/events')">event</a> to get started.</div>
    </div>
    <div class="event-details-container" [ngClass]="{'show': eventActive, 'hide': unselectEvent == -1}" *ngIf="!loadingEvents && eventsDisplayedOnTimeline.length > 0">
        <div class="title">{{ eventActive?.name }}</div>
        <div class="sub-title">
            <ng-container *ngIf="!eventActive?.startDateIncludeTime">
                {{ eventActive?.startDateTime | date:'longDate' }}
                <ng-container *ngIf="eventActive?.endDateTime && !event.endDateIncludeTime">- {{ eventActive?.endDateTime | date:'longDate' }}</ng-container>
                <ng-container *ngIf="eventActive?.endDateTime && event.endDateIncludeTime && datesAreOnSameDay(eventActive?.startDateTime, eventActive?.endDateTime)">- {{ eventActive?.endDateTime | date:'h:mm a' }}</ng-container>
                <ng-container *ngIf="eventActive?.endDateTime && event.endDateIncludeTime && !datesAreOnSameDay(eventActive?.startDateTime, eventActive?.endDateTime)">- {{ eventActive?.endDateTime | date:'MMMM d, y, h:mm a' }}</ng-container>
            </ng-container>
            <ng-container *ngIf="eventActive?.startDateIncludeTime">
                {{ event?.startDateTime | date:'MMMM d, y, h:mm a' }}
                <ng-container *ngIf="eventActive?.endDateTime && !eventActive.endDateIncludeTime">- {{ eventActive?.endDateTime | date:'longDate' }}</ng-container>
                <ng-container *ngIf="eventActive?.endDateTime && eventActive.endDateIncludeTime && datesAreOnSameDay(eventActive?.startDateTime, eventActive?.endDateTime)">- {{ eventActive?.endDateTime | date:'h:mm a' }}</ng-container>
                <ng-container *ngIf="eventActive?.endDateTime && eventActive.endDateIncludeTime && !datesAreOnSameDay(eventActive?.startDateTime, eventActive?.endDateTime)">- {{ eventActive?.endDateTime | date:'MMMM d, y, h:mm a' }}</ng-container>
            </ng-container>
        </div>
        <div class="details" *ngIf="eventActive?.details">{{ eventActive.details }}</div>
        <div class="event-map-container" *ngIf="eventActive?.location && eventActive.locationShowMap">
            <div *ngIf="locationEmbedUrl">
                <iframe [src]="locationEmbedUrl | safe: 'resourceUrl'" width="100%" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        <div class="close-container"><a href="javascript:void(0)" (click)="closeEvent()">Close event</a></div>
    </div>
    <div class="event-photos-container" [ngClass]="{'show': eventActive, 'hide': unselectEvent == -1}">
        <div class="image-container" [ngClass]="{'wait': loadingPhotos}">
            <ngx-masonry>
                <div ngxMasonryItem class="masonry-item" *ngFor="let photo of eventPhotos; let i = index">
                    <div class="photo-container">
                        <img *ngIf="photo.image" [src]="photo.image" />
                        <div class="star-container" *ngIf="photo.eventCoverPhoto"><i class="fas fa-star"></i></div>
                    </div>
                </div>
            </ngx-masonry>
        </div>
    </div>
    <div class="title-container" *ngIf="!error && !loadingEvents && eventsDisplayedOnTimeline.length > 0 && !eventSelectedOnce">
        <div class="one" [ngClass]="{'fade-in-1': fadeText1 }">Welcome to the story of</div>
        <div class="two" [ngClass]="{'fade-in-2': fadeText2 }">{{ timelineUser?.firstName }} {{ timelineUser?.lastName }}</div>
    </div>
</div>
<div class="timeline wait" [ngClass]="{'hidden': error, 'wait': loadingEvents, 'not-empty': eventsDisplayedOnTimeline.length > 0 }">
    <div class="date date-0">
        <div class="focus">
            <div>{{ year0 }}</div>
        </div>
    </div>
    <div class="date date-1">
        <div class="focus">
            <div>{{ math.trunc(year1) }}</div>
        </div>
    </div>
    <div class="date date-2">
        <div class="focus">
            <div>{{ math.trunc(year2) }}</div>
        </div>
    </div>
    <div class="date date-3">
        <div class="focus">
            <div>{{ math.trunc(year3) }}</div>
        </div>
    </div>
    <div class="date date-4">
        <div class="focus">
            <div>{{ math.trunc(year4) }}</div>
        </div>
    </div>
    <div class="date date-5">
        <div class="focus">
            <div>{{ math.trunc(year5) }}</div>
        </div>
    </div>
    <div class="date date-6">
        <div class="focus">
            <div>{{ math.trunc(year6) }}</div>
        </div>
    </div>
    <div class="date date-7">
        <div class="focus">
            <div>{{ math.trunc(year7) }}</div>
        </div>
    </div>
    <div class="date date-8">
        <div class="focus">
            <div>{{ math.trunc(year8) }}</div>
        </div>
    </div>
    <div class="date date-9">
        <div class="focus">
            <div>{{ year9 }}</div>
        </div>
    </div>
    <ng-container *ngFor="let event of eventsDisplayedOnTimeline">
        <div class="event wait" [ngClass]="{'wait': loadingEventsWait}" [ngStyle]="{'left': calculateLeftPosition(event.startDateTime) + '%'}" (click)="selectEvent(event)" (mouseover)="hoverEvent(event)" (mouseout)="unhoverEvent(event)">
            <div class="event-hover-container" *ngIf="eventHover == event">
                <div class="timeline-name" [ngClass]="{'left': calculateLeftPosition(event.startDateTime) > 50}">{{ event.name }}</div>
            </div>
        </div>
    </ng-container>
</div>
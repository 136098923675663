import { Photo } from "./photo.model";

export class PhotoUpload {
	public file: any;
	public photo: Photo;

	public selectedFolder: any;
	public selectedPeople: any;
	public selectedLocations: any;
	public selectedKeywords: any;
	public selectedTags: any;

	public connectionsCanView: any;
	public connectionsCanReact: any;
	public connectionsCanDiscuss: any;
	public connectionsCanSuggest: any;
	public connectionsCanSeeExif: any;

	public event: any;
	public uploadToAccount: any; // This is used when uploading to another users event to specify whose account the upload should go to

	public status;
	public errorMessage;
	public queueNumber;
	public signedUploadUrlNumber;
}
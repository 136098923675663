import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Photo } from 'src/app/models/photo.model';
import { SignedDownloadToken } from 'src/app/models/signed-download-token.model';
import { UserMessage } from 'src/app/models/user-message';
import { User } from 'src/app/models/user.model';
import { PhotoService } from 'src/app/services/photo.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-user-message',
	templateUrl: './user-message.component.html',
	styleUrls: ['./user-message.component.css']
})
export class UserMessageComponent implements OnInit, OnDestroy {
	@Input() message: UserMessage;
	@Output() emitMessageAcknowledged = new EventEmitter<UserMessage>();

	@ViewChild('photoDetailsDialog', { static: true }) photoDetailsDialog: TemplateRef<any>;

	modalRef: BsModalRef;

	userFoundSubscription: Subscription;
	user: User;

	loadingUser = true;
	loadingWave = false;

	waveToUserSuccess = false;

	showPhotoDetailsModal = false;
	showImageLightbox = false;

	activePhoto: Photo;
	modalTab = 'discussTab';

	fadeOut = false;
	fadeOutHidden = false;

	constructor(
		private router: Router,
		private modalService: BsModalService,
		private photoService: PhotoService,
		private userService: UserService
	) { }

	// Allow the escape key to close the photo modal
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		this.showPhotoDetailsModal = false;
		this.showImageLightbox = false;
	}

	ngOnInit() {
		this.subscribeToUserChanged();

		if (this.message.messageUserId) {
			this.userService.getUserByUserId(this.message.messageUserId, null);
		} else {
			this.loadingUser = false;
		}
	}

	ngOnDestroy() { }

	subscribeToUserChanged() {
		this.userFoundSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.message.messageUserId) {
					this.user = user;
					this.loadingUser = false;
				}
			}
		);
	}

	acknowledge(message: UserMessage) {
		this.fadeOut = true;

		let _this = this;
		setTimeout(function () {
			_this.fadeOutHidden = true;
			_this.emitMessageAcknowledged.emit(message);
		}, 1000);

		this.userService.acknowledgeUserMessage(message.messageId).subscribe(
			response => {
				// Do nothing.
			}
		);
	}

	viewPhotoClassify() {
		if (this.activePhoto) {
			this.photoService.setActivePhoto(this.activePhoto);
			this.openPhotoDetailsModalClassify(this.activePhoto);
		} else {
			this.photoService.getPhoto(this.message.messageEventId).subscribe(
				response => {
					let photo: Photo = response.body;
					if (!photo.imageSafeUrl) {
						this.setImageSafeUrl(photo);
					} else {
						this.photoService.setActivePhoto(photo);
						this.openPhotoDetailsModalClassify(photo);
					}
				}
			);
		}
	}

	setImageSafeUrl(photo) {
		let token: SignedDownloadToken = null;

		// Loop the cached tokens. Only need to find the current users public token
		for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
			// Investigate the ones who match the photo's userId
			if (signedDownloadToken.userId == photo.userId && signedDownloadToken.type == 'userPublic') {
				token = signedDownloadToken;
				break;
			}
		}

		if (token) {
			// Found a valid token, use it
			let url = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + token.token;
			photo.imageSafeUrl = url;

			this.photoService.setActivePhoto(photo);
			this.openPhotoDetailsModalClassify(photo);
		} else {
			// No valid token for scope, pull new token
			let scope = photo.userId;
			let type = 'userPublic';

			this.photoService.getSignedDownloadToken(scope, type).subscribe(
				response => {
					let responseToken = response.body;
					responseToken.userId = photo.userId;
					photo.imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + responseToken.token;

					this.photoService.signedDownloadTokens.push(responseToken);

					this.photoService.setActivePhoto(photo);
					this.openPhotoDetailsModalClassify(photo);
				}
			);
		}
	}

	openPhotoDetailsModalClassify(photo: Photo) {
		this.closeImageLightbox();

		this.showPhotoDetailsModal = true;

		this.modalTab = 'classifyTab';

		this.modalRef = this.modalService.show(
			this.photoDetailsDialog,
			Object.assign({}, { class: 'modal-lg' }, { backdrop: true, ignoreBackdropClick: false, animated: true })
		);
	}

	openPhotoDetailsModalDiscuss(photo: Photo) {
		this.closeImageLightbox();

		this.showPhotoDetailsModal = true;

		this.modalTab = 'discussTab';

		this.modalRef = this.modalService.show(
			this.photoDetailsDialog,
			Object.assign({}, { class: 'modal-lg' }, { backdrop: true, ignoreBackdropClick: false, animated: true })
		);
	}

	closePhotoDetailsModal() {
		this.showPhotoDetailsModal = false;
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	openImageLightbox(photo: Photo, selectedPhotoPointer: number) {
		this.closePhotoDetailsModal();

		// this.selectedPhotoPointer = selectedPhotoPointer;

		// this.selectedPhoto = photo;
		// this.photoService.setActivePhoto(photo);

		this.showImageLightbox = true;
	}

	closeImageLightbox() {
		// this.selectedPhoto = null;
		this.showImageLightbox = false;
	}

	navigate(route: string) {
		this.router.navigate([route]);
	}

	waveToUser() {
		this.loadingWave = true;

		this.userService.waveToUser(this.user.id).subscribe(
			response => {
				this.loadingWave = false;
				this.waveToUserSuccess = true;
			}
		);
	}
}

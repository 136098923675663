import { Widget } from "./widget.model";

export class User {
	public id: number;
	public uuid: string;
	public uuidPrivate: string;
	public email: string;
	public emailVerified: boolean;
	public emailVerifyCode: string;
	public password: string;
	// public salt: string;
	// public passwordResetCode: string;
	// public passwordResetCodeExpiration: string;
	public firstName: string;
	public lastName: string;
	public imageExternalId: string;
	public imageExternalUrl: string;
	public date: Date;
	public loginCount: number;
	public loginRemember: boolean;
	public birthDate: Date;
	public widgets: Widget[];
	public connectionType: string;
	public isTimelinePrivate: boolean;
	public hasAcknowledgedExploreHints: boolean;
	public connectionsCanViewByDefault: boolean;
	public connectionsCanReactByDefault: boolean;
	public connectionsCanSuggestByDefault: boolean;
	public connectionsCanDiscussByDefault: boolean;
	public connectionsCanSeeExifByDefault: boolean;
	public defaultPhotoOwnerUserIds: string;
	public defaultExploreType: string; // choose/network/public
	public stripeCustomerId: string;
	public paymentMethodId: string;
	public paymentMethodType: string;
	public paymentMethodCcLastFour: string;
	public paymentMethodCcExpirationMonth: number;
	public paymentMethodCcExpirationYear: number;
	public paymentMethodCcBrand: string;

	public cardLabel: string; // **** **** **** 1234   08/22

	public name: string; // This is not stored in the database, but is a combination of firstName + lastName
	public imageSafeUrl: string; // This is not stored in the database, but is generated when needed
	public initials: string; // Displayed when a user does not have a profile image
	public photoIndex: number; // Used on the explore page when loading initials
	public isSearchDefaultOwner: boolean; // Used on the profile and explore page to know if the user is set as a default search option for ownership
}

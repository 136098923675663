<div class="card" [ngClass]="user.connectionType ? user.connectionType : 'pending'" #card>
	<div class="face front" [ngClass]="{'loading in': loading }">
		<img *ngIf="user.imageSafeUrl" [src]="user.imageSafeUrl" class="user-image-icon" [title]="user.name" />
		<i *ngIf="!user.imageSafeUrl" class="fas fa-user"></i>
		<div class="text">
			<ng-container *ngIf="(user.firstName?.length > 0) || (user.lastName?.length > 0)">
				{{ user.firstName }} {{ user.lastName }}
			</ng-container>
			<ng-container *ngIf="!(user.firstName?.length > 0) && !(user.lastName?.length > 0)">
				{{ user.email }}
			</ng-container>
			<br />
			<!-- Active -->
			<span *ngIf="user.connectionType == 'active'">Connection since {{ user.date | date:'longDate' }}</span>

			<!-- Pending -->
			<span *ngIf="user.connectionType == 'pending'">Requested on {{ user.date | date:'longDate' }}</span>

			<!-- Waiting -->
			<span *ngIf="user.connectionType == 'waiting'">Requested on {{ user.date | date:'longDate' }}</span>

			<!-- Blocked -->
			<span *ngIf="user.connectionType == 'blocked'">Blocked on {{ user.date | date:'longDate' }}</span>
		</div>

		<div class="button-container">
			<!-- Active -->
			<div *ngIf="user.connectionType == 'active'" class="button first" (click)="navigateToPhotos()"><i class="fas fa-image"></i>Photos</div>
			<!-- <div *ngIf="user.connectionType == 'active'" class="button middle"><i class="fas fa-calendar"></i>Events</div> -->
			<div *ngIf="user.connectionType == 'active'" class="button middle" (click)="navigateToTimeline()"><i class="fas fa-user-clock"></i>Timeline</div>
			<div *ngIf="user.connectionType == 'active'" class="button last ellipsis" (click)="showActiveSubmenu = !showActiveSubmenu">...</div>
			<div *ngIf="showActiveSubmenu" class="submenu">
				<div class="button " (click)="waveToUser()">Wave to user<i class="fas fa-hand-sparkles"></i></div>
				<!-- <div class="button" title="Remove connection">Send a message<i class="fas fa-comment-alt"></i></div> -->
				<div class="button" (click)="removeConnectionActiveConfirm(user)" title="Remove connection">Remove connection<i class="fas fa-trash"></i></div>
				<div class="button" (click)="blockConnectionActiveConfirm(user)" title="Block connection">Block connection<i class="fas fa-user-slash"></i></div>
			</div>

			<!-- Pending -->
			<div *ngIf="user.connectionType == 'pending'" class="button first btn-success" (click)="approveConnectionPending(user)" title="Approve connection">Approve</div>
			<div *ngIf="user.connectionType == 'pending'" class="button middle btn-danger" (click)="rejectConnectionPending(user)" title="Reject connection">Reject</div>
			<div *ngIf="user.connectionType == 'pending'" class="button last btn-danger" (click)="blockConnectionPending(user)" title="Block connection">Block</div>

			<!-- Waiting -->
			<div *ngIf="user.connectionType == 'waiting'" class="button" (click)="cancelConnectionWaiting(user)" title="Cancel connection">Cancel</div>

			<!-- Blocked -->
			<div *ngIf="user.connectionType == 'blocked'" class="button" (click)="unblockConnection(user)" title="Unblock connection">Unblock</div>
		</div>
		<div *ngIf="waveToUserSuccess && loadingWave"><i class="fas fa-circle-notch fa-spin loading-lower"></i></div>
		<div *ngIf="waveToUserSuccess && !loadingWave" class="success">You waved at {{ user.firstName }} {{ user.lastName }}</div>
	</div>
	<div class="face back" [ngClass]="{'loading in': loading }">
		<ng-container *ngIf="removeConfirm">
			<img *ngIf="user.imageSafeUrl" [src]="user.imageSafeUrl" class="user-image-icon" [title]="user.name" />
			<i *ngIf="!user.imageSafeUrl" class="fas fa-user"></i>
			<div class="help-subtitle">{{ user.firstName }} {{ user.lastName }}</div>
			<div class="help-text">You are about to remove the following connection. Continue?</div>
			<div class="button-container">
				<div class="button first" (click)="removeConnectionActive()">Remove</div>
				<div class="button last" data-dismiss="modal" (click)="cancelRemoveConnection()">Cancel</div>
			</div>
		</ng-container>
		<ng-container *ngIf="blockConfirm">
			<img *ngIf="user.imageSafeUrl" [src]="user.imageSafeUrl" class="user-image-icon" [title]="user.name" />
			<i *ngIf="!user.imageSafeUrl" class="fas fa-user"></i>
			<div class="help-title">{{ user.firstName }} {{ user.lastName }}</div>
			<div class="help-text">You are about to block the following user. Continue?</div>
			<div class="button-container">
				<div class="button first" (click)="blockConnectionActive()">Block</div>
				<div class="button last" data-dismiss="modal" (click)="cancelBlockConnection()">Cancel</div>
			</div>
		</ng-container>
	</div>
</div>
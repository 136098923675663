<div class="background">
	<div class="header">
		Explore Tags
	</div>
	<div class="tag-container">
		<div *ngIf="!loadingUserPublicTags && userTags.length == 0" class="no-tags-message">
			You have not added tags to any photos
		</div>
		<div *ngFor="let tag of userTags" class="tag" (click)="searchTag(tag)">
			#{{ tag }}
		</div>
	</div>
</div>
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Folder } from 'src/app/models/folder.model';
import { PhotoComment } from 'src/app/models/photo-comment.model';
import { PhotoHistory } from 'src/app/models/photo-history.model';
import { PhotoSuggestion } from 'src/app/models/photo-suggestion.model';
import { Photo } from 'src/app/models/photo.model';
import { FolderService } from 'src/app/services/folder.service';
import { PhotoCommentService } from 'src/app/services/photo-comment.service';
import { PhotoService } from 'src/app/services/photo.service';
import { UserConnectionService } from 'src/app/services/user-connection.service';
import { AuthService } from '../../../../services/auth.service';
import { UserService } from '../../../../services/user.service';

@Component({
	selector: 'app-photo-view',
	templateUrl: './view.component.html',
	styleUrls: ['./view.component.css']
})
export class PhotoViewComponent implements OnInit, OnDestroy {
	@Input() modalRef: BsModalRef;
	@Input() modalTab: string;

	@Output() emitPhotoUpdated = new EventEmitter<Photo>();

	@ViewChild('keywordsSelect') keywordsSelect;
	@ViewChild('locationsSelect') locationsSelect;
	@ViewChild('peopleSelect') peopleSelect;

	@ViewChild('inputPerson') inputPerson: ElementRef;
	@ViewChild('inputDate') inputDate: ElementRef;
	@ViewChild('inputKeyword') inputKeyword: ElementRef;
	@ViewChild('inputLocation') inputLocation: ElementRef;
	@ViewChild('inputTag') inputTag: ElementRef;

	// Subscriptions
	activePhotoChangedSubscription: Subscription;

	// Global Page
	editMode: boolean = false;
	editTagsMode: boolean = false;
	tab: string = '';
	maxDate: Date;

	// Loading indicators
	loading = false;
	loadingKeywords = true;
	loadingPeople = true;
	loadingLocations = true;
	loadingOptionUpdate = false;
	loadingFolders = true;
	loadingUserPublicTags = false;
	loadingCreateFolder = -1;

	// UI
	actionsRolloverText = '';
	saveRolloverText = '';
	shareRolloverText = '';
	private folderSearchSubject = new Subject<string>();
	private peopleSearchSubject = new Subject<string>();
	private locationsSearchSubject = new Subject<string>();
	private keywordsSearchSubject = new Subject<string>();
	private tagsSearchSubject = new Subject<string>();

	private readonly debounceTime350Ms = 350;
	private readonly debounceTime100Ms = 100;

	// Photo
	photo: Photo;
	photoHistory: PhotoHistory[] = [];

	// Discussion
	commentForm: UntypedFormGroup;
	photoComments: PhotoComment[] = [];

	// Edit Metadata
	classifyForm: UntypedFormGroup;

	// Folder Dropdown Vars
	foldersShowSelector = false;				// If true, the folders dropdown list is displayed
	selectedRootFolder: Folder = null;			// The root folder selected by the user
	selectedSubFolder: Folder = null;			// The sub folder selected by the user
	userRootFolders: any[] = [];				// List of user folders from the database
	filteredUserRootFolders: any[] = [];		//
	expandedRootId = -1;						// 
	newSubFolderParentId = -1;					// 
	tempFolder = '';							// 

	// People Dropdown Vars
	peopleHideAddIcon = false;					// If true, a new tag is displayed
	peopleShowSelector = false;					// If true, the people dropdown list is displayed
	selectedPeople: any[] = [];					// People that were selected by the user
	userPeople: any[] = [];						// List of connections + user people from the database. This list is displayed when the user presses the down icon
	filteredUserPeople: any[] = [];				// A subset of userPeople, used to populate the people selector dropdown when a user types into the input
	tempPerson = '';							//

	// Date Vars 
	dateHideAddIcon = false;

	// Locations Dropdown Vars
	locationsHideAddIcon = false;				// If true, a new tag is displayed
	locationsShowSelector: boolean = false;		// If true, the locations dropdown list is displayed
	locationsShowSuggestions: boolean = false;	// If true, public address suggestions are displayed
	selectedLocations: any[] = [];				// Location that was selected by the user
	userLocations: any[] = [];					//
	filteredUserLocations: any[] = [];			//
	filteredPublicLocations: any[] = [];		//
	tempLocation = '';							// 

	// Keywords Dropdown Vars
	keywordsHideAddIcon = false;				// If true, a new tag is displayed
	keywordsShowSelector: boolean = false;		// If true, the keywords dropdown list is displayed
	selectedKeywords: any[] = [];				// Keywords that were selected by the user
	userKeywords: any[] = [];					// List of user keywords from the database. This list is displayed when the user presses the down icon
	filteredUserKeywords: any[] = [];			// A subset of userKeywords, used to populate the keyword selector dropdown when a user types into the input
	tempKeyword = '';							//

	// Tags Dropdown Vars
	tagsForm: UntypedFormGroup;
	tagsHideAddIcon = false;					// If true, a new tag is displayed
	tagsShowSelector: boolean = false;			// If true, the tags dropdown list is displayed
	selectedTags: any[] = [];					// Tags that were selected by the user
	userTags: any[] = [];						// List of user tags from the database. This list is displayed when the user presses the down icon
	filteredUserTags: any[] = [];				// A subset of userTags, used to populate the tag selector dropdown when a user types into the input
	filteredPublicTags: any[] = [];				// Looked-up public tags, used to populate the tag selector dropdown when a user types into the input
	tempTag = '';								// When a user types a new value in to the tags input, it is saved in tempTag.  This value is then added to the array after the user presses enter or clicks the check icon

	// Delete
	deleteConfirm = false;
	photoDeleted = false;

	constructor(
		public authService: AuthService,
		private bsDatepickerConfig: BsDatepickerConfig,
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private toastr: ToastrService,
		private connectionService: UserConnectionService,
		private folderService: FolderService,
		private photoService: PhotoService,
		private photoCommentService: PhotoCommentService,
		public userService: UserService
	) {
		this.maxDate = new Date();
	}

	/**
	 * INIT
	 */

	// 1. Subscribe to activePhotoChanged to know if prev or next button was pressed
	// 2. Initialize the update metadata form since we are hiding it instead of using an ngIf
	// 3. Load the photo from this.photoService.getActivePhoto()
	// 4. Retrieve suggestions and add them to photo metadata
	// 5. Retrieve the photo history if the current user is the photo owner
	// 6. Retrieve custom dropdown data if the user has the ability to edit metadata
	// 7. Set the correct tab if one passed in querystring

	ngOnInit() {
		this.subscribeToActivePhotoChanged();
		this.subscribeToFolderInputChanged();
		this.subscribeToPeopleInputChanged();
		this.subscribeToLocationInputChanged();
		this.subscribeToKeywordsInputChanged();
		this.subscribeToTagsInputChanged();

		this.photo = this.photoService.getActivePhoto();

		this.initClassifyForm();
		this.initCommentForm();
		this.initTagsForm();

		if (this.photo.connectionsCanSuggest || this.photo.userId == this.userService.getLocalUserId(0)) {
			this.retrieveDropdownData();
		} else {
			this.loadingPeople = false;
			this.loadingLocations = false;
			this.loadingKeywords = false;
			this.loadingUserPublicTags = false;

			this.populatePhotoMetadata();
		}

		if (this.photo.userId == this.userService.getLocalUserId(0)) {
			this.retrievePhotoHistory();
		}

		if (this.modalTab == 'classifyTab') {
			this.tab = 'classifyTab';
		} else {
			this.tab = 'discussTab';
		}

		// TODO: Only load comments if discussions are enabled for the photo.
		this.loadDiscussion();

		this.route.queryParams.subscribe(params => {
			if (params['tab']) {
				this.tab = params['tab'];
			}
		});

		this.bsDatepickerConfig.dateInputFormat = 'MMMM DD, YYYY';
	}

	ngOnDestroy() {
		if (this.activePhotoChangedSubscription) {
			this.activePhotoChangedSubscription.unsubscribe();
		}
	}

	initClassifyForm() {
		if (typeof this.photo.capturedDate == 'string') {
			let date = new Date(Date.parse(this.photo.capturedDate));
			this.photo.capturedDate = date;
		}

		this.classifyForm = this.formBuilder.group({
			'description': this.photo.description,
			'enableComments': new UntypedFormControl(),
			'storeInPhotonomy': new UntypedFormControl(),
			'folder': new UntypedFormControl(),
			'newSubFolderName': new UntypedFormControl(),
			'people': new UntypedFormControl(),
			'capturedDate': this.photo.capturedDate,
			'location': new UntypedFormControl(),
			'keywords': new UntypedFormControl(),
			'localEditMode': new UntypedFormControl(),
			'connectionsCanView': this.photo.connectionsCanView,
			'connectionsCanReact': this.photo.connectionsCanReact,
			'connectionsCanDiscuss': this.photo.connectionsCanDiscuss,
			'lockDiscussion': this.photo.lockDiscussion,
			'connectionsCanSuggest': this.photo.connectionsCanSuggest,
			'connectionsCanSeeExif': this.photo.connectionsCanSeeExif,
		});
	}

	initCommentForm() {
		this.commentForm = this.formBuilder.group({
			'addComment': new UntypedFormControl(),
		});
	}

	initTagsForm() {
		this.tagsForm = this.formBuilder.group({
			'tags': new UntypedFormControl(),
		});
	}

	/**
	 * SUBSCRIPTIONS
	 */

	subscribeToFolderInputChanged() {
		this.folderSearchSubject.pipe(debounceTime(this.debounceTime100Ms)).subscribe(() => {
			this.folderKeyupSearch();
		});
	}

	subscribeToPeopleInputChanged() {
		this.peopleSearchSubject.pipe(debounceTime(this.debounceTime100Ms)).subscribe(() => {
			this.peopleKeyupSearch();
		});
	}

	subscribeToLocationInputChanged() {
		this.locationsSearchSubject.pipe(debounceTime(this.debounceTime350Ms)).subscribe(() => {
			this.locationsKeyupSearch();
		});
	}

	subscribeToKeywordsInputChanged() {
		this.keywordsSearchSubject.pipe(debounceTime(this.debounceTime100Ms)).subscribe(() => {
			this.keywordsKeyupSearch();
		});
	}

	subscribeToTagsInputChanged() {
		this.tagsSearchSubject.pipe(debounceTime(this.debounceTime100Ms)).subscribe(() => {
			this.tagsKeyupSearch();
		});
	}

	subscribeToActivePhotoChanged() {
		this.activePhotoChangedSubscription = this.photoService.activePhotoChanged
			.subscribe((photo: Photo) => {
				// Clear page data
				this.editMode = false;
				this.editTagsMode = false;
				this.updateSaveRolloverText('');
				this.updateShareRolloverText('');

				this.loadingFolders = true;
				this.loadingKeywords = true;
				this.loadingPeople = true;
				this.loadingLocations = true;
				this.loadingUserPublicTags = true;

				this.resetFolderSelector();
				this.resetPeopleSelector();
				this.resetLocationsSelector();
				this.resetKeywordsSelector();
				this.resetTagsSelector();

				this.resetSelectedMetadata();

				this.selectedRootFolder = null;
				this.selectedSubFolder = null;

				this.peopleHideAddIcon = false;
				this.locationsHideAddIcon = false;
				this.keywordsHideAddIcon = false;
				this.tagsHideAddIcon = false;

				// Update local photo object
				this.photo = photo;

				this.initClassifyForm();
				this.initCommentForm();
				this.initTagsForm();

				if (this.photo.connectionsCanSuggest || this.photo.userId == this.userService.getLocalUserId(0)) {
					this.retrieveDropdownData();
				} else {
					this.loadingPeople = false;
					this.loadingLocations = false;
					this.loadingKeywords = false;
					this.loadingUserPublicTags = false;

					this.populatePhotoMetadata();
				}

				if (this.photo.userId == this.userService.getLocalUserId(0)) {
					this.retrievePhotoHistory();
				}

				this.loadDiscussion();

				// Set delete params
				this.deleteConfirm = false;
				if (photo.status == 'deleted') {
					this.photoDeleted = true;
				} else {
					this.photoDeleted = false;
				}

				this.loading = false;
			});
	}

	/**
	 * RETRIEVE DROPDOWN DATA
	 * 
	 * Triggers the functions to retrieve the final data for the dropdowns.
	 */
	retrieveDropdownData() {
		this.loadFolders();
		this.loadPeople();
		this.loadUserLocations();
		this.loadUserKeywords();
		this.loadUserPublicTags();
	}

	// Load the users folders for the folder selector
	loadFolders() {
		this.loadingFolders = true;
		this.folderService.getFoldersBasic(0).subscribe(
			response => {
				this.userRootFolders = response.body;

				this.userRootFolders.sort((a, b) => a.name.toString().localeCompare(b.name));
				this.userRootFolders = this.userRootFolders.slice();

				this.loadingFolders = false;

				this.selectFolderValues();
			}
		);
	}

	// Load the users active connection for the People dropdown
	loadPeople() {
		let type = 'db';
		if (this.photo.userId !== this.userService.getLocalUserId(0)) {
			type = 'suggestion-new';
		}
		if (this.userService.userPeople) {
			for (const person of this.userService.userPeople) {
				let item = this.userPeople.find(d => d.name.toUpperCase() === person.name.toUpperCase());
				if (!item) {
					this.userPeople.push(person);
				}
			}
			this.userPeople.sort((a, b) => a.name.toString().localeCompare(b.name));
			this.userPeople = this.userPeople.slice();

			this.loadingPeople = false;

			this.postLoadUserData();
		} else {
			let userPeople = [];
			let user = this.userService.getLocalUser(0);
			let item = { name: user.firstName + " " + user.lastName, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '', new: false };
			this.userPeople.push(item);
			userPeople.push(item);

			this.connectionService.getUserConnectionsActive().subscribe(
				response => {
					for (const person of response.body) {
						let item = { name: person.firstName + " " + person.lastName, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '', new: false };

						let photoPeopleItem = this.userPeople.find(d => d.name.toUpperCase() === item.name.toUpperCase());
						if (!photoPeopleItem) {
							this.userPeople.push(item);
						}
						userPeople.push(item);
					}
					this.userPeople.sort((a, b) => a.name.toString().localeCompare(b.name));
					this.userPeople = this.userPeople.slice();

					this.userService.userPeople = userPeople;

					this.loadUserPeople();
				}
			);
		}
	}

	// Load the list of non-users for the People dropdown
	loadUserPeople() {
		this.userService.getUserPeople().subscribe(
			response => {
				let type = 'db';
				if (this.photo.userId !== this.userService.getLocalUserId(0)) {
					type = 'suggestion-new';
				}

				let userPeople = this.userService.userPeople;
				for (const person of response) {
					let item = { name: person, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '', new: false }

					let photoPeopleItem = this.userPeople.find(d => d.name.toUpperCase() === person.toUpperCase());
					if (!photoPeopleItem) {
						this.userPeople.push(item);
					}

					userPeople.push(item);
				}
				this.userPeople.sort((a, b) => a.name.localeCompare(b.name));
				this.userPeople = this.userPeople.slice();

				this.userService.userPeople = userPeople;

				this.loadingPeople = false;

				this.postLoadUserData();
			}
		);
	}

	//Load the user locations for the locations dropdown
	loadUserLocations() {
		let type = 'db';
		if (this.photo.userId !== this.userService.getLocalUserId(0)) {
			type = 'suggestion-new';
		}

		if (this.userService.userLocations) {
			for (const location of this.userService.userLocations) {
				let item = this.userLocations.find(d => d.name.toUpperCase() === location.name.toUpperCase());
				if (!item) {
					location.type = type;
					this.userLocations.push(location);
				}
			}

			this.userLocations.sort((a, b) => a.name.localeCompare(b.name));
			this.userLocations = this.userLocations.slice();

			this.loadingLocations = false;

			this.postLoadUserData();
		} else {
			this.userService.getUserLocations().subscribe(
				response => {
					let userLocations = [];

					for (const location of response) {
						let item = { name: location, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '', new: false };

						let photoLocationsItem = this.userLocations.find(d => d.name.toUpperCase() === location.toUpperCase());
						if (!photoLocationsItem) {
							this.userLocations.push(item);
						}

						userLocations.push(item);
					}
					this.userLocations.sort((a, b) => a.name.localeCompare(b.name));
					this.userLocations = this.userLocations.slice();

					this.userService.userLocations = userLocations;

					this.loadingLocations = false;

					this.postLoadUserData();
				}
			);
		}
	}

	// Load the user keywords for the multi-select field
	loadUserKeywords() {
		let type = 'db';
		if (this.photo.userId !== this.userService.getLocalUserId(0)) {
			type = 'suggestion-new';
		}

		if (this.userService.userKeywords) {
			for (const keyword of this.userService.userKeywords) {
				let item = this.userKeywords.find(d => d.name.toUpperCase() === keyword.name.toUpperCase());
				if (!item) {
					// We set this in case the user just added this as a new keyword.
					keyword.type = type;
					this.userKeywords.push(keyword);
				}
			}
			this.userKeywords.sort((a, b) => a.name.localeCompare(b.name));
			this.userKeywords = this.userKeywords.slice();

			this.loadingKeywords = false;

			this.postLoadUserData();
		} else {
			this.userService.getUserKeywords().subscribe(
				response => {
					let userKeywords = [];
					for (const keyword of response) {
						let item = { name: keyword, type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '', new: false };

						let photoKeywordsItem = this.userKeywords.find(d => d.name.toUpperCase() === keyword.toUpperCase());
						if (!photoKeywordsItem) {
							this.userKeywords.push(item);
						}

						userKeywords.push(item);
					}
					this.userKeywords.sort((a, b) => a.name.localeCompare(b.name));
					this.userKeywords = this.userKeywords.slice();

					this.userService.userKeywords = userKeywords;

					this.loadingKeywords = false;

					this.postLoadUserData();
				}
			);
		}
	}

	// Load the user tags for the public tags dropdown
	loadUserPublicTags() {
		this.loadingUserPublicTags = true;
		this.userService.getUserPublicTags().subscribe(
			response => {
				for (const tag of response) {
					let item = this.userTags.find(d => d.name.toUpperCase() === tag.toUpperCase());
					if (!item) {
						this.userTags.push({ name: tag, type: 'db' });
					}
				}
				this.userTags.sort((a, b) => a.name.localeCompare(b.name));
				this.userTags = this.userTags.slice();

				this.loadingUserPublicTags = false;

				this.postLoadUserData();
			}
		);
	}

	postLoadUserData() {
		if (!this.loadingPeople && !this.loadingLocations && !this.loadingKeywords && !this.loadingUserPublicTags) {
			this.populatePhotoMetadata();
		}
	}


	/**
	 * DISCUSSION TAB FUNCTIONS
	 */
	loadDiscussion() {
		this.photoCommentService.getDiscussion(this.photo.id).subscribe(
			response => {
				this.photoComments = response.body;
			}
		);
	}

	addComment() {
		let comment = this.commentForm.value.addComment;

		this.photoCommentService.addComment(this.photo.id, comment, 0).subscribe(
			response => {
				// TODO: Remove the text from the input

				// TODO: Either add the comment to the local array or reload the comments.
				this.loadDiscussion();
			}
		);
	}

	/**
	 * CLASSIFY TAB FUNCTIONS
	 */

	// Loads the photo metadata into the photo metadata arrays.
	populatePhotoMetadata() {
		this.selectedPeople = [];
		if (this.photo.people) {
			for (let person of JSON.parse(this.photo.people)) {
				const photoUserId = this.photo.userId;
				this.selectedPeople.push({ name: person, type: 'db', action: '', userId: photoUserId, suggestionId: '', new: false });

				let itemRemove = this.userPeople.find(d => d.name.toUpperCase() === person.toUpperCase());
				if (itemRemove) {
					this.userPeople.splice(this.userPeople.indexOf(itemRemove), 1);
				}
			}
			this.selectedPeople.sort((a, b) => a.name.localeCompare(b.name));
			this.selectedPeople = this.selectedPeople.slice();
		}

		this.selectedLocations = [];
		if (this.photo.locations) {
			for (let location of JSON.parse(this.photo.locations)) {
				const photoUserId = this.photo.userId;
				this.selectedLocations.push({ name: location, type: 'db', action: '', userId: photoUserId, suggestionId: '', new: false });

				let itemRemove = this.userLocations.find(d => d.name.toUpperCase() === location.toUpperCase());
				if (itemRemove) {
					this.userLocations.splice(this.userLocations.indexOf(itemRemove), 1);
				}
			}
			this.selectedLocations.sort((a, b) => a.name.localeCompare(b.name));
			this.selectedLocations = this.selectedLocations.slice();
		}

		this.selectedKeywords = [];
		if (this.photo.keywords) {
			for (let keyword of JSON.parse(this.photo.keywords)) {
				const photoUserId = this.photo.userId;
				this.selectedKeywords.push({ name: keyword, type: 'db', action: '', userId: photoUserId, suggestionId: '', new: false });

				let itemRemove = this.userKeywords.find(d => d.name.toUpperCase() === keyword.toUpperCase());
				if (itemRemove) {
					this.userKeywords.splice(this.userKeywords.indexOf(itemRemove), 1);
				}
			}
			this.selectedKeywords.sort((a, b) => a.name.localeCompare(b.name));
			this.selectedKeywords = this.selectedKeywords.slice();
		}

		this.selectedTags = [];
		if (this.photo.publicTags) {
			let photoTagsArray = [];
			if (!Array.isArray(this.photo.publicTags)) {
				photoTagsArray = JSON.parse(this.photo.publicTags);
			} else {
				photoTagsArray = this.photo.publicTags;
			}
			for (let tag of photoTagsArray) {
				this.selectedTags.push({ name: tag, type: 'db' });

				let itemRemove = this.userTags.find(d => d.name.toUpperCase() === tag.toUpperCase());
				if (itemRemove) {
					this.userTags.splice(this.userTags.indexOf(itemRemove), 1);
				}
			}
			this.selectedTags.sort((a, b) => a.name.localeCompare(b.name));
			this.selectedTags = this.selectedTags.slice();
		}

		this.retrievePhotoSuggestions(this.photo.id);
	}

	/**
	 * LOAD PHOTO SUGGESTIONS
	 * 
	 * Load suggestions for a photo from the database. Even though this function is called at the same time
	 * as populatePhotoMetadata, this is guaranteed to be the second data added to the arrays, since it 
	 * pulls it from the database.  After this is finished the rest of the dropdown data is loaded.
	 */
	retrievePhotoSuggestions(photoId: number) {
		this.photoService.getPhotoSuggestions(photoId).subscribe(
			response => {
				// Loop suggestions
				for (let suggestion of response.body) {

					if (suggestion.suggestionType == 'keyword' && suggestion.suggestionAction == 'add') {
						// Check if the keyword already exists
						const item = this.selectedKeywords.find(d => d.name.toUpperCase() === suggestion.suggestionValue.toUpperCase());
						if (item) {
							// Keyword already exists, delete the suggestion if current user is the photo or suggestion owner
							if (this.photo.userId == this.userService.getLocalUserId(0)) {
								this.declineSuggestion(suggestion, 'keyword');
							} else if (suggestion.userId == this.userService.getLocalUserId(0)) {
								this.cancelSuggestion(suggestion, 'keyword');
							}
						} else {
							// Keyword doesn't exist, add it
							this.selectedKeywords.push({ name: suggestion.suggestionValue, type: 'suggestion', action: suggestion.suggestionAction, userId: suggestion.userId, suggestionId: suggestion.suggestionId, new: false });
						}
					} else if (suggestion.suggestionType == 'keyword' && suggestion.suggestionAction == 'remove') {
						// Check if the keyword already exists
						const item = this.selectedKeywords.find(d => d.name.toUpperCase() === suggestion.suggestionValue.toUpperCase());
						if (item) {
							// Keyword already exists, update it to type suggestion with an action remove
							item.type = 'suggestion';
							item.action = suggestion.suggestionAction;
							item.suggestionId = suggestion.suggestionId;
							item.userId = suggestion.userId;
						} else {
							// Item doesn't exist, delete the suggestion if current user is the photo or suggestion owner
							if (this.photo.userId == this.userService.getLocalUserId(0)) {
								this.declineSuggestion(suggestion, 'keyword');
							} else if (suggestion.userId == this.userService.getLocalUserId(0)) {
								this.cancelSuggestion(suggestion, 'keyword');
							}
						}
					} else if (suggestion.suggestionType == 'person' && suggestion.suggestionAction == 'add') {
						// Check if the person already exists
						const item = this.selectedPeople.find(d => d.name.toUpperCase() === suggestion.suggestionValue.toUpperCase());
						if (item) {
							// Person already exists, delete the suggestion if current user is the photo or suggestion owner
							if (this.photo.userId == this.userService.getLocalUserId(0)) {
								this.declineSuggestion(suggestion, 'person');
							} else if (suggestion.userId == this.userService.getLocalUserId(0)) {
								this.cancelSuggestion(suggestion, 'person');
							}
						} else {
							// Person doesn't exist, add it
							this.selectedPeople.push({ name: suggestion.suggestionValue, type: 'suggestion', action: suggestion.suggestionAction, userId: suggestion.userId, suggestionId: suggestion.suggestionId, new: false });
						}
					} else if (suggestion.suggestionType == 'person' && suggestion.suggestionAction == 'remove') {
						// Check if the person already exists
						const item = this.selectedPeople.find(d => d.name.toUpperCase() === suggestion.suggestionValue.toUpperCase());
						if (item) {
							// Person already exists, update it to type suggestion with an action remove
							item.type = 'suggestion';
							item.action = suggestion.suggestionAction;
							item.suggestionId = suggestion.suggestionId;
							item.userId = suggestion.userId;
						} else {
							// Item doesn't exist, delete the suggestion if current user is the photo or suggestion owner
							if (this.photo.userId == this.userService.getLocalUserId(0)) {
								this.declineSuggestion(suggestion, 'person');
							} else if (suggestion.userId == this.userService.getLocalUserId(0)) {
								this.cancelSuggestion(suggestion, 'person');
							}
						}
					} else if (suggestion.suggestionType == 'location' && suggestion.suggestionAction == 'add') {
						// Check if the location already exists
						const item = this.selectedLocations.find(d => d.name.toUpperCase() === suggestion.suggestionValue.toUpperCase());
						if (item) {
							// Location already exists, delete the suggestion if current user is the photo or suggestion owner
							if (this.photo.userId == this.userService.getLocalUserId(0)) {
								this.declineSuggestion(suggestion, 'location');
							} else if (suggestion.userId == this.userService.getLocalUserId(0)) {
								this.cancelSuggestion(suggestion, 'location');
							}
						} else {
							// Location doesn't exist, add it
							this.selectedLocations.push({ name: suggestion.suggestionValue, type: 'suggestion', action: suggestion.suggestionAction, userId: suggestion.userId, suggestionId: suggestion.suggestionId, new: false });
						}
					} else if (suggestion.suggestionType == 'location' && suggestion.suggestionAction == 'remove') {
						// Check if the location already exists
						const item = this.selectedLocations.find(d => d.name.toUpperCase() === suggestion.suggestionValue.toUpperCase());
						if (item) {
							// Location already exists, update it to type suggestion with an action remove
							item.type = 'suggestion';
							item.action = suggestion.suggestionAction;
							item.suggestionId = suggestion.suggestionId;
							item.userId = suggestion.userId;
						} else {
							// Item doesn't exist, delete the suggestion if current user is the photo or suggestion owner
							if (this.photo.userId == this.userService.getLocalUserId(0)) {
								this.declineSuggestion(suggestion, 'location');
							} else if (suggestion.userId == this.userService.getLocalUserId(0)) {
								this.cancelSuggestion(suggestion, 'location');
							}
						}
					}
				}
			});
	}

	/**
	 * UPDATE PHOTO FUNCTIONS
	 */
	updatePhoto() {
		this.loading = true;

		// Add new people
		if (this.selectedPeople && this.selectedPeople.length > 0) {
			for (const person of this.selectedPeople) {
				if (person.new == true && person.name !== '') {
					person.type = 'db';
					this.userService.addUserPerson(person.name).subscribe(
						response => {
							// Add the person to local cache
							this.userService.userPeople.push(person);
							this.userService.userPeople.sort((a, b) => a.name.toString().localeCompare(b.name));
							this.userService.userPeople = this.userService.userPeople.slice();
						});
				}
			}
		}

		// Add new locations
		if (this.selectedLocations && this.selectedLocations.length > 0) {
			for (const location of this.selectedLocations) {
				if (location.new == true && location.name !== '') {
					location.type = 'db';
					this.userService.addUserLocation(location.name).subscribe(
						response => {
							// Add the location to local cache
							this.userService.userLocations.push(location);
							this.userService.userLocations.sort((a, b) => a.name.toString().localeCompare(b.name));
							this.userService.userLocations = this.userService.userLocations.slice();
						});
				}
			}
		}

		// Add new keywords
		if (this.selectedKeywords && this.selectedKeywords.length > 0) {
			for (const keyword of this.selectedKeywords) {
				if (keyword.new == true && keyword.name !== '') {
					keyword.type = 'db';
					this.userService.addUserKeyword(keyword.name).subscribe(
						response => {
							// Add the location to local cache
							this.userService.userKeywords.push(keyword);
							this.userService.userKeywords.sort((a, b) => a.name.toString().localeCompare(b.name));
							this.userService.userKeywords = this.userService.userKeywords.slice();
						});
				}
			}
		}

		// Update Photo

		// Description
		if (this.classifyForm.value.description) {
			this.photo.description = this.classifyForm.value.description;
		} else {
			this.photo.description = '';
		}

		// Folder
		if (this.selectedSubFolder) {
			this.photo.folderId = this.selectedSubFolder.id;
		} else if (this.selectedRootFolder) {
			this.photo.folderId = this.selectedRootFolder.id;
		} else {
			this.photo.folderId = 0;
		}

		// People
		let peopleOnlyArray = [];
		if (this.selectedPeople && this.selectedPeople.length > 0) {
			for (let person of this.selectedPeople) {
				if (person.name !== '') {
					peopleOnlyArray.push(person.name.toUpperCase());
				}

			}
			this.photo.people = JSON.stringify(peopleOnlyArray);
		} else {
			this.photo.people = '[]';
		}

		// Date
		let clearDate = false;
		if (this.classifyForm.value.capturedDate) {
			this.photo.capturedType = "date";
			this.photo.capturedDate = this.classifyForm.value.capturedDate;
		} else {
			this.photo.capturedType = "date";
			this.photo.capturedDate = null;
			clearDate = true;
		}

		// Locations
		let locationOnlyArray = [];
		if (this.selectedLocations && this.selectedLocations.length > 0) {
			for (let location of this.selectedLocations) {
				if (location.name !== '') {
					locationOnlyArray.push(location.name.toUpperCase());
				}
			}
			this.photo.locations = JSON.stringify(locationOnlyArray);
		} else {
			this.photo.locations = '[]';
		}

		// Keywords
		let keywordOnlyArray = [];
		if (this.selectedKeywords && this.selectedKeywords.length > 0) {
			for (let keyword of this.selectedKeywords) {
				if (keyword.name !== '') {
					keywordOnlyArray.push(keyword.name.toUpperCase());
				}
			}
			this.photo.keywords = JSON.stringify(keywordOnlyArray);
		} else {
			this.photo.keywords = '[]';
		}

		// Set Tags to null so they aren't updated
		this.photo.publicTags = null;

		this.photoService.updatePhoto(this.photo, true, clearDate).subscribe(
			response => {
				if (response.status === 200) {
					this.photo.capturedDateAuto = response.body.capturedDateAuto;
					this.photo.publicTags = response.body.publicTags;

					// Announce the photo has been updated so it gets removed from the unclassified 
					// listing if they are looking at that page
					this.photoService.setPhotoCategorized(response.body);

					// Set the active photo which will trigger the subscription.
					// TODO: Instead of triggering the subscription this should really update the local
					//       object and cache, which would be more efficient.
					this.photoService.setActivePhoto(this.photo);

					this.loading = false;
				} else {
					this.loading = false;
				}
			},
			err => {
				this.loading = false;
			}
		);
	}

	updatePhotoForReview() {
		this.loading = true;

		let keywordsComplete = true;
		let peopleComplete = false;
		let locationsComplete = true;

		// Add new people
		if (this.selectedPeople && this.selectedPeople.length > 0) {
			for (const person of this.selectedPeople) {
				if (person.new == true && person.name !== '') {
					person.new = false;
					this.userService.addUserPerson(person.name).subscribe(
						response => {
							// Add the person to local cache
							this.userService.userPeople.push(person);
							this.userService.userPeople.sort((a, b) => a.name.toString().localeCompare(b.name));
							this.userService.userPeople = this.userService.userPeople.slice();
						});
				}
			}
		}

		// Add new locations
		if (this.selectedLocations && this.selectedLocations.length > 0) {
			for (const location of this.selectedLocations) {
				if (location.new == true && location.name !== '') {
					location.new = false;
					this.userService.addUserLocation(location.name).subscribe(
						response => {
							// Add the location to local cache
							this.userService.userLocations.push(location);
							this.userService.userLocations.sort((a, b) => a.name.toString().localeCompare(b.name));
							this.userService.userLocations = this.userService.userLocations.slice();
						});
				}
			}
		}

		// Add new keywords
		if (this.selectedKeywords && this.selectedKeywords.length > 0) {
			for (const keyword of this.selectedKeywords) {
				if (keyword.new == true && keyword.name !== '') {

					keyword.new = false;
					this.userService.addUserKeyword(keyword.name).subscribe(
						response => {
							// Add the location to local cache
							this.userService.userKeywords.push(keyword);
							this.userService.userKeywords.sort((a, b) => a.name.toString().localeCompare(b.name));
							this.userService.userKeywords = this.userService.userKeywords.slice();
						});
				}
			}
		}

		/**
		 * .type can be the following:
		 * 		- db - added by the photo owner, loaded from the db
		 * 		- new - added by the photo owner, added during this session (cannot be this value in this function)
		 * 		- suggestion - added by a non-photo owner user, but not during this session.  Does not need added now.
		 * 		- suggestion-new - added by a non-photo owner user, during this session.  Needs added.
		 * 	
		 * .action can be the following:
		 * 		- add
		 * 		- remove
		 */

		//let photoHasLoaded = false;

		if (this.selectedPeople && this.selectedPeople.length > 0) {
			for (let i = 0; i < this.selectedPeople.length; i++) {
				if (this.selectedPeople[i].name !== '' && this.selectedPeople[i].type == 'suggestion-new') {
					// Add suggestion
					let personName = this.selectedPeople[i].name.toUpperCase();
					let photoSuggestion = new PhotoSuggestion();
					photoSuggestion.photoId = this.photo.id;
					photoSuggestion.suggestionAction = this.selectedPeople[i].action;
					photoSuggestion.suggestionType = 'person';
					photoSuggestion.suggestionValue = personName;

					this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
						response => {
							this.selectedPeople[i].type = 'suggestion';
							this.selectedPeople[i].suggestionId = response.body.suggestionId;

							// Check if this is the last suggestion to add
							if (i == (this.selectedPeople.length - 1)) {
								peopleComplete = true;
								if (keywordsComplete && peopleComplete && locationsComplete) {
									this.finishUpdatePhotoForReview();
								}
							}
						}
					);
				} else if (this.selectedPeople[i].name !== '' && this.selectedPeople[i].type == 'suggestion-remove') {
					// Add suggestion
					let personName = this.selectedPeople[i].name.toUpperCase();
					let photoSuggestion = new PhotoSuggestion();
					photoSuggestion.photoId = this.photo.id;
					photoSuggestion.suggestionAction = 'remove';
					photoSuggestion.suggestionType = 'person';
					photoSuggestion.suggestionValue = personName;

					this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
						response => {
							this.selectedPeople[i].type = 'suggestion';
							this.selectedPeople[i].suggestionId = response.body.suggestionId;

							// Check if this is the last suggestion to add
							if (i == (this.selectedPeople.length - 1)) {
								peopleComplete = true;
								if (keywordsComplete && peopleComplete && locationsComplete) {
									this.finishUpdatePhotoForReview();
								}
							}
						}
					);
				} else {
					// Check if this is the last suggestion to add
					if (i == (this.selectedPeople.length - 1)) {
						peopleComplete = true;
						if (keywordsComplete && peopleComplete && locationsComplete) {
							this.finishUpdatePhotoForReview();
						}
					}

					// If this tag is empty, remove it
					if (this.selectedPeople[i].name == '') {
						this.selectedPeople.splice(i, 1);
					}
				}
			}
		} else {
			peopleComplete = true;
			if (keywordsComplete && peopleComplete && locationsComplete) {
				this.finishUpdatePhotoForReview();
			}
		}

		if (this.selectedLocations && this.selectedLocations.length > 0) {
			for (let i = 0; i < this.selectedLocations.length; i++) {
				if (this.selectedLocations[i].name !== '' && this.selectedLocations[i].type == 'suggestion-new') {
					// Add suggestion
					let locationName = this.selectedLocations[i].name.toUpperCase();
					let photoSuggestion = new PhotoSuggestion();
					photoSuggestion.photoId = this.photo.id;
					photoSuggestion.suggestionAction = this.selectedLocations[i].action;
					photoSuggestion.suggestionType = 'location';
					photoSuggestion.suggestionValue = locationName;

					this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
						response => {
							this.selectedLocations[i].type = 'suggestion';
							this.selectedLocations[i].suggestionId = response.body.suggestionId;

							// Check if this is the last suggestion to add
							if (i == (this.selectedLocations.length - 1)) {
								locationsComplete = true;
								if (keywordsComplete && peopleComplete && locationsComplete) {
									this.finishUpdatePhotoForReview();
								}
							}
						}
					);
				} else if (this.selectedLocations[i].name !== '' && this.selectedLocations[i].type == 'suggestion-remove') {
					// Add suggestion
					let locationName = this.selectedLocations[i].name.toUpperCase();
					let photoSuggestion = new PhotoSuggestion();
					photoSuggestion.photoId = this.photo.id;
					photoSuggestion.suggestionAction = 'remove';
					photoSuggestion.suggestionType = 'location';
					photoSuggestion.suggestionValue = locationName;

					this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
						response => {
							this.selectedLocations[i].type = 'suggestion';
							this.selectedLocations[i].suggestionId = response.body.suggestionId;

							// Check if this is the last suggestion to add
							if (i == (this.selectedLocations.length - 1)) {
								locationsComplete = true;
								if (keywordsComplete && peopleComplete && locationsComplete) {
									this.finishUpdatePhotoForReview();
								}
							}
						}
					);
				} else {
					// Check if this is the last suggestion to add
					if (i == (this.selectedLocations.length - 1)) {
						locationsComplete = true;
						if (keywordsComplete && peopleComplete && locationsComplete) {
							this.finishUpdatePhotoForReview();
						}
					}

					// If this tag is empty, remove it
					if (this.selectedLocations[i].name == '') {
						this.selectedLocations.splice(i, 1);
					}
				}
			}
		} else {
			locationsComplete = true;
			if (keywordsComplete && peopleComplete && locationsComplete) {
				this.finishUpdatePhotoForReview();
			}
		}

		/** NOT HANDLING DATE FOR NOW
		if (this.classifyForm.value.capturedDate) {
			this.photo.capturedType = "date";
			this.photoReview.capturedDate = this.classifyForm.value.capturedDate;
		} 
		*/

		if (this.selectedKeywords && this.selectedKeywords.length > 0) {
			for (let i = 0; i < this.selectedKeywords.length; i++) {
				if (this.selectedKeywords[i].name !== '' && this.selectedKeywords[i].type == 'suggestion-new') {
					// Add suggestion
					let keywordName = this.selectedKeywords[i].name.toUpperCase();
					let photoSuggestion = new PhotoSuggestion();
					photoSuggestion.photoId = this.photo.id;
					photoSuggestion.suggestionAction = this.selectedKeywords[i].action;
					photoSuggestion.suggestionType = 'keyword';
					photoSuggestion.suggestionValue = keywordName;
					this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
						response => {
							this.selectedKeywords[i].type = 'suggestion';
							this.selectedKeywords[i].suggestionId = response.body.suggestionId;

							// Check if this is the last suggestion to add
							if (i == (this.selectedKeywords.length - 1)) {
								keywordsComplete = true;
								if (keywordsComplete && peopleComplete && locationsComplete) {
									this.finishUpdatePhotoForReview();
								}
							}
						}
					);

					// Create the custom user person if needed
					// if (this.selectedKeywords[i].new == true) {
					// 	this.userService.addUserPerson(keywordName).subscribe(
					// 		response => {
					// 			this.selectedKeywords[i].new = false;

					// 			let item = this.userService.userKeywords.find(d => d.name.toUpperCase() === keywordName.toUpperCase());
					// 			if (!item) {
					// 				this.userService.userKeywords.push(this.selectedKeywords[i]);
					// 				this.userService.userKeywords.sort((a, b) => a.name.toString().localeCompare(b.name));
					// 				this.userService.userKeywords = this.userService.userKeywords.slice();
					// 			}
					// 		});
					// }
				} else if (this.selectedKeywords[i].name !== '' && this.selectedKeywords[i].type == 'suggestion-remove') {
					// Add suggestion
					let keywordName = this.selectedKeywords[i].name.toUpperCase();
					let photoSuggestion = new PhotoSuggestion();
					photoSuggestion.photoId = this.photo.id;
					photoSuggestion.suggestionAction = 'remove';
					photoSuggestion.suggestionType = 'keyword';
					photoSuggestion.suggestionValue = keywordName;

					this.photoService.createPhotoSuggestion(photoSuggestion).subscribe(
						response => {
							this.selectedKeywords[i].type = 'suggestion';
							this.selectedKeywords[i].suggestionId = response.body.suggestionId;

							// Check if this is the last suggestion to add
							if (i == (this.selectedKeywords.length - 1)) {
								keywordsComplete = true;
								if (keywordsComplete && peopleComplete && locationsComplete) {
									this.finishUpdatePhotoForReview();
								}
							}
						}
					);
				} else {
					// Check if this is the last suggestion to add
					if (i == (this.selectedKeywords.length - 1)) {
						keywordsComplete = true;
						if (keywordsComplete && peopleComplete && locationsComplete) {
							this.finishUpdatePhotoForReview();
						}
					}

					// If this tag is empty, remove it
					if (this.selectedKeywords[i].name == '') {
						this.selectedKeywords.splice(i, 1);
					}
				}
			}
		} else {
			keywordsComplete = true;
			if (keywordsComplete && peopleComplete && locationsComplete) {
				this.finishUpdatePhotoForReview();
			}
		}
	}

	finishUpdatePhotoForReview() {
		this.resetPeopleSelector();
		this.resetLocationsSelector();
		this.resetKeywordsSelector();

		// this.resetSelectedMetadata();

		this.peopleHideAddIcon = false;
		this.locationsHideAddIcon = false;
		this.keywordsHideAddIcon = false;
		this.tagsHideAddIcon = false;

		this.editMode = false;
		this.loading = false;
	}

	updatePhotoTags() {
		this.loading = true;

		// Add new tags
		if (this.selectedTags && this.selectedTags.length > 0) {
			for (const tag of this.selectedTags) {
				if (tag.new == true) {
					tag.type = 'db';
					tag.new = false;
					this.userService.addUserPublicTag(tag.name).subscribe(
						response => {
							// TODO: Error handling
						});
				}
			}
		}

		// Update photo
		let tagsOnlyArray = [];
		if (this.selectedTags && this.selectedTags.length > 0) {
			for (let tag of this.selectedTags) {
				tagsOnlyArray.push(tag.name)
			}
			this.photo.publicTags = JSON.stringify(tagsOnlyArray);
		} else {
			this.photo.publicTags = '[]';
		}
		this.photo.publicTagsArray = JSON.parse(this.photo.publicTags);

		this.photoService.updatePhoto(this.photo, true, false).subscribe(
			response => {
				this.loading = false;

				this.photoService.setActivePhoto(this.photo);
			}
		);
	}

	/**
	 * SUGGESTION FUNCTIONS
	*/

	acceptSuggestion(suggestion, field) {
		this.photoService.acceptPhotoSuggestion(suggestion.suggestionId).subscribe(
			response => {
				if (suggestion.action == 'add') {
					// Change the suggestion from type 'suggestion' to 'db' in the photoKeywords array
					suggestion.type = 'db';

					if (field == 'keyword') {
						// Add the suggestion to the photo.keywords json string
						let keywordsArray = JSON.parse(this.photo.keywords);
						if (!keywordsArray) {
							keywordsArray = [];
						}
						keywordsArray.push(suggestion.name);
						this.photo.keywords = JSON.stringify(keywordsArray);

						// Add new keyword to the user keyword table if one doesn't already exist.
						let item = this.userService.userKeywords.find(d => d.name.toUpperCase() === suggestion.name.toUpperCase());
						if (!item) {
							this.userService.addUserKeyword(suggestion.name).subscribe(
								response => {
									// Add the keyword to local cache
									let newItem = { name: suggestion.name, type: 'db', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '', new: false }
									this.userService.userKeywords.push(newItem);
									this.userService.userKeywords.sort((a, b) => a.name.toString().localeCompare(b.name));
									this.userService.userKeywords = this.userService.userKeywords.slice();
								});
						}
					} else if (field == 'location') {
						// Add the suggestion to the photo.locations json string
						let locationsArray = JSON.parse(this.photo.locations);
						if (!locationsArray) {
							locationsArray = [];
						}
						locationsArray.push(suggestion.name);
						this.photo.locations = JSON.stringify(locationsArray);

						// Add new location to the user location table if one doesn't already exist.
						let item = this.userService.userLocations.find(d => d.name.toUpperCase() === suggestion.name.toUpperCase());
						if (!item) {
							this.userService.addUserLocation(suggestion.name).subscribe(
								response => {
									// Add the location to local cache
									let newItem = { name: suggestion.name, type: 'db', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '', new: false }
									this.userService.userLocations.push(newItem);
									this.userService.userLocations.sort((a, b) => a.name.toString().localeCompare(b.name));
									this.userService.userLocations = this.userService.userLocations.slice();
								});
						}
					} else if (field == 'person') {
						// Add the suggestion to the photo.people json string
						let peopleArray = JSON.parse(this.photo.people);
						if (!peopleArray) {
							peopleArray = [];
						}
						peopleArray.push(suggestion.name);
						this.photo.people = JSON.stringify(peopleArray);

						// Add new person to the user person table if one doesn't already exist.
						let item = this.userService.userPeople.find(d => d.name.toUpperCase() === suggestion.name.toUpperCase());
						if (!item) {
							this.userService.addUserPerson(suggestion.name).subscribe(
								response => {
									// Add the person to local cache
									let newItem = { name: suggestion.name, type: 'db', action: '', userId: this.userService.getLocalUserId(0), suggestionId: '', new: false }
									this.userService.userPeople.push(newItem);
									this.userService.userPeople.sort((a, b) => a.name.toString().localeCompare(b.name));
									this.userService.userPeople = this.userService.userPeople.slice();
								});
						}
					}
				} else if (suggestion.action == 'remove') {
					if (field == 'keyword') {
						// Remove the suggestion from the photo.keywords json string
						let keywordsArray = JSON.parse(this.photo.keywords);
						const item = keywordsArray.find(d => d.toUpperCase() === suggestion.name.toUpperCase());
						if (item) {
							keywordsArray.splice(keywordsArray.indexOf(item), 1);
						}
						this.photo.keywords = JSON.stringify(keywordsArray);

						// Remove the keyword from the photoKeywords array
						this.selectedKeywords.splice(this.selectedKeywords.indexOf(suggestion), 1);
					} else if (field == 'location') {
						// Remove the suggestion from the photo.locations json string
						let locationsArray = JSON.parse(this.photo.locations);
						const item = locationsArray.find(d => d.toUpperCase() === suggestion.name.toUpperCase());
						if (item) {
							locationsArray.splice(locationsArray.indexOf(item), 1);
						}
						this.photo.locations = JSON.stringify(locationsArray);

						// Remove the keyword from the photoKeywords array
						this.selectedLocations.splice(this.selectedLocations.indexOf(suggestion), 1);
					} else if (field == 'person') {
						// Remove the suggestion from the photo.keywords json string
						let peopleArray = JSON.parse(this.photo.people);
						const item = peopleArray.find(d => d.toUpperCase() === suggestion.name.toUpperCase());
						if (item) {
							peopleArray.splice(peopleArray.indexOf(item), 1);
						}
						this.photo.people = JSON.stringify(peopleArray);

						// Remove the keyword from the photoKeywords array
						this.selectedPeople.splice(this.selectedPeople.indexOf(suggestion), 1);
					}
				}
			});
	}

	declineSuggestion(suggestion, field) {
		this.photoService.declinePhotoSuggestion(suggestion.suggestionId).subscribe(
			response => {
				if (suggestion.action == 'add') {
					if (field == 'keyword') {
						this.selectedKeywords.splice(this.selectedKeywords.indexOf(suggestion), 1);
					} else if (field == 'location') {
						this.selectedLocations.splice(this.selectedLocations.indexOf(suggestion), 1);
					} else if (field == 'person') {
						this.selectedPeople.splice(this.selectedPeople.indexOf(suggestion), 1);
					}
				} else if (suggestion.action == 'remove') {
					if (field == 'keyword') {
						const item = this.selectedKeywords.find(d => d.name.toUpperCase() === suggestion.name.toUpperCase());
						if (item) {
							item.type = 'db';
							item.action = '';
							item.suggestionId = '';
							item.userId = this.photo.userId;
						}
					} else if (field == 'location') {
						const item = this.selectedLocations.find(d => d.name.toUpperCase() === suggestion.name.toUpperCase());
						if (item) {
							item.type = 'db';
							item.action = '';
							item.suggestionId = '';
							item.userId = this.photo.userId;
						}
					} else if (field == 'person') {
						const item = this.selectedPeople.find(d => d.name.toUpperCase() === suggestion.name.toUpperCase());
						if (item) {
							item.type = 'db';
							item.action = '';
							item.suggestionId = '';
							item.userId = this.photo.userId;
						}
					}
				}
			}
		);
	}

	cancelSuggestion(suggestion, field) {
		this.photoService.cancelPhotoSuggestion(suggestion.suggestionId).subscribe(
			response => {
				// Check if the suggestion action is 'add' or 'remove'
				if (suggestion.action == 'add') {
					if (field == 'keyword') {
						this.selectedKeywords.splice(this.selectedKeywords.indexOf(suggestion), 1);
					} else if (field == 'location') {
						this.selectedLocations.splice(this.selectedLocations.indexOf(suggestion), 1);
					} else if (field == 'person') {
						this.selectedPeople.splice(this.selectedPeople.indexOf(suggestion), 1);
					}
				} else if (suggestion.action == 'remove') {
					if (field == 'keyword') {
						const item = this.selectedKeywords.find(d => d.name.toUpperCase() === suggestion.name.toUpperCase());
						if (item) {
							item.type = 'db';
							item.action = '';
							item.suggestionId = '';
							item.userId = this.photo.userId;
						}
					} else if (field == 'location') {
						const item = this.selectedLocations.find(d => d.name.toUpperCase() === suggestion.name.toUpperCase());
						if (item) {
							item.type = 'db';
							item.action = '';
							item.suggestionId = '';
							item.userId = this.photo.userId;
						}
					} else if (field == 'person') {
						const item = this.selectedPeople.find(d => d.name.toUpperCase() === suggestion.name.toUpperCase());
						if (item) {
							item.type = 'db';
							item.action = '';
							item.suggestionId = '';
							item.userId = this.photo.userId;
						}
					}
				}
			});
	}


	/**
	 * EDIT METADATA FUNCTIONS TIED TO THE DROPDOWN FIELDS
	 */

	/**
	 * FOLDER FUNCTIONS
	 */

	selectFolderValues() {
		if (this.photo.folderId) {
			let folder: Folder = this.userRootFolders.find(d => d.id === this.photo.folderId);
			if (folder) {
				// Root folder
				this.selectedRootFolder = folder;
			} else {
				// Sub folder
				for (let parentFolder of this.userRootFolders) {
					let subfolder: Folder = parentFolder.folders.find(d => d.id === this.photo.folderId);
					if (subfolder) {
						this.selectedRootFolder = parentFolder;
						this.selectedSubFolder = subfolder;
						break;
					}
				}
			}
		} else {
			this.selectedRootFolder = null;
			this.selectedSubFolder = null;
		}
	}

	// Happens when the user presses the down arrow on the folder input
	showAllUserFolders() {
		if (this.foldersShowSelector) {
			this.resetFolderSelector();
		} else {
			this.foldersShowSelector = true;
		}
	}

	// Happens when the user clicks inside the folder input
	folderFocus() {
		this.foldersShowSelector = true;
	}

	// Happens when the user releases any key when in the folder input
	// This provides a delay so the search doesn't trigger as often
	folderKeyup() {
		this.folderSearchSubject.next(null);
	}

	// Happens when the user releases any key when in the folder input
	// Triggered after the delay in the above function
	folderKeyupSearch() {
		let value = this.classifyForm.controls['folder'].value;
		if (value.length > 0) {
			this.tempFolder = value;
			this.foldersShowSelector = true;
			this.filteredUserRootFolders = this.userRootFolders.filter(d => String(d.name).toUpperCase().includes(this.tempFolder.toUpperCase()));
		} else {
			this.tempFolder = '';
			this.filteredUserRootFolders = [];
		}
	}

	// Happens when a user selects the No Folder option in the folder selector
	selectNoFolder() {
		this.selectedRootFolder = null;
		this.selectedSubFolder = null;
		this.expandedRootId = -1;
		this.newSubFolderParentId = -1;
		this.foldersShowSelector = false;
	}

	// Happens when a user selects a folder in the folder selector
	selectFolder(folder, subfolder) {
		this.selectedRootFolder = folder;
		this.selectedSubFolder = subfolder;

		this.resetFolderSelector();
	}

	// Happens when a user presses the expand icon next to a root folder
	expandFolder(folder) {
		if (folder.folders && folder.folders.length > 0) {
			this.newSubFolderParentId = -1;
			this.classifyForm.controls['newSubFolderName'].setValue('');

			this.expandedRootId = folder.id;
		}
	}

	// Happens when a user presses the collapse icon next to a root folder
	collapseFolder() {
		this.expandedRootId = -1;
	}

	// Happens when the user presses the X icon after typing to create a new root folder
	cancelCreateRootFolder() {
		this.tempFolder = '';
		this.classifyForm.controls['folder'].setValue('');
	}

	// Happens when a user presses the check icon after typing to create a new root folder
	createRootFolder() {
		let item = this.userRootFolders.find(d => d.name.toUpperCase() === this.tempFolder.toUpperCase());
		if (!item) {
			this.loadingCreateFolder = 0;

			let folder: Folder = new Folder;
			folder.name = this.classifyForm.controls['folder'].value.toUpperCase();
			folder.parentId = 0;

			this.folderService.createFolder(folder).subscribe(
				response => {
					this.userRootFolders.push(response.body);

					this.userRootFolders.sort((a, b) => a.name.toString().localeCompare(b.name));
					this.userRootFolders = this.userRootFolders.slice();

					this.classifyForm.controls['folder'].setValue('');
					this.tempFolder = '';

					this.loadingCreateFolder = -1;
				}
			);
		}
	}

	// Happens when a user presses the + Create Subfolder option next to a root folder
	showCreateSubFolder(parentFolderId) {
		this.expandedRootId = parentFolderId;

		this.newSubFolderParentId = parentFolderId;
	}

	// Happens when a user presses the X icon within the create subfolder dialog
	cancelShowCreateSubFolder() {
		this.newSubFolderParentId = -1;
	}

	// Happens when a user presses the check icon within the create subfolder dialog
	createSubFolder(parentFolderId) {
		this.loadingCreateFolder = parentFolderId;

		let folder: Folder = new Folder;
		folder.name = this.classifyForm.value.newSubFolderName.toUpperCase();
		folder.parentId = parentFolderId;

		this.folderService.createFolder(folder).subscribe(
			response => {
				let parentFolder: Folder = this.userRootFolders.find(d => d.id === parentFolderId);
				if (parentFolder) {
					if (parentFolder.folders) {
						parentFolder.folders.push(response.body);
					} else {
						parentFolder.folders = [];
						parentFolder.folders.push(response.body);
					}

					parentFolder.folders.sort((a, b) => a.name.toString().localeCompare(b.name));
					parentFolder.folders = parentFolder.folders.slice();

					this.newSubFolderParentId = -1;

					this.loadingCreateFolder = -1;
				} else {
					// Error handling, just pull the folders over again completely
					this.loadingCreateFolder = -1;
				}

			}
		);
	}

	// Resets the form, which happens after a few different actions
	resetFolderSelector() {
		this.classifyForm.controls['folder'].setValue('');
		this.classifyForm.controls['newSubFolderName'].setValue('');

		this.filteredUserRootFolders = [];
		this.expandedRootId = -1;

		this.newSubFolderParentId = -1;
		this.tempFolder = '';

		this.foldersShowSelector = false;
	}

	/** 
	 * PEOPLE FUNCTIONS 
	 */

	// Happens when a user presses the + arrow next to the selected tags
	addPersonTag() {
		let type = '';
		if (this.photo.userId !== this.userService.getLocalUserId(0)) {
			type = 'suggestion-new';
		}
		this.selectedPeople.push({ name: '', type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '', new: false });

		this.peopleHideAddIcon = true;
	}

	// Happens when the user presses the down arrow on the people input
	showAllUserPeople() {
		this.peopleShowSelector = !this.peopleShowSelector;
	}

	// Happens when the user clicks inside the people input
	peopleFocus() {
		this.peopleShowSelector = true;
	}

	// Happens when the user releases any key when in the locations input
	// This provides a delay so the search doesn't trigger as often
	peopleKeyup() {
		this.peopleSearchSubject.next(null);
	}

	// Happens when the user releases any key when in the people input
	// Triggered after the delay in the above function
	peopleKeyupSearch() {
		let value = this.inputPerson.nativeElement.value;
		if (value.length > 0) {
			this.tempPerson = value;
			this.peopleShowSelector = true;
			this.filteredUserPeople = this.userPeople.filter(d => String(d.name).toUpperCase().includes(this.tempPerson.toUpperCase()));
		} else {
			this.tempPerson = '';
			this.filteredUserPeople = [];
		}
	}

	// Happens when the user releases the enter key when in the people input, or after a user presses the check icon after typing in the people input
	// This should add the typed value as a new person
	peopleKeyupEnter() {
		let existingItem = this.selectedPeople.find(d => d.name.toUpperCase() === this.tempPerson.toUpperCase());
		if (!existingItem && this.tempPerson.length > 1) {
			let emptyItem = this.selectedPeople.find(d => d.name.toUpperCase() === '');
			emptyItem.name = this.tempPerson.toUpperCase();
			if (this.photo.userId == this.userService.getLocalUserId(0)) {
				emptyItem.type = 'new';
				emptyItem.new = true;
			} else {
				emptyItem.type = 'suggestion-new';
				emptyItem.new = true;
			}
			emptyItem.new = true;

			this.resetPeopleSelector();

			this.peopleHideAddIcon = false;
		}
	}

	// Happens when the user clicks on a person
	selectPerson(person) {
		let existingItem = this.selectedPeople.find(d => d.name.toUpperCase() === person.name.toUpperCase());
		if (!existingItem) {
			let emptyItem = this.selectedPeople.find(d => d.name.toUpperCase() === '');
			emptyItem.name = person.name.toUpperCase();
			if (this.photo.userId == this.userService.getLocalUserId(0)) {
				emptyItem.type = 'db';
			} else {
				emptyItem.type = 'suggestion-new';
			}
			emptyItem.new = false;

			this.resetPeopleSelector();

			this.peopleHideAddIcon = false;
		}

		let removeItem = this.userPeople.find(d => d.name.toUpperCase() === person.name.toUpperCase());
		if (removeItem) {
			this.userPeople.splice(this.userPeople.indexOf(removeItem), 1);
		}
	}

	// Happens when the user presses the X icon on the selected person
	removePerson(person, hideAddIcon) {
		if (person.new == false && person.name !== '') {
			let itemAdd = this.userPeople.find(d => d.name.toUpperCase() === person.name.toUpperCase());
			if (!itemAdd) {
				this.userPeople.push({ name: person.name.toUpperCase(), type: person.type });
				this.userPeople.sort((a, b) => a.name.localeCompare(b.name));
				this.userPeople = this.userPeople.slice();
			}
		}

		if (this.photo.userId !== this.userService.getLocalUserId(0) && person.type == 'db') {
			person.type = 'suggestion-new';
			person.action = 'remove';
			person.userId = this.userService.getLocalUserId(0);
		} else {
			let itemRemove = this.selectedPeople.find(d => d.name.toUpperCase() === person.name.toUpperCase());
			if (itemRemove) {
				this.selectedPeople.splice(this.selectedPeople.indexOf(itemRemove), 1);
			}
		}

		if (hideAddIcon) {
			this.peopleHideAddIcon = false;

			this.peopleShowSelector = false;
		}
	}

	cancelNewSuggestion(person) {
		person.type = 'db';
		person.action = '';
		person.userId = this.photo.userId;
	}


	// Happens when a user presses the X icon after typing in the people input
	cancelCreatePerson() {
		this.inputPerson.nativeElement.value = '';
		this.tempPerson = '';
	}

	// Resets the form, which happens after a few different actions
	resetPeopleSelector() {
		this.filteredUserPeople = [];
		this.peopleShowSelector = false;
		this.tempPerson = '';
	}


	/**
	 * DATE FUNCTIONS
	 */
	addDate() {
		this.dateHideAddIcon = true;
	}

	removeDate() {
		this.inputDate.nativeElement.value = '';
		this.classifyForm.value.capturedDate = '';
		this.photo.capturedDate = null;

		this.dateHideAddIcon = false;
	}


	/** 
	 * LOCATIONS FUNCTIONS 
	 * */
	// Happens when a user presses the + arrow next to the selected tags
	addLocationTag() {
		let type = '';
		if (this.photo.userId !== this.userService.getLocalUserId(0)) {
			type = 'suggestion-new';
		}
		this.selectedLocations.push({ name: '', type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '' });

		this.locationsHideAddIcon = true;
	}

	// Happens when the user presses the down arrow on the locations input
	showAllUserLocations() {
		if (this.locationsShowSelector) {
			this.resetLocationsSelector();
		} else {
			this.locationsShowSelector = true;
		}
	}

	// Happens when the user clicks inside the locations input
	locationsFocus() {
		this.locationsShowSelector = true;
	}

	// Happens when the user releases any key when in the locations input
	// This provides a delay so the search doesn't trigger as often
	locationsKeyup() {
		this.locationsSearchSubject.next(null);
	}

	// Happens when the user releases any key when in the locations input
	// Triggered after the delay in the above function
	locationsKeyupSearch() {
		let value = this.inputLocation.nativeElement.value;
		if (value.length > 0) {
			this.tempLocation = value;
			this.locationsShowSelector = true;

			if (this.locationsShowSuggestions) {
				this.filteredUserLocations = [];

				this.getLocationSuggestions();
			} else {
				this.filteredPublicLocations = [];
				this.filteredUserLocations = this.userLocations.filter(d => String(d.name).toUpperCase().includes(this.tempLocation.toUpperCase()));
			}

		} else {
			this.tempLocation = '';
			this.filteredUserLocations = [];
		}
	}

	// Happens when the user releases the enter key when in the locations input, or after a user presses the check icon after typing in the locations input
	// This should add the typed value as a new location
	locationsKeyupEnter() {
		let existingItem = this.selectedLocations.find(d => d.name.toUpperCase() === this.tempLocation.toUpperCase());
		if (!existingItem && this.tempLocation.length > 1) {
			let emptyItem = this.selectedLocations.find(d => d.name.toUpperCase() === '');
			emptyItem.name = this.tempLocation.toUpperCase();
			if (this.photo.userId == this.userService.getLocalUserId(0)) {
				emptyItem.type = 'new';
				emptyItem.new = true;
			} else {
				emptyItem.type = 'suggestion-new';
				emptyItem.new = true;
			}

			this.resetLocationsSelector();

			this.locationsHideAddIcon = false;
		}
	}

	// Happens when the user presses the Load More Results button in the locations selector
	loadMoreLocationResults() {
		this.locationsShowSuggestions = true;

		this.locationsKeyupSearch();
	}

	// Retrieves suggestions
	getLocationSuggestions() {
		this.filteredPublicLocations = [];

		let value = this.inputLocation.nativeElement.value;
		this.userService.getLocationSuggestions(value).subscribe(
			response => {
				this.filteredPublicLocations = response.body.items;
			}
		);
	}

	// Happens when the user clicks on a location
	selectLocation(location) {
		let existingItem = this.selectedLocations.find(d => d.name.toUpperCase() === location.name.toUpperCase());
		if (!existingItem) {
			let emptyItem = this.selectedLocations.find(d => d.name.toUpperCase() === '');
			emptyItem.name = location.name.toUpperCase();
			if (this.photo.userId == this.userService.getLocalUserId(0)) {
				emptyItem.type = 'db';
			} else {
				emptyItem.type = 'suggestion-new';
			}

			this.resetLocationsSelector();

			this.locationsHideAddIcon = false;
		}

		let removeItem = this.userLocations.find(d => d.name.toUpperCase() === location.name.toUpperCase());
		if (removeItem) {
			this.userLocations.splice(this.userLocations.indexOf(removeItem), 1);
		}
	}

	// Happens when the user clicks on a location suggestion
	selectLocationSuggestion(name) {
		let existingItem = this.selectedLocations.find(d => d.name.toUpperCase() === name.toUpperCase());
		if (!existingItem) {
			let emptyItem = this.selectedLocations.find(d => d.name.toUpperCase() === '');
			emptyItem.name = name.toUpperCase();
			emptyItem.new = true;
		}

		this.resetLocationsSelector();

		this.locationsHideAddIcon = false;
	}

	// Happens when the user presses the X icon on the selected location
	removeLocation(location, hideAddIcon) {
		if (location.new !== true) {
			let itemAdd = this.userLocations.find(d => d.name.toUpperCase() === location.name.toUpperCase());
			if (!itemAdd) {
				this.userLocations.push({ name: location.name.toUpperCase(), type: location.type });
				this.userLocations.sort((a, b) => a.name.localeCompare(b.name));
				this.userLocations = this.userLocations.slice();
			}
		}

		let itemRemove = this.selectedLocations.find(d => d.name.toUpperCase() === location.name.toUpperCase());
		if (itemRemove) {
			this.selectedLocations.splice(this.selectedLocations.indexOf(itemRemove), 1);
		}

		if (hideAddIcon) {
			this.locationsHideAddIcon = false;

			this.locationsShowSelector = false;
		}
	}

	// Happens when a user presses the X icon after typing in the locations input
	cancelCreateLocation() {
		this.tempLocation = '';
		this.inputLocation.nativeElement.value = '';
	}

	// Resets the form, which happens after a few different actions
	resetLocationsSelector() {
		this.filteredUserLocations = [];
		this.filteredPublicLocations = [];
		this.locationsShowSelector = false;
		this.locationsShowSuggestions = false;
		if (this.inputLocation) {
			this.inputLocation.nativeElement.value = '';
		}
		this.tempLocation = '';
	}


	/**
	 * KEYWORDS FUNCTIONS
	 */

	addKeywordTag() {
		let type = '';
		if (this.photo.userId !== this.userService.getLocalUserId(0)) {
			type = 'suggestion';
		}
		this.selectedKeywords.push({ name: '', type: type, action: 'add', userId: this.userService.getLocalUserId(0), suggestionId: '' });

		this.keywordsHideAddIcon = true;
	}

	// Happens when the user presses the down arrow on the keywords input
	showAllUserKeywords() {
		this.keywordsShowSelector = !this.keywordsShowSelector;
	}

	// Happens when the user clicks inside the keywords input
	keywordsFocus() {
		this.keywordsShowSelector = true;
	}

	// Happens when the user releases any key when in the keywords input
	// This provides a delay so the search doesn't trigger as often
	keywordsKeyup() {
		this.keywordsSearchSubject.next(null);
	}

	// Happens when the user releases any key when in the keywords input
	// Triggered after the delay in the above function
	keywordsKeyupSearch() {
		let value = this.inputKeyword.nativeElement.value;
		if (value.length > 0) {
			this.tempKeyword = value;
			this.keywordsShowSelector = true;
			this.filteredUserKeywords = this.userKeywords.filter(d => String(d.name).toUpperCase().includes(this.tempPerson.toUpperCase()));
		} else {
			this.tempKeyword = '';
			this.filteredUserKeywords = [];
		}
	}

	// Happens when the user releases the enter key when in the keywords input, or after a user presses the check icon after typing in the keywords input
	// This should add the typed value as a new keyword
	keywordsKeyupEnter() {
		let existingItem = this.selectedKeywords.find(d => d.name.toUpperCase() === this.tempKeyword.toUpperCase());
		if (!existingItem && this.tempKeyword.length > 1) {
			let emptyItem = this.selectedKeywords.find(d => d.name.toUpperCase() === '');
			emptyItem.name = this.tempKeyword.toUpperCase();
			if (this.photo.userId == this.userService.getLocalUserId(0)) {
				emptyItem.type = 'new';
				emptyItem.new = true;
			} else {
				emptyItem.type = 'suggestion-new';
				emptyItem.new = true;
			}

			this.resetKeywordsSelector();

			this.keywordsHideAddIcon = false;
		}
	}

	// Happens when the user clicks on a keyword
	selectKeyword(keyword) {
		let existingItem = this.selectedKeywords.find(d => d.name.toUpperCase() === keyword.name.toUpperCase());
		if (!existingItem) {
			let emptyItem = this.selectedKeywords.find(d => d.name.toUpperCase() === '');
			emptyItem.name = keyword.name.toUpperCase();
			if (this.photo.userId == this.userService.getLocalUserId(0)) {
				emptyItem.type = 'db';
			} else {
				emptyItem.type = 'suggestion-new';
			}

			this.resetKeywordsSelector();

			this.keywordsHideAddIcon = false;
		}

		let removeItem = this.userKeywords.find(d => d.name.toUpperCase() === keyword.name.toUpperCase());
		if (removeItem) {
			this.userKeywords.splice(this.userKeywords.indexOf(removeItem), 1);
		}
	}

	// Happens when the user presses the X icon on the selected keyword
	removeKeyword(keyword, hideAddIcon) {
		if (keyword.new !== true) {
			let itemAdd = this.userKeywords.find(d => d.name.toUpperCase() === keyword.name.toUpperCase());
			if (!itemAdd) {
				this.userKeywords.push({ name: keyword.name.toUpperCase(), type: keyword.type });
				this.userKeywords.sort((a, b) => a.name.localeCompare(b.name));
				this.userKeywords = this.userKeywords.slice();
			}
		}

		let itemRemove = this.selectedKeywords.find(d => d.name.toUpperCase() === keyword.name.toUpperCase());
		if (itemRemove) {
			this.selectedKeywords.splice(this.selectedKeywords.indexOf(itemRemove), 1);
		}

		if (hideAddIcon) {
			this.keywordsHideAddIcon = false;
		}
	}

	// Happens when a user presses the X icon after typing in the keywords input
	cancelCreateKeyword() {
		this.inputKeyword.nativeElement.value = '';
		this.tempKeyword = '';
	}

	// 
	resetKeywordsSelector() {
		this.filteredUserKeywords = [];
		this.keywordsShowSelector = false;
		this.tempKeyword = '';
	}




	/** 
	 * PUBLIC TAGS FUNCTIONS 
	 */

	addUserTag() {
		const photoUserId = this.photo.userId;
		this.selectedTags.push({ name: '', type: '', action: '', userId: photoUserId, suggestionId: '', new: true });

		this.tagsHideAddIcon = true;
	}

	// Happens when the user presses the arrow down icon in the tag input
	showAllUserTags() {
		this.tagsShowSelector = !this.tagsShowSelector;
	}

	// Happens when the user clicks inside the tags input
	tagsFocus() {
		this.tagsShowSelector = true;
	}

	// Happens when the user releases any key when in the tags input
	tagsKeyup() {
		this.tagsSearchSubject.next(null);
	}

	tagsKeyupSearch() {
		let value = this.inputTag.nativeElement.value;
		if (value.length > 1) {
			this.tempTag = value;
			this.tagsShowSelector = true;
			this.filteredUserTags = this.userTags.filter(d => String(d.name).toUpperCase().includes(this.tempTag.toUpperCase()));
			this.findPublicTags(this.tempTag.toUpperCase());
		} else {
			this.tagsShowSelector = false;
			this.tempTag = '';

			this.filteredUserTags = [];
			this.filteredPublicTags = [];
		}
	}

	// Happens when the user releases the enter key when in the tags input
	// This should add the typed value as a new tag
	tagsKeyupEnter() {
		let existingItem = this.selectedTags.find(d => d.name.toUpperCase() === this.tempTag.toUpperCase());
		if (!existingItem && this.tempTag.length > 1) {

			let emptyItem = this.selectedTags.find(d => d.name.toUpperCase() === '');
			emptyItem.name = this.tempTag.toUpperCase();
			emptyItem.new = true;
		}

		this.resetTagsSelector();

		this.tagsHideAddIcon = false;
	}

	// 
	findPublicTags(keyword) {
		this.loadingUserPublicTags = true;
		this.filteredPublicTags = [];
		this.userService.findPublicTagsByKeyword(keyword).subscribe(
			response => {
				for (const tag of response) {
					let item = this.filteredUserTags.find(d => d.name === tag);
					if (!item) {
						this.filteredPublicTags.push({ name: tag, type: '', new: true });
					}
				}
				this.filteredPublicTags.sort((a, b) => a.name.localeCompare(b.name));
				this.filteredPublicTags = this.filteredPublicTags.slice();

				this.loadingUserPublicTags = false;
			}
		);
	}

	// Happens when the user clicks on a tag
	selectTag(tag) {
		let existingItem = this.selectedTags.find(d => d.name.toUpperCase() === tag.name.toUpperCase());
		if (!existingItem) {
			let emptyItem = this.selectedTags.find(d => d.name.toUpperCase() === '');
			emptyItem.name = tag.name.toUpperCase();
			emptyItem.type = tag.type || 'db';

			this.resetTagsSelector();

			this.tagsHideAddIcon = false;
		}

		let removeItem = this.userTags.find(d => d.name.toUpperCase() === tag.name.toUpperCase());
		if (removeItem) {
			this.userTags.splice(this.userTags.indexOf(removeItem), 1);
		}
	}

	// Happens when the user presses the X icon on the tag
	removeTag(tag, hideAddIcon) {
		if (tag.new !== true) {
			let itemAdd = this.userTags.find(d => d.name.toUpperCase() === tag.name.toUpperCase());
			if (!itemAdd) {
				this.userTags.push({ name: tag.name.toUpperCase(), type: tag.type });
				this.userTags.sort((a, b) => a.name.localeCompare(b.name));
				this.userTags = this.userTags.slice();
			}
		}

		let itemRemove = this.selectedTags.find(d => d.name.toUpperCase() === tag.name.toUpperCase());
		if (itemRemove) {
			this.selectedTags.splice(this.selectedTags.indexOf(itemRemove), 1);
		}

		if (hideAddIcon) {
			this.tagsHideAddIcon = false;
		}
	}

	cancelCreateTag() {
		this.filteredPublicTags = [];
		this.filteredUserTags = [];
		this.tagsShowSelector = false;
		this.inputTag.nativeElement.value = '';
		this.tempTag = '';
	}

	clearTags() {
		this.selectedTags = [];
		this.filteredUserTags = [];
		this.filteredPublicTags = [];
		this.tagsShowSelector = false;
		this.tempTag = '';
	}

	resetTagsSelector() {
		this.filteredPublicTags = [];
		this.filteredUserTags = [];
		this.tagsShowSelector = false;
		if (this.inputTag) {
			this.inputTag.nativeElement.value = '';
		}
		this.tempTag = '';
	}














	// If you select a tag, it moves it from userKeyword to selectedKeyword.  If you press the reset form, the selectedKeywords get lost.
	// This function moves them back as needed.
	resetSelectedMetadata() {
		for (const item of this.selectedPeople) {
			if (item.type == 'db') {
				this.userPeople.push(item);
			}
		}
		this.userPeople.sort((a, b) => a.name.localeCompare(b.name));
		this.userPeople = this.userPeople.slice();

		for (const item of this.selectedLocations) {
			if (item.type == 'db') {
				this.userLocations.push(item);
			}
		}
		this.userLocations.sort((a, b) => a.name.localeCompare(b.name));
		this.userLocations = this.userLocations.slice();

		for (const item of this.selectedKeywords) {
			if (item.type == 'db') {
				this.userKeywords.push(item);
			}
		}
		this.userKeywords.sort((a, b) => a.name.localeCompare(b.name));
		this.userKeywords = this.userKeywords.slice();


		for (const item of this.selectedTags) {
			if (item.type == 'db') {
				this.userTags.push(item);
			}
		}
		this.userTags.sort((a, b) => a.name.localeCompare(b.name));
		this.userTags = this.userTags.slice();


		this.selectedPeople = [];
		this.selectedLocations = [];
		this.selectedKeywords = [];
		this.selectedTags = [];
	}





	/**
	 * PHOTO HISTORY TAB FUNCTIONS
	 */

	// Retrieve the photo history from the database. This occurs every time a photo is loaded if
	// the current user is the owner of the photo.
	retrievePhotoHistory() {
		this.photoService.getPhotoHistory(this.photo.id).subscribe(
			response => {
				this.photoHistory = response.body;
			}
		);
	}


	/**
	 * PHOTO OPTIONS TAB FUNCTIONS
	 */
	connectionsCanViewSwitchChanged(e) {
		this.loadingOptionUpdate = true;
		if (e.srcElement.checked) {
			// Enable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanView = true;

			this.photoService.updatePhoto(photo, false, false).subscribe(
				response => {
					this.photo.connectionsCanView = true;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections can view the photo');
				}
			);
		} else {
			// Disable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanView = false;

			this.photoService.updatePhoto(photo, false, false).subscribe(
				response => {
					this.photo.connectionsCanView = false;
					this.photo.connectionsCanReact = false;
					this.photo.connectionsCanDiscuss = false;
					this.photo.lockDiscussion = false;
					this.photo.connectionsCanSeeExif = false;
					this.photo.connectionsCanSuggest = false;

					this.initClassifyForm();

					this.loadingOptionUpdate = false;
					this.toastr.success('Connections cannot view the photo');
				}
			);
		}
	}

	connectionsCanReactSwitchChanged(e) {
		this.loadingOptionUpdate = true;
		if (e.srcElement.checked) {
			// Enable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanReact = true;

			this.photoService.updatePhoto(photo, false, false).subscribe(
				response => {
					this.photo.connectionsCanReact = true;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections can react to the photo');
				}
			);
		} else {
			// Disable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanReact = false;

			this.photoService.updatePhoto(photo, false, false).subscribe(
				response => {
					this.photo.connectionsCanReact = false;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections cannot react to the photo');
				}
			);
		}
	}

	connectionsCanDiscussSwitchChanged(e) {
		this.loadingOptionUpdate = true;
		if (e.srcElement.checked) {
			// Enable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanDiscuss = true;

			this.photoService.updatePhoto(photo, false, false).subscribe(
				response => {
					this.photo.connectionsCanDiscuss = true;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections can discuss the photo');
				}
			);
		} else {
			// Disable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanDiscuss = false;

			this.photoService.updatePhoto(photo, false, false).subscribe(
				response => {
					this.photo.connectionsCanDiscuss = false;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections cannot discuss the photo');
				}
			);
		}
	}

	connectionsCanSuggestSwitchChanged(e) {
		this.loadingOptionUpdate = true;
		if (e.srcElement.checked) {
			// Enable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanSuggest = true;

			this.photoService.updatePhoto(photo, false, false).subscribe(
				response => {
					this.photo.connectionsCanSuggest = true;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections can suggest photo metadata');
				}
			);
		} else {
			// Disable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanSuggest = false;

			this.photoService.updatePhoto(photo, false, false).subscribe(
				response => {
					this.photo.connectionsCanSuggest = false;

					// Decline all suggestions from photoKeywords, photoUsers, photoLocations
					for (let i = 0; i < this.selectedKeywords.length; i++) {
						if (this.selectedKeywords[i].type == 'suggestion') {
							this.declineSuggestion(this.selectedKeywords[i], 'keyword');
						}
					}
					for (let i = 0; i < this.selectedLocations.length; i++) {
						if (this.selectedLocations[i].type == 'suggestion') {
							this.declineSuggestion(this.selectedLocations[i], 'location');
						}
					}
					for (let i = 0; i < this.selectedPeople.length; i++) {
						if (this.selectedPeople[i].type == 'suggestion') {
							this.declineSuggestion(this.selectedPeople[i], 'person');
						}
					}

					this.loadingOptionUpdate = false;
					this.toastr.success('Connections cannot suggest photo metadata');
				}
			);
		}
	}

	connectionsCanSeeExifSwitchChanged(e) {
		this.loadingOptionUpdate = true;
		if (e.srcElement.checked) {
			// Enable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanSeeExif = true;

			this.photoService.updatePhoto(photo, false, false).subscribe(
				response => {
					this.photo.connectionsCanSeeExif = true;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections can see photo EXIF data');
				}
			);
		} else {
			// Disable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.connectionsCanSeeExif = false;

			this.photoService.updatePhoto(photo, false, false).subscribe(
				response => {
					this.photo.connectionsCanSeeExif = false;
					this.loadingOptionUpdate = false;
					this.toastr.success('Connections cannot photo EXIF data');
				}
			);
		}
	}

	lockDiscussionSwitchChanged(e) {
		this.loadingOptionUpdate = true;
		if (e.srcElement.checked) {
			// Enable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.lockDiscussion = true;

			this.photoService.updatePhoto(photo, false, false).subscribe(
				response => {
					this.photo.lockDiscussion = true;
					this.loadingOptionUpdate = false;
					this.toastr.success('Photo discussion is locked');
				}
			);
		} else {
			// Disable 
			let photo = new Photo();
			photo.id = this.photo.id;
			photo.lockDiscussion = false;

			this.photoService.updatePhoto(photo, false, false).subscribe(
				response => {
					this.photo.lockDiscussion = false;
					this.loadingOptionUpdate = false;
					this.toastr.success('Photo discussion is unlocked');
				}
			);
		}
	}

	/**
	 * DELETE PHOTO CONFIRM
	 */
	deletePhotoConfirm() {
		this.loading = true;

		this.photoService.deleteUploadedPhoto(this.photo.id).subscribe(
			response => {
				if (response.status === 200) {
					// Annouce the photo has been deleted.
					this.photoService.announcePhotoDeleted(this.photo.id);

					// Update the photo count.
					let userPhotoCount = this.photoService.getUserPhotoCount();

					if (userPhotoCount) {
						userPhotoCount.totalPhotos = userPhotoCount.totalPhotos - 1;
						userPhotoCount.myUploadedPhotos = userPhotoCount.myUploadedPhotos - 1;
						userPhotoCount.myPhotos = userPhotoCount.myPhotos - 1;

						this.photoService.setAndAnnounceUserPhotoCount(userPhotoCount);
					} else {
						this.photoService.getTotalPhotoCount().subscribe(
							response => {
								this.photoService.setAndAnnounceUserPhotoCount(response.body);
							});

					}

					// Show a deleted message.
					this.photoDeleted = true;

					// TODO: Update the photo object so if you navigate away and back, it still shows as deleted.
					this.photo.status = 'deleted'

					this.deleteConfirm = false;
					this.loading = false;
				}
			});
	}


	/**
	 * UI / HELPER FUNCTIONS
	 */
	tabSelect(tab: string) {
		this.tab = tab;
	}

	enterEditMode() {
		this.editMode = true;
		this.updateSaveRolloverText('');
	}

	enterEditTagsMode() {
		this.editTagsMode = true;
		this.updateShareRolloverText('');
	}

	cancelUpdatePhoto() {
		// Reload the photo so it clears any modified metadata
		this.photoService.setActivePhoto(this.photo);
	}

	cancelUpdatePhotoTags() {
		// Reload the photo so it clears any modified metadata
		this.photoService.setActivePhoto(this.photo);
	}

	updateActionsRolloverText(text: string) {
		this.actionsRolloverText = text;
	}

	updateSaveRolloverText(text: string) {
		this.saveRolloverText = text;
	}

	updateShareRolloverText(text: string) {
		this.shareRolloverText = text;
	}

	closeModal() {
		// If we don't do this multiple subsriptions get created each time they view a photo.
		if (this.activePhotoChangedSubscription) {
			this.activePhotoChangedSubscription.unsubscribe();
		}

		this.photoService.triggerModalClosed();
		this.modalRef.hide();
	}
}

import { SafeUrl } from "@angular/platform-browser";
import { Event } from "./event.model";
import { PhotoReactionAngry } from "./photo-reaction-angry.model";
import { PhotoReactionLaugh } from "./photo-reaction-laugh.model";
import { PhotoReactionLike } from "./photo-reaction-like.model";
import { PhotoReactionLove } from "./photo-reaction-love.model";
import { PhotoReactionSad } from "./photo-reaction-sad.model";

export class Photo {
	public id: number;
	public uuid: string;
	public checksum: string;
	public userId: number;
	public folderId: number;
	public eventId: number;
	public addedByUserId: number;
	public status: string;
	public type: string;
	public addedDate: Date;
	public description: string;
	public capturedType: string;
	public capturedDate: Date;
	public capturedDateAuto: boolean;
	public capturedTimeframe: string;
	public externalParentId: string;
	public externalId: string;
	public externalIdMed: string;
	public externalUrl: string;
	public externalUrlMed: string;
	public imageUrl: string;
	public imageUrlMed: string;
	public imageSize: number;
	public imageSizeOnDisk: number;
	public imageOriginalFilename: string;
	public imageMimeType: string;
	public keywords: string;
	public people: string;
	public locations: string;
	public publicTags: string;
	public publicTagsArray: string[] = [];
	public touched: boolean;
	public xresolution: number;
	public yresolution: number;
	public height: number;
	public width: number;
	public cameraMake: string;
	public cameraModel: string;
	public cameraLensMake: string;
	public cameraLensModel: string;
	public cameraIso: string;
	public cameraShutterSpeed: string;
	public cameraAperature: string;
	public cameraBrightness: string;
	public cameraFlash: number;
	public cameraZoomRatio: number;
	public latitude: number;
	public longitude: number;
	public connectionsCanView: boolean;
	public connectionsCanReact: boolean;
	public connectionsCanSuggest: boolean;
	public connectionsCanDiscuss: boolean;
	public connectionsCanSeeExif: boolean;
	public lockDiscussion: boolean;

	public imageSafeUrl: SafeUrl; // used to load images directly from B2

	//public photoIndex: number; // these 4 are used to store the initials of the photos owner on the explore page
	public userInitials: string;
	public userFirstName: string;
	public userLastName: string;

	public reactionsAngry: PhotoReactionAngry[] = [];
	public reactionsLaugh: PhotoReactionLaugh[] = [];
	public reactionsLike: PhotoReactionLike[] = [];
	public reactionsLove: PhotoReactionLove[] = [];
	public reactionsSad: PhotoReactionSad[] = [];

	public addToEvent: Event;

	public vaultPhotoStatus: string;

	public eventCoverPhoto: boolean;		// used on the events and timeline pages to indicate a cover photo

	//public photoIconHoverMessage: string;

	public imageLoading: boolean = true; // used for loading indicators when loading an image
}

<div class="container" [ngClass]="{'loading': loading}">
	<div *ngIf="loading" class="loading-container loading in">&nbsp;</div>
	<ng-container *ngIf="!loading">
		<div class="header">User Notifications</div>
		<ng-container *ngFor="let message of userMessages">
			<app-user-message [message]="message" (emitMessageAcknowledged)="removeMessage($event)"></app-user-message>
		</ng-container>
		<div *ngIf="!loading && userMessages.length == 0" class="no-tags-message">
			You have no new messages
		</div>
	</ng-container>
</div>